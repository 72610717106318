import { useContext } from "react";
import { Link } from "react-router-dom";
import Collapse from 'react-bootstrap/Collapse';
import useComponentVisible from "../../hooks/useComponentVisible";
import { AppContext } from "../../util/AppContext";
import { getConfigValue } from "../../util/getConfigValue";

function QuickSheetRow({ quickSheet, index, onExpanded }) {
    const [appState] = useContext(AppContext);
    const [optionsMenuIsVisible] = useComponentVisible(false);
    const territoriesNames = quickSheet.territories.map(territory => territory.name).join(" ");
    const planNames = quickSheet.plans.map(plan => plan.name).join(", ");

    function setExpanded() {
        quickSheet.isExpanded = !quickSheet.isExpanded;

        if (onExpanded) {
            onExpanded(quickSheet);
        }
    }

    return (
        <div className="row pd-plan-table-row pd-plan-table-card align-items-center">
            <div className="col-10 col-md-6 col-lg-4 py-3 py-lg-4 py-xl-5">{quickSheet.documentName}</div>
            <div className="col-md-3 d-none d-lg-block">{quickSheet.productName}</div>
            <div className="col-md-3 d-none d-md-block d-lg-block">{quickSheet.expirationDate}</div>
            <div className="col-2 col-md-3 col-lg-2 d-flex justify-content-end">
                <div className="d-flex">
                    <Link to="/QuickSheetsAdminPage-edit" className="btn btn-ghost-secondary btn-md btn-icon-only me-4" state={{ quickSheet, isAddMode: false }}>
                        <span className="fa-solid fa-pen btn-icon"></span>
                    </Link>
                    <button
                        onClick={() => setExpanded()}
                        className="btn btn-ghost-secondary btn-md btn-icon-only"
                        data-bs-toggle="collapse"
                        data-bs-target={`#options-row-${index}`}
                        aria-expanded={optionsMenuIsVisible}
                        aria-controls={`options-row-${index}`}>
                        <span className="fa-regular fa-chevron-down btn-icon"></span>
                    </button>
                </div>
            </div>

            <Collapse in={quickSheet.isExpanded}>
                <div className="pd-plan-col pd-plan-datatable col-12 order-lg-last px-0" id="datatable-row-1">
                    <div className="pd-plan-datatable">
                        <div className="pd-plan-datatable-row">
                            <div className="pd-plan-datatable-col pd-datatable-label">{getConfigValue(appState?.config?.QuickSheets?.Admin?.DocumentId, appState)?.Value || null}</div>
                            <div className="pd-plan-datatable-col pd-datatable-value">{quickSheet.documentId}</div>
                        </div>
                        <div className="pd-plan-datatable-row">
                            <div className="pd-plan-datatable-col pd-datatable-label">{getConfigValue(appState?.config?.QuickSheets?.Admin?.DocumentName, appState)?.Value || null}</div>
                            <div className="pd-plan-datatable-col pd-datatable-value">{quickSheet.documentName}</div>
                        </div>
                        <div className="pd-plan-datatable-row">
                            <div className="pd-plan-datatable-col pd-datatable-label">{getConfigValue(appState?.config?.QuickSheets?.Admin?.ValidityDates?.BeginningDate, appState)?.Value || null}</div>
                            <div className="pd-plan-datatable-col pd-datatable-value">{quickSheet.fromDate}</div>
                        </div>
                        <div className="pd-plan-datatable-row">
                            <div className="pd-plan-datatable-col pd-datatable-label">{getConfigValue(appState?.config?.QuickSheets?.Admin?.ValidityDates?.ExpirationDate, appState)?.Value || null}</div>
                            <div className="pd-plan-datatable-col pd-datatable-value">{quickSheet.expirationDate}</div>
                        </div>
                        <div className="pd-plan-datatable-row">
                            <div className="pd-plan-datatable-col pd-datatable-label">{getConfigValue(appState?.config?.QuickSheets?.Admin?.Product, appState)?.Value || null}</div>
                            <div className="pd-plan-datatable-col pd-datatable-value">{quickSheet.productName}</div>
                        </div>
                        <div className="pd-plan-datatable-row">
                            <div className="pd-plan-datatable-col pd-datatable-label">{getConfigValue(appState?.config?.QuickSheets?.Admin?.Territories, appState)?.Value || null}</div>
                            <div className="pd-plan-datatable-col pd-datatable-value">{territoriesNames}</div>
                        </div>
                        <div className="pd-plan-datatable-row">
                            <div className="pd-plan-datatable-col pd-datatable-label">{getConfigValue(appState?.config?.QuickSheets?.Admin?.Plans, appState)?.Value || null}</div>
                            <div className="pd-plan-datatable-col pd-datatable-value">{planNames}</div>
                        </div>
                    </div>
                </div>
            </Collapse>

        </div>
    );
}

export default QuickSheetRow;