import { useIdleTimer } from 'react-idle-timer'
import { useContext } from 'react';
import { AppContext } from './AppContext';
import { logOutPost } from './AuthRoutes';

export const TimeoutHandler = () => {
    const [appState] = useContext(AppContext);
    const timeout = appState?.config?.Session?.TimeoutInMinutes?.Value || 15;
	
    //when the user is detected to be idle past the defined timeout, log it out 
    function onIdle(){
        logOutPost();
    }

	useIdleTimer({
		onIdle,
		timeout: timeout*60*1000,
		throttle: 500,
		startOnMount: true,
		stopOnIdle: true
	});

    return (<></>)
}