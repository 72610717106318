import ToastContainer from 'react-bootstrap/ToastContainer'
import Toast from 'react-bootstrap/Toast';
import PropType from 'prop-types';

ToastAlert.propTypes = {
	show: PropType.bool,
	setShow: PropType.func,
	title: PropType.string,
	message: PropType.string,
	autohide: PropType.bool
};

function ToastAlert({ show, setShow, title, message, autohide = true }) {
    return (
        <ToastContainer className="top-0 end-0 p-3">
            <Toast onClose={() => setShow(false)} delay={autohide ? 4000 : 0} show={show} autohide={autohide}>
                <Toast.Header className="danger">
                    <strong className="me-auto">{title}</strong>
                </Toast.Header>
                <Toast.Body>
                    {getMessageList(message)}
                </Toast.Body>
            </Toast>
        </ToastContainer>
    )
}

function getMessageList(message) {
    if (Array.isArray(message) && message.length > 1) {
        return (message.map((msg, index) => (
            <li key={index}>
                <span>{msg}</span>
            </li>
        )))
    }

    return message;
}

export default ToastAlert

