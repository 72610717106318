export async function getQuickSheets(productId, includeActive = true, includeExpired = true, territoryId = null, targetId = null) {
    if (!productId) {
        throw new Error('No productId provided.');
    }

    // example: https://localhost:7099/api/QuickSheets/Filter?productId=254&includeActive=true&includeExpired=false&territoryId=Territory1&targetId=1234567
    let url = `${process.env.REACT_APP_API_URL}QuickSheets/Filter?productId=${encodeURIComponent(productId)}&includeActive=${encodeURIComponent(includeActive)}&includeExpired=${encodeURIComponent(includeExpired)}`;

    if (territoryId) {
        url += `&territoryId=${encodeURIComponent(territoryId)}`;
    }

    if (targetId) {
        url += `&targetId=${encodeURIComponent(targetId)}`;
    }

    const response = await fetch(url, {
        method: "GET",
        credentials: "include",
    });

    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 500 || response.status === 404) {
        return [];
    } else {
        throw response.statusText;
    }
}



export async function getAllQuickSheets() {
    const url = `${process.env.REACT_APP_API_URL}QuickSheets`;
    const response = await fetch(url, {
        method: "GET",
        credentials: "include",
    });

    if (response.status === 200) {
        return await response.json();
    } else {
        throw response.statusText;
    }
}
export async function getCachedThumbNail(id) {
    const cachedValue = sessionStorage.getItem(id);
    if (cachedValue) {
        return cachedValue;
    }
    const thumbNail = await getThumbNail(id);
    sessionStorage.setItem(id, thumbNail);
    return thumbNail;
}

export async function getThumbNail(id) {
    const url = `${process.env.REACT_APP_API_URL}QuickSheet/ThumbNail?id=${encodeURIComponent(id)}`;
    const response = await fetch(url, {
        method: "GET",
        credentials: "include",
    });

    if (response.status === 200) {
        return await response.json();
    } else {
        throw response.statusText;
    }
}

export async function getPlansForUser(userId) {
    const url = `${process.env.REACT_APP_API_URL}User/Plans?userId=${encodeURIComponent(userId)}`;

    const response = await fetch(url, {
        method: "GET",
        credentials: "include",
    });

    if (response.status === 200) {
        return await response.json();
    }
    else if (response.status === 204) {
        return '';
    }
    else {
        throw response.statusText;
    }
}

export async function upsertQuickSheet(isAdding, quickSheet) {
    const url = `${process.env.REACT_APP_API_URL}QuickSheet`;

    const response = await fetch(url, {
        method: isAdding ? "POST" : "PUT",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(quickSheet)
    });

    return response;
}
