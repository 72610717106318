import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { AppContext } from '../util/AppContext';
import { getConfigValue } from '../util/getConfigValue';

function TerritoryPage() {
	const [appState, setAppState] = useContext(AppContext);

	const [marketList, setMarketList] = useState();
	const [territoryList, setTerritoryList] = useState();

	const [multipleMarkets, setMultipleMarkets] = useState(false);

	const [selectedMarket, setSelectedMarket] = useState(appState.market || null);
	const [selectedTerritory, setSelectedTerritory] = useState(appState.territory || null);

	let selectMarketConfigValue = getConfigValue(appState.config?.Territory.SelectMarket, appState)?.Value
	let selectTerritoryConfigValue = getConfigValue(appState.config?.Territory.SelectTerritory, appState)?.Value;


	useEffect(() => {
		const markets = JSON.parse(sessionStorage.getItem('marketsForUser'));

		if (Object.keys(markets)?.length > 1) {
			setMultipleMarkets(true);
		} else {
			handleMarket(markets[0]);
		}

		setMarketList(markets);
	}, []);

	useEffect(() => {
		if (selectedMarket) {
			setTerritoryList(selectedMarket.territories);
		}
	}, [selectedMarket]);

	function handleMarket(market) {
		setAppState({
			...appState,
			market: market,
		});

		setSelectedMarket(market);
		setTerritoryList(market.territories);
	}

	function handleTerritory(territory) {
		setAppState({
			...appState,
			territory: territory,
		});

		setSelectedTerritory(territory);
	}

	return (
		<div>
			<div className='title-bar'>
				<div className='container-xl'>
					<div className='row'>
						<div className='col-12'>
							<h1 className='type-24 mt-4 mb-4'>{getConfigValue(appState.config?.Focus?.Header, appState)?.Value || null}</h1>
							<ul className='wizard-list hide-noncurrent-mobile horizontal mb-5'>
								<li className='wizard-step state-current'>
									<span className='wizard-step-icon'></span>
									<span className='wizard-step-label'>{getConfigValue(appState.config?.Focus?.BreadCrumb?.Territory?.Label, appState)?.Value || null}</span>
								</li>
								<li className='wizard-step state-future'>
									<span className='wizard-step-icon'></span>
									<span className='wizard-step-label'>{getConfigValue(appState.config?.Focus?.BreadCrumb?.Target?.Label, appState)?.Value || null}</span>
								</li>
								<li className='wizard-step state-future'>
									<span className='wizard-step-icon'></span>
									<span className='wizard-step-label'>{getConfigValue(appState.config?.Focus?.BreadCrumb?.Configure?.Label, appState)?.Value || null}</span>
								</li>
								<li className='wizard-step state-future'>
									<span className='wizard-step-icon'></span>
									<span className='wizard-step-label'>{getConfigValue(appState.config?.Focus?.BreadCrumb?.Generate?.Label, appState)?.Value || null}</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className='container-xl mt-5'>
				<div className='row justify-content-center mb-6'>
					<div className='col-12 col-sm-10 col-lg-8'>
						<h1 className='type-20 color-grey-d1 mb-5'>{getConfigValue(appState.config?.Territory?.SectionTitle, appState)?.Value}</h1>
						<div className='card pd-card pt-3 pt-md-5 pb-4 pb-md-7'>
							<div className='row justify-content-center'>
								<div className='col-10 col-md-5'>
									<p>{getConfigValue(multipleMarkets ? appState.config?.Territory.SectionMessage : appState.config?.Territory.SectionMessageTerritory, appState)?.Value}</p>
									{multipleMarkets && (
										<>
											<label htmlFor='market' className='form-label'>
												{selectMarketConfigValue}
											</label>
											<Select
												unstyled
												classNames={{
													control: () => 'form-control',
													menuList: () => 'card',
													option: (state) =>
														state.isFocused ? 'bg-color-precision-grey text-white p-2' : 'p-2',
												}}
												aria-label={selectMarketConfigValue}
												id='market'
												type='search'
												placeholder={selectMarketConfigValue}
												onChange={handleMarket}
												options={marketList}
												getOptionLabel={(market) => market.name}
												getOptionValue={(market) => market.id}
												value={marketList?.filter(obj => obj.id === selectedMarket.id)}></Select>
										</>
									)}

									<div className='my-5'></div>
									<label htmlFor='territory' className='form-label'>
										{selectTerritoryConfigValue}
									</label>
									<Select
										unstyled
										classNames={{
											control: () => 'form-control',
											menuList: () => 'card',
											option: (state) =>
												state.isFocused ? 'bg-color-precision-grey text-white p-2' : 'p-2',
										}}
										aria-label={selectTerritoryConfigValue}
										id='territory'
										type='search'
										placeholder={selectTerritoryConfigValue}
										onChange={handleTerritory}
										options={territoryList}
										getOptionLabel={(territory) => territory.name}
										getOptionValue={(territory) => territory.id}
										value={selectedMarket?.territories?.filter(obj => obj.id === selectedTerritory.id)}></Select>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='row justify-content-center mb-7'>
					<div className='col-10 col-sm-8 col-md-6 offset-md-4 offset-lg-2 col-xl-5 offset-xl-3 justify-content-end'>
						<div className='d-flex flex-column flex-md-row-reverse'>
							{!selectedTerritory.id && (
								<>
									<Link
										to='#'
										className='btn btn-primary disabled btn-lg pe-7 mb-3 mb-md-0 ms-md-3 flex-fill w-100'>
										{getConfigValue(appState.config?.Common?.Continue, appState)?.Value || null}
									</Link>
								</>
							)}
							{selectedTerritory.id && (
								<>
									<Link
										to='/HCPPage'
										state={{ selectedTerritory }}
										className='btn btn-primary btn-lg pe-7 mb-3 mb-md-0 ms-md-3 flex-fill w-100'>
										{getConfigValue(appState.config?.Common?.Continue, appState)?.Value || null}
									</Link>
								</>
							)}
							<Link to='/' className='btn btn-secondary btn-lg pe-7 flex-fill w-100'>
								{getConfigValue(appState.config?.Common?.Back, appState)?.Value || null}
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TerritoryPage;
