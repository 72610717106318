import React, { useState, useEffect, useContext } from 'react';
import PrintOptionCheckbox from './printOptionCheckbox';
import { AppContext } from '../../util/AppContext';
import ToastAlert from '../ToastAlert';
import { getConfigValue } from '../../util/getConfigValue';
import PropType from 'prop-types';

PrintOptionDropdown.propTypes = {
    isDisabled: PropType.bool,
    printConfigColumnHeaders: PropType.arrayOf(PropType.shape({
        isRequired: PropType.bool,
        title: PropType.string,
        key: PropType.string
    })),
    printOptionsEhrBackPageEnabled: PropType.bool
}

function PrintOptionDropdown(props) {
    const {
        isConfigMenuVisible,
        setIsConfigMenuVisible,
        configMenuContentRef,
        configMenuRef,
        configHCPisChecked,
        setConfigHCPisChecked,
        configEHRisChecked,
        setConfigEHRisChecked,
        selectedColumnState,
        setSelectedColumnState,
        printConfigColumnHeaders,
        isDisabled,
        printOptionsEhrBackPageEnabled
    } = props;

    const [appState, setAppState] = useContext(AppContext);
    const [showToast, setShowToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");
    const [maxSelectedColumnState, setMaxSelectedColumnState] = useState(null);
    const prescriberType = getConfigValue(appState.prescriberType);


    useEffect(() => {
        if (appState?.config) {
            getMaxPrintColumns();
        }
    }, [appState]);

    function getMaxPrintColumns() {
        const value = getConfigValue(appState.config.PlanList.MaxPrintableColumns, appState)?.Value;
        if (value) {
            setMaxSelectedColumnState(value);
        }
    }

    function triggerToastAlert(title, message) {
        setToastTitle(title);
        setToastMessage(message);
        setShowToast(true);
    }

    function handlePrintColumnsCheckboxClick(event) {
        const isChecked = event.target.checked;
        const maxReached = selectedColumnState.length >= maxSelectedColumnState;

        if (isChecked && maxReached) {
            event.preventDefault();
            triggerToastAlert("Max reached", `You can only select up to ${maxSelectedColumnState} columns.`);
        }
    }

    function handleCheckboxChange(columnHeader) {
        const newState = [...selectedColumnState];
        const index = newState.indexOf(columnHeader.key);
        const maxReached = selectedColumnState.length >= parseInt(maxSelectedColumnState);
        if (index > -1) {
            newState.splice(index, 1);
        } else if (!maxReached) {
            newState.push(columnHeader.key);
        }
        setSelectedColumnState(newState);
    }
    return (
        <div className="dropdown-rc mb-4 my-lg-0">
            <button
                disabled={isDisabled ?? true}
                ref={configMenuRef}
                onClick={() => setIsConfigMenuVisible(isConfigMenuVisible => !isConfigMenuVisible)}
                aria-expanded={isConfigMenuVisible ? "true" : "false"}
                className="border-0 px-0 bg-transparent text-start d-block w-100"
                type="button"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside">
                <div
                    className={(isDisabled ?? true) ? "border border-dark-subtle p-3 bg-white opacity-50" : "border border-dark-subtle card p-3"}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <span className="fa-solid fa-sliders color-primary me-2"></span>
                            <span>{getConfigValue(appState.config?.PlanList?.ConfigureOption, appState)?.Value}</span>
                        </div>
                        <span className="fa-solid fa-chevron-down color-grey-d1"></span>
                    </div>
                </div>
            </button>
            <div
                ref={configMenuContentRef}
                className="dropdown-rc-options"
                id="dropdown-rc-options">
                <ul className={`dropdown-menu dropdown-menu-end ${isConfigMenuVisible ? "show" : " "}`}>
                    <li className="ps-3 pe-3">
                        <h4 className="type-16 type-bold mt-2 mb-3">
                            {getConfigValue(appState.config?.PlanList?.SheetLabel, appState)?.Value}
                        </h4>
                        <div className="form-check mb-3 d-flex align-items-center">
                            <input
                                className="form-check-input type-14 flex-shrink-0"
                                type="checkbox"
                                value=""
                                id="checkbox-sheet-options-includehcp"
                                checked={configHCPisChecked}
                                onChange={() => setConfigHCPisChecked(!configHCPisChecked)}
                            ></input>
                            <label className="form-check-label type-14" htmlFor="checkbox-sheet-options-includehcp"> {
                                prescriberType === "Prescriber" ? (getConfigValue(appState.config?.PlanList?.ConfigureOption?.IncludeHCPName, appState)?.Value) :
                                    (getConfigValue(appState.config?.PlanList?.ConfigureOption?.IncludeGroupPracticeName, appState)?.Value)}</label>
                        </div>
                        {printOptionsEhrBackPageEnabled &&
                            <div className="form-check mb-3 d-flex align-items-center">
                                <input
                                    className="form-check-input type-14 flex-shrink-0"
                                    type="checkbox"
                                    value=""
                                    id="checkbox-sheet-options-EHRbackpage"
                                    checked={configEHRisChecked}
                                    onChange={() => setConfigEHRisChecked(!configEHRisChecked)}
                                ></input>
                                <label className="form-check-label type-14" htmlFor="checkbox-sheet-options-EHRbackpage">{getConfigValue(appState.config?.PlanList?.ConfigureOption?.EHRBackPage, appState)?.Value}</label>
                            </div>
                        }
                        <h4 className="type-16 type-bold mt-5 mb-3">
                            {getConfigValue(appState.config?.PlanList?.PrintLabel, appState)?.Value}
                        </h4>
                        <p className="type-12 fst-italic color-grey-d1 mb-3">{getConfigValue(appState.config?.PlanList?.MinPrintColumn?.Text, appState)?.Value}</p>
                        {/* This area is dealing with static data that will be in the dropdown  */}
                        {printConfigColumnHeaders?.map((header, index) => {
                            return (
                                <PrintOptionCheckbox
                                    disabled={header.isRequired}
                                    key={index}
                                    label={header.title}
                                    value={header.key}
                                    checked={selectedColumnState.indexOf(header.key) > -1}
                                    onChange={(() => {
                                        handleCheckboxChange(header)
                                    })}
                                    onClick={handlePrintColumnsCheckboxClick} />
                            )
                        })}
                    </li>
                </ul>
            </div>
            <ToastAlert
                show={showToast}
                setShow={setShowToast}
                title={toastTitle}
                message={toastMessage}
            />
        </div>
    );
}

export default PrintOptionDropdown;