import { useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import Tooltip from "./Tooltip";
import { AppContext } from "../util/AppContext";
import { getConfigValue } from "../util/getConfigValue";
import { computeBorderColorClass, getIndicatorClass } from '../util/getBorderColor'
import PropType from 'prop-types';
import PrintRestrictionModal from './modals/PrintRestrictionModal';

SingleWinPlanListRow.propTypes = {
    plan: PropType.shape({
        name: PropType.string,
        benType: PropType.string,
        isPrintable: PropType.bool,
        planClass: PropType.string,
        isWin: PropType.bool,
        coverage: PropType.string,
        isGeographicPlan: PropType.bool,
        planId: PropType.string,
        hasSufficientPlans: PropType.bool
    })
};

function SingleWinPlanListRow({ plan, index }) {
    const [appState] = useContext(AppContext);
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipTarget = useRef(null);
    const [showPrintRestrictionModal, setShowPrintRestrictionModal] = useState(false);

    const { name, benType, isWin, coverage, isPrintable, planClass, isGeographicPlan, planId, hasSufficientPlans } = plan;

    let isDangerRow = !isPrintable;
    let borderColorClass = computeBorderColorClass(isWin, isPrintable, planClass);
    let softPrint = planClass;

    return (
        <div className={`row pd-plan-table-row ${borderColorClass ? getIndicatorClass(borderColorClass) : ''
            } pd-plan-table-card mb-4 mb-lg-0 pt-3 py-lg-4 justify-content-lg-between align-items-lg-center`}>
            {/* <!-- Fixed columns (Channel/Plan) --> */}

            <div className="pd-plan-col pd-plan-fixed-id col-6 col-lg-3">
                <div className="row align-items-lg-center">
                    <div className="col-12 mb-3 my-lg-0">
                        <div className="pd-plan-table-label">{getConfigValue(appState?.config?.SingleWinPlanListRow?.Channel?.Label, appState)?.Value || null}</div>
                        <div className="pd-plan-table-value">{benType}</div>
                    </div>
                </div>
            </div>

            <div className="pd-plan-col pd-plan-fixed-id col-6 col-lg-4">
                <div className="row align-items-lg-center">
                    <div className="col-12 mb-3 my-lg-0">
                        <div className="pd-plan-table-label">{getConfigValue(appState?.config?.SingleWinPlanListRow?.Plan?.Label, appState)?.Value || null}
                        </div>
                        <div className="pd-plan-table-value">
                            <div className="d-flex align-items-middle">
                                <span className={isGeographicPlan ? "fal fa-globe color-primary me-2 mt-1" : "fal fa-user-doctor color-primary me-2 mt-1"}></span>{name}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Variable columns --> */}

            <div className="pd-plan-col pd-plan-fixed-id col-6 col-lg-3">
                <div className="row align-items-lg-center">
                    <div className="col-12 mb-3 my-lg-0">
                        <div className="pd-plan-table-label">{getConfigValue(appState?.config?.SingleWinPlanListRow?.Status?.Label, appState)?.Value || null}</div>
                        <div className="pd-plan-table-value">{coverage}</div>
                    </div>
                </div>
            </div>

            {/* <!-- Button - more info --> */}

            <div className="pd-plan-col pd-plan-fixed-id col-12 col-lg-2 mb-5 my-lg-0">
                <div className="row justify-content-center align-items-lg-center justify-content-lg-end">
                    <div className="col-11 col-sm-8 col-md-6 col-lg-12 d-none d-lg-flex justify-content-lg-center">
                        {isDangerRow ? (
                            <>
                                <span ref={tooltipTarget} onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)} className="far fa-print-slash type-20 color-danger" id={`${benType}${name}`}></span>
                                <Tooltip
                                    text={`${getConfigValue(appState.config?.PlanList?.PrintRestrictionTooltip, appState)?.Value}`}
                                    ref={tooltipTarget}
                                    showTooltip={showTooltip}
                                    index={index}
                                />
                            </>
                        ) : (
                            <div>
                                {hasSufficientPlans === false && softPrint ?
                                    <button className="far fa-print-slash type-20 color-grey-dark-1 btn-reset"
                                        onClick={() => setShowPrintRestrictionModal(true)}>
                                    </button>
                                    :
                                    <div className="d-flex flex-column">
                                        <Link to="/PlanListPage"
                                            state={{ tier: coverage, planName: name, planId: planId }}
                                            className="btn btn-outline-primary btn-md btn-icon d-lg-none">
                                            {getConfigValue(appState?.config?.SingleWinPlanListRow?.SelectThisPlan?.Label, appState)?.Value || null}
                                            <span className="fas fa-chevron-right ms-lg-2"></span>
                                        </Link>
                                        <Link to="/PlanListPage"
                                            state={{ tier: coverage, planName: name, planId: planId }}
                                            className="btn btn-link btn-sm pe-0 type-14 d-none d-lg-flex align-items-center">
                                            {getConfigValue(appState?.config?.SingleWinPlanListRow?.SelectThisPlan?.Label, appState)?.Value || null}
                                            <span className="fas fa-chevron-right ms-lg-2"></span>
                                        </Link>
                                    </div>}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <PrintRestrictionModal show={showPrintRestrictionModal} setShow={setShowPrintRestrictionModal} />
        </div>
    );
}

export default SingleWinPlanListRow;
