export default function getName(appState) {
    if (!appState.prescriber) {
        console.warn("No prescriber in appState");
        return "";
    }
    // Default to display name
    if(appState.prescriber.displayName){
        return appState.prescriber.displayName;
    }

    //build the name manually if display name is not available
    const prefix = appState.prescriber?.prefix || "";
    const suffix = appState.prescriber?.suffix || "";
    const firstName = appState.prescriber?.firstName || "";
    const lastName = appState.prescriber?.lastName || "";
    return buildName(prefix, firstName, lastName, suffix);
}

function buildName(prefix, firstName, lastName, suffix) {
    return `${prefix} ${firstName} ${lastName}${suffix ? `, ${suffix}` : ""}`.trim();
}