import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { updateAddress, addAddress, getStates } from '../../API/address';
import isEmptyObject from '../../util/isEmptyObject';
import { v4 as uuid } from 'uuid';
import { AppContext } from "../../util/AppContext";
import { getConfigValue } from "../../util/getConfigValue";
import { useErrorBoundary } from "react-error-boundary";

function AddressPageEdit() {
    const [appState] = useContext(AppContext);
    const { state: locationState } = useLocation(); // pull state, rename as locationState to avoid conflict 
    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm(); // form validation

    const userId = locationState.userId; // userId = email 
    const [currentAddress, setCurrentAddress] = useState(locationState.address || {});
    const [addMode, setAddMode] = useState(isEmptyObject(currentAddress) ? true : false);

    const [currentId, setCurrentId] = useState(currentAddress.id || null);
    const [statesData, setStatesData] = useState([]);
    const [countriesData, setCountriesData] = useState([]);
    const [filteredFormStates, setFilteredFormStates] = useState([]);

    const [isSaving, setIsSaving] = useState(false);
    const [hasSaved, setHasSaved] = useState(false);
    const [formData, setFormData] = useState(null);
    const { showBoundary } = useErrorBoundary();

    const pageTitle = addMode ? (getConfigValue(appState?.config?.AddressPage?.Title?.Add, appState)?.Value || null) :
        (getConfigValue(appState?.config?.AddressPage?.Title?.Edit, appState)?.Value || null)

    const saveButtonLabel = addMode ? (getConfigValue(appState?.config?.AddressPage?.Button?.Add, appState)?.Value || null) :
        (getConfigValue(appState?.config?.AddressPage?.Button?.Save, appState)?.Value || null)

    const navigate = useNavigate();

    // controlled by react-hook-form
    const country = watch('country');
    const phoneNumber = watch('phoneNumber') || ""; // avoid react component error when phoneNumber is undefined

    const zipErrorMessageLookup = {
        "United States": (getConfigValue(appState?.config?.AddressPage?.ZipCode?.ValidationMessage?.UnitedStates, appState)?.Value || null),
        "Canada": (getConfigValue(appState?.config?.AddressPage?.ZipCode?.ValidationMessage?.Canada, appState)?.Value || null)
    };

    const zipValidatorLookup = {
        "United States": (zip) => /\b\d{5}\b/.test(zip),
        "Canada": (zip) => /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(zip),
    };

    useEffect(() => {
        getDropdownFormStates();
    }, []);

    useEffect(() => {

        // get unique countries from getStates API call 
        if (statesData.length > 0 && countriesData.length === 0) {
            const countries = [...new Set(statesData.map(state => state.country))];
            setCountriesData(countries);
        }

        // align form states with form country
        if (country && statesData.length > 0) {
            const filteredStates = statesData.filter(state => state.country.toLowerCase() === country.toLowerCase());

            setFilteredFormStates(filteredStates);
        }
    }, [country, statesData]);

    useEffect(() => {
        if (isEmptyObject(currentAddress)) {
            setAddMode(true);
        } else {
            setAddMode(false);
            if (statesData && countriesData) {
                populateFormFields(currentAddress);
            }
        }
    }, [currentAddress, statesData, countriesData]);

    useEffect(() => {
        if (formData) {
            if (addMode) {
                addFormAddress(userId);
            }
            else {
                updateFormAddress(userId, formData);
            }
        }
    }, [formData]);

    function populateFormFields(address) {
        if (!address) {
            console.error("No address passed in.");
            return;
        }

        setValue('nickName', address.nickName);
        setValue('firstName', address.firstName);
        setValue('lastName', address.lastName);
        setValue('address1', address.address1);
        setValue('address2', address.address2 || "");
        setValue('city', address.city);
        setValue('state', address.state);
        setValue('zip', address.zip);
        setValue('country', address.country);
        setValue('phoneNumber', formatPhoneNumber((address.phoneNumber)));
        setValue('isPrimary', address.isPrimary);
        setValue('id', address.id);
    }

    function updateFormAddress(userId) {
        setIsSaving(true);
        updateAddress(userId, formData)
            .then((responseJson) => {
                if (responseJson === currentId || responseJson === "Previous primary address has been reset") {
                    setHasSaved(true);
                    navigate(-1, { state: { alert: { type: 'success', heading: getConfigValue(appState?.config?.AddressPage?.Success?.Title, appState)?.Value || null, message: getConfigValue(appState?.config?.AddressPage?.Messages?.Update?.Success, appState)?.Value || null } } });
                } else {
                    console.error('Address update failed.');
                    setIsSaving(false);
                    setHasSaved(true);
                    navigate(-1, { state: { alert: { type: 'danger', heading: getConfigValue(appState?.config?.AddressPage?.Failure?.Title, appState)?.Value || null, message: getConfigValue(appState?.config?.AddressPage?.Messages?.Failed, appState)?.Value || null } } });
                }
            })
            .catch(error => {
                setIsSaving(false);
                setHasSaved(true);
                navigate(-1, { state: { alert: { type: 'danger', heading: getConfigValue(appState?.config?.AddressPage?.Failure?.Title, appState)?.Value || null, message: getConfigValue(appState?.config?.AddressPage?.Messages?.Failed, appState)?.Value || null } } });
                console.error(error);
            });
    }

    function addFormAddress(userId) {
        setIsSaving(true);
        addAddress(userId, formData)
            .then((responseJson) => {
                if (responseJson) {
                    setHasSaved(true);
                    navigate(-1, { state: { alert: { type: 'success', heading: getConfigValue(appState?.config?.AddressPage?.Success?.Title, appState)?.Value || null, message: getConfigValue(appState?.config?.AddressPage?.Messages?.Add?.Success, appState)?.Value || null } } });
                }
            })
            .catch(error => {
                setIsSaving(false);
                setHasSaved(true);
                navigate(-1, { state: { alert: { type: 'danger', heading: getConfigValue(appState?.config?.AddressPage?.Failure?.Title, appState)?.Value || null, message: getConfigValue(appState?.config?.AddressPage?.Messages?.Failed, appState)?.Value || null } } });
                console.error(error);
            });
    }

    function getDropdownFormStates() {
        getStates()
            .then((responseJson) => {
                setStatesData(responseJson); // single source of truth for states
                setFilteredFormStates(responseJson); // add all states to form initially 
            })
            .catch(error => {
                showBoundary(error);
            });
    }

    function onSubmit(data) { // this is hit after all validation is passed
        let resultFormData = { ...data };

        // remove hyphens from phone number
        resultFormData.phoneNumber = resultFormData.phoneNumber.replaceAll("-", "");

        if (addMode) {
            resultFormData.id = uuid();
            resultFormData.isPrimary = false;
        }

        setFormData(resultFormData); // triggers useEffect
    }

    function handleStateChange(e) {
        // setState(e.target.value);
        setValue('state', e.target.value, { shouldValidate: true });

        if (e.target.value === "") {
            // if state is empty, reset filtered states to all states
            setFilteredFormStates(statesData);
        }

        if (!country) {
            // if state is selected without country, align form country to state's country
            const formState = watch('state');
            const formCountry = formState ? statesData.find(state => state.state === e.target.value).country : null;

            if (formCountry) {
                setValue('country', formCountry, { shouldValidate: true });
            }
        }
    }

    function formatPhoneNumber(value) {
        const phoneNumber = value.replace(/[^\d]/g, '');

        const areaCode = phoneNumber.substring(0, 3);
        const prefix = phoneNumber.substring(3, 6);
        const lineNumber = phoneNumber.substring(6, 10);

        if (phoneNumber.length > 6) {
            return `${areaCode}-${prefix}-${lineNumber}`;
        } else if (phoneNumber.length === 6) {
            return `${areaCode}-${prefix}-`;
        } else if (phoneNumber.length > 3) {
            return `${areaCode}-${prefix}`;
        } else if (phoneNumber.length === 3) {
            return `${areaCode}-`;
        } else if (phoneNumber.length > 0) {
            return `${areaCode}`;
        }

        return phoneNumber;
    }

    function handlePhoneChange(e) {
        const value = e.target.value;
        const formattedValue = formatPhoneNumber(value);

        setValue('phoneNumber', formattedValue);
    }

    function handlePhoneKeyDown(e) {
        // if last character is auto inserted "-", remove 2 characters to include last number
        if (e.key === 'Backspace' && e.target.value.slice(-1) === "-") {
            setValue('phoneNumber', e.target.value.slice(0, -1));
        }
    }

    function trimWhiteSpace(e) {
        e.target.value = e.target.value.trim();
    }

    return (
        <>
            <nav aria-label="breadcrumb" className="breadcrumb-wrapper">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-12">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/"><span className="fas fa-house"></span><span className="sr-only">Home</span></Link></li>
                                <li className="breadcrumb-item"><Link to="/">Profile</Link></li>
                                <li className="breadcrumb-item"><Link to="/AddressPage">Addresses</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{pageTitle}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </nav>

            <div className="container-xxl mt-4">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        <h1 className="type-24 my-0">{pageTitle}</h1>
                    </div>
                </div>
            </div>

            <div className="container-xxl mt-4">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="card pd-card py-3 mb-5">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-12">
                                            <label htmlFor="nickName" className="form-label">{getConfigValue(appState?.config?.AddressPage?.Nickname?.Label, appState)?.Value || null} *</label>
                                            <input
                                                {...register("nickName", {
                                                    required: (getConfigValue(appState?.config?.AddressPage?.Nickname?.Label, appState)?.Value || null) + " is required.",
                                                    onBlur: trimWhiteSpace,
                                                    maxLength: {
                                                        value: 20,
                                                        message: "Max length is 20.",
                                                    },
                                                })}
                                                type="text"
                                                className={`form-control ${errors.nickName ? "is-invalid" : ""}`}
                                                id="nickname"
                                                aria-label="Nickname"
                                            />
                                            {errors.nickName && <div className="invalid-feedback">{errors.nickName.message}</div>}
                                            <div className="my-4"></div>
                                            <div className="row g-0">
                                                <div className="col-12 col-md mb-4 mb-md-0 pe-md-2">
                                                    <label htmlFor="firstName" className="form-label">{getConfigValue(appState?.config?.AddressPage?.FirstName?.Label, appState)?.Value || null} *</label>
                                                    <input
                                                        {...register("firstName", {
                                                            required: (getConfigValue(appState?.config?.AddressPage?.FirstName?.Label, appState)?.Value || null) + " is required.",
                                                            onBlur: trimWhiteSpace,
                                                            maxLength: {
                                                                value: 50,
                                                                message: "Max length is 50.",
                                                            },
                                                        })}
                                                        type="text"
                                                        className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
                                                        id="firstName"
                                                        aria-label="First name"
                                                    />
                                                    {errors.firstName && <div className="invalid-feedback">{errors.firstName.message}</div>}
                                                </div>
                                                <div className="col-12 col-md ps-md-2">
                                                    <label htmlFor="lastName" className="form-label">{getConfigValue(appState?.config?.AddressPage?.LastName?.Label, appState)?.Value || null} *</label>
                                                    <input
                                                        {...register("lastName", {
                                                            required: (getConfigValue(appState?.config?.AddressPage?.LastName?.Label, appState)?.Value || null) + " is required.",
                                                            onBlur: trimWhiteSpace,
                                                            maxLength: {
                                                                value: 50,
                                                                message: "Max length is 50.",
                                                            },
                                                        })}
                                                        type="text"
                                                        className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
                                                        id="lastName"
                                                        aria-label="Last name"
                                                    />
                                                    {errors.lastName && <div className="invalid-feedback">{errors.lastName.message}</div>}
                                                </div>
                                            </div>
                                            <div className="my-4"></div>
                                            <label htmlFor="address1" className="form-label">{getConfigValue(appState?.config?.AddressPage?.AddressLine1?.Label, appState)?.Value || null} *</label>
                                            <input
                                                {...register("address1", {
                                                    required: (getConfigValue(appState?.config?.AddressPage?.AddressLine1?.Label, appState)?.Value || null) + " is required.",
                                                    onBlur: trimWhiteSpace,
                                                    maxLength: {
                                                        value: 50,
                                                        message: "Max length is 50.",
                                                    },
                                                })}
                                                type="text"
                                                className={`form-control ${errors.address1 ? "is-invalid" : ""}`}
                                                id="address1"
                                                aria-label="Address line 1"
                                            />
                                            {errors.address1 && <div className="invalid-feedback">{errors.address1.message}</div>}
                                            <div className="my-4"></div>
                                            <label htmlFor="address2" className="form-label">{getConfigValue(appState?.config?.AddressPage?.AddressLine2?.Label, appState)?.Value || null}</label>
                                            <input
                                                {...register("address2", {
                                                    required: false,
                                                    onBlur: trimWhiteSpace,
                                                    maxLength: {
                                                        value: 50,
                                                        message: "Max length is 50.",
                                                    },
                                                })}
                                                type="text"
                                                className={`form-control ${errors.address2 ? "is-invalid" : ""}`}
                                                defaultValue=""
                                                id="address2"
                                                aria-label="Address line 2"
                                            />
                                            {errors.address2 && <div className="invalid-feedback">{errors.address2.message}</div>}
                                            <div className="my-4"></div>
                                            <div className="row g-0">
                                                <div className="col-12 col-md mb-4 mb-md-0 pe-md-2">
                                                    <label htmlFor="city" className="form-label">{getConfigValue(appState?.config?.AddressPage?.City?.Label, appState)?.Value || null} *</label>
                                                    <input
                                                        {...register("city", {
                                                            required: (getConfigValue(appState?.config?.AddressPage?.City?.Label, appState)?.Value || null) + " is required.",
                                                            onBlur: trimWhiteSpace,
                                                            maxLength: {
                                                                value: 50,
                                                                message: "Max length is 50.",
                                                            },
                                                        })}
                                                        type="text"
                                                        className={`form-control ${errors.city ? "is-invalid" : ""}`}
                                                        id="city"
                                                        aria-label="City"
                                                    />
                                                    {errors.city && <div className="invalid-feedback">{errors.city.message}</div>}
                                                </div>
                                                <div className="col-12 col-md ps-md-2">
                                                    <label htmlFor="state" className="form-label">{getConfigValue(appState?.config?.AddressPage?.StateProvince?.Label, appState)?.Value || null} *</label>
                                                    <select
                                                        {...register("state", {
                                                            required: (getConfigValue(appState?.config?.AddressPage?.StateProvince?.Label, appState)?.Value || null) + " is required.",
                                                            onChange: handleStateChange,
                                                        })}
                                                        className={`form-control ${errors.state ? "is-invalid" : ""}`}
                                                        aria-label="Select state / province"
                                                        id="state"
                                                    >
                                                        <option defaultValue value="">{getConfigValue(appState?.config?.AddressPage?.DropDown?.Default?.Value, appState)?.Value || null}</option>
                                                        {filteredFormStates.length > 0 && filteredFormStates.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.state}>{item.state}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    {errors.state && <div className="invalid-feedback">{errors.state.message}</div>}
                                                </div>
                                            </div>
                                            <div className="my-4"></div>
                                            <div className="row g-0">
                                                <div className="col-12 col-md mb-4 mb-md-0 pe-md-2">
                                                    <label htmlFor="zip" className="form-label">{getConfigValue(appState?.config?.AddressPage?.PostalCode?.Label, appState)?.Value || null} *</label>
                                                    <input
                                                        {...register("zip", {
                                                            required: (getConfigValue(appState?.config?.AddressPage?.PostalCode?.Label, appState)?.Value || null) + " is required.",
                                                            onBlur: trimWhiteSpace,
                                                            validate: zipValidatorLookup[country] || (() => true), // regex validator based on country
                                                            maxLength: {
                                                                value: 7,
                                                                message: "Max length is 7.",
                                                            },

                                                        })}
                                                        className={`form-control ${errors.zip ? "is-invalid" : ""}`}
                                                        id="zip"
                                                        aria-label="Zip / postal code"
                                                    />
                                                    {errors.zip && errors.zip.type === "validate" && <div className="invalid-feedback">{zipErrorMessageLookup[country]}</div>}
                                                    {errors.zip && <div className="invalid-feedback">{errors.zip.message}</div>}
                                                </div>
                                                <div className="col-12 col-md ps-md-2">
                                                    <label htmlFor="country" className="form-label">{getConfigValue(appState?.config?.AddressPage?.Country?.Label, appState)?.Value || null} *</label>
                                                    <select
                                                        {...register("country", {
                                                            required: (getConfigValue(appState?.config?.AddressPage?.Country?.Label, appState)?.Value || null) + " is required.",
                                                        })}
                                                        className={`form-control ${errors.country ? "is-invalid" : ""}`}
                                                        aria-label="Select country"
                                                        id="country"
                                                    >
                                                        <option value="">{getConfigValue(appState?.config?.AddressPage?.DropDown?.Default?.Value, appState)?.Value || null}</option>
                                                        {countriesData.length > 0 && countriesData.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item}>{item}</option>
                                                            )
                                                        }
                                                        )}
                                                    </select>
                                                    {errors.country && <div className="invalid-feedback">{errors.country.message}</div>}
                                                </div>
                                            </div>
                                            <div className="my-4"></div>
                                            <label htmlFor="phoneNumber" className="form-label">{getConfigValue(appState?.config?.AddressPage?.PhoneNumber?.Label, appState)?.Value || null} *</label>
                                            <input
                                                {...register("phoneNumber", {
                                                    required: (getConfigValue(appState?.config?.AddressPage?.PhoneNumber?.Label, appState)?.Value || null) + " is required.",
                                                    onChange: (e) => handlePhoneChange(e),
                                                    onBlur: trimWhiteSpace,
                                                    minLength: {
                                                        value: 12, // 10 digits + 2 dashes
                                                        message: "Phone must be 10 digits long.",
                                                    },
                                                })}
                                                placeholder="xxx-xxx-xxxx"
                                                type="text"
                                                className={`form-control ${errors.phoneNumber ? "is-invalid" : ""}`}
                                                id="phoneNumber"
                                                value={phoneNumber}
                                                onKeyDown={handlePhoneKeyDown}
                                                aria-label="Phone number"
                                            />
                                            {errors.phoneNumber && <div className="invalid-feedback">{errors.phoneNumber.message}</div>}
                                            <div className="my-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center justify-content-md-end">
                                <div className="col-12 col-sm-8 col-md-8 col-lg-7 col-xl-6">
                                    <div className="d-flex flex-column flex-md-row-reverse">
                                        <button disabled={isSaving ? true : null} type="submit" className={`btn ${isSaving && !hasSaved ? 'btn-working' : ""} btn-success btn-lg mb-3 mb-md-0 ms-md-3 flex-fill w-100`}>
                                            {saveButtonLabel}
                                        </button>

                                        <button disabled={isSaving ? true : null} onClick={() => navigate(-1)} className="btn btn-secondary btn-lg flex-fill w-100">
                                            {getConfigValue(appState?.config?.Common?.Cancel, appState)?.Value || null}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-7"></div>
                            <input type="hidden" {...register("id")} />
                            <input type="hidden" {...register("isPrimary")} />
                        </form>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddressPageEdit;