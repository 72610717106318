import { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AppContext } from '../util/AppContext';
import { getConfigValue } from '../util/getConfigValue';

export function ProductsModal({ show, setShow, onProductClick }) {
    const [appState, setAppState] = useContext(AppContext);
    
    function filterProducts(productDetail) {
        if (appState.products.length >0) {
            const product = appState?.products?.find(p => p.productId === productDetail.clientProductId)
                return (product && product?.messages?.length > 0);
        }
        return true;
    }

    return (
        <Modal show={show} onHide={() => setShow(false)} scrollable>
            <Modal.Header className="primary" closeButton>
                <Modal.Title className="type-18">{getConfigValue(appState.config?.ProductModal?.SelectBrand?.Label, appState)?.Value || null}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul className="list-group list-group-flush">
                    {appState?.productDetails?.filter(filterProducts).map((product, index) => (
                        <li key={index} className="list-group-item border border-0">
                            <button onClick={() => onProductClick(product)} className="btn btn-tile btn-tile-outline d-block w-100 text-center py-3">
                                <img style={{ 'maxWidth': '100%' }} src={product.image} className="" alt={`${product.name} logo`} />
                            </button>
                        </li>
                    ))}
                </ul>
            </Modal.Body>
        </Modal>
    );
}

export function ProductsModalButton({ setShowProductsModal, productImageSource, productName }) {
    const [appState, setAppState] = useContext(AppContext);

    return (
        <>
            <button onClick={() => setShowProductsModal(true)} className="border-0 px-0 bg-transparent text-start d-block w-100" data-bs-toggle="modal" data-bs-target="#selectBrand">
                <div className="border border-dark-subtle card p-2 px-3 d-flex flex-row justify-content-between align-items-center">
                    <img style={{ 'maxWidth': '100%' }} src={productImageSource} className="" alt={`${productName} logo`} />
                </div>
                <p className="m-0 mt-2 type-12 color-primary text-decoration-underline text-decoration-hover-none">{getConfigValue(appState.config?.ProductModal?.ChangeBrand?.Label, appState)?.Value || null}</p>
            </button>
        </>
    );
}