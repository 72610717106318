import { useState, useEffect, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AppContext } from '../util/AppContext';
import { getConfigValue } from '../util/getConfigValue';
import PropType from 'prop-types';

TerritoriesModal.propTypes = {
    show: PropType.bool,
    setShow: PropType.func,
    onTerritoryClick: PropType.func,
    currentTerritory: PropType.string
};

function TerritoriesModal({ show, setShow, markets, territories, currentTerritory, onTerritoryClick }) {
    const [appState, setAppState] = useContext(AppContext);
    const [filteredTerritories, setFilteredTerritories] = useState(territories);
    const [selectedTerritory, setSelectedTerritory] = useState(currentTerritory);
    const [input, setSearchInput] = useState('');

    let timer;

    const defaultSelectionText = getConfigValue(appState.config?.TerritoryModal?.DefaultSelectionText, appState)?.Value || null;

    useEffect(() => {
        // cleanup
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        }
    }, [])

    useEffect(() => {
        if (currentTerritory === null) {
            setSelectedTerritory(currentTerritory)
        }
    }, [currentTerritory])

    function resetModal() {
        // avoid showing reset changes in UI as modal closes
        timer = setTimeout(() => {
            setSearchInput('');
            setFilteredTerritories(territories);
        }, 250);
    }

    function handleHide() {
        setShow(false);
        resetModal();
    }

    function handleSelect() {
        onTerritoryClick(selectedTerritory);
        resetModal();
    }

    function handleChange(event) {
        const value = event.target.value;

        if (value === defaultSelectionText) {
            setFilteredTerritories(territories);
        } else {
            setFilteredTerritories(territories.filter((territory) => territory.marketName === value));
        }
    }

    function handleSearchInput(event) {
        const search = event.target.value.trim();

        if (!search) {
            setFilteredTerritories(territories);
            return;
        }

        setSearchInput(search);
        setFilteredTerritories(territories.filter((territory) => territory.name.toLowerCase().includes(search.toLowerCase())));
    }

    return (
        <Modal
            show={show}
            onHide={handleHide}
            scrollable
        >
            <Modal.Header className="primary" closeButton>
                <Modal.Title className="type-18">{getConfigValue(appState.config?.TerritoryModal?.Title, appState)?.Value || null}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {markets.length > 1 && (
                    <>
                        <label htmlFor="territories-market-select" className="form-label">{getConfigValue(appState.config?.TerritoryModal?.SelectMarket?.Label, appState)?.Value || null}</label>
                        <select onChange={handleChange} className="form-select" aria-label="Select a market">
                            <option defaultValue disabled="">{defaultSelectionText}</option>
                            {markets.map((market, index) => (
                                <option key={index}>{market.name}</option>
                            ))}
                        </select>
                    </>
                )}

                <div className={markets.length > 1 ? "my-4" : "my-1"}></div>
                <label htmlFor="territories-search" className="form-label">{getConfigValue(appState.config?.TerritoryModal?.SearchTerritories?.Label, appState)?.Value || null}</label>
                <input onChange={handleSearchInput} type="text" className="form-control icon-search" id="territories-search" aria-label="Search territories" />
                <div className="my-3"></div>
                <ul className="list-group list-group-flush">
                    {filteredTerritories.length > 0 && filteredTerritories.map((territory, index) => (
                        <li key={index} className="list-group-item px-0 py-3 d-flex align-items-center">
                            <div className="form-check d-flex align-items-center">
                                <input checked={selectedTerritory && selectedTerritory.id === territory.id && selectedTerritory.marketName === territory.marketName ? true : false} onChange={() => setSelectedTerritory(territory)} className="form-check-input" type="radio" name="select-territory" value="" id={`add-territory-${index}`} />
                                <label className="form-check-label ms-2" htmlFor={`add-territory-${index}`}>{territory.name}
                                    <span className="d-block type-14 color-grey-d1">{territory.marketName}</span></label>
                            </div>
                        </li>
                    ))}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <div className="container-fluid mx-0">
                    <div className="row justify-content-md-end px-0">
                        <div className="col-12 col-md-9 col-lg-7 px-0">
                            <div className="d-flex w-100 flex-column flex-md-row-reverse">
                                <button onClick={handleSelect} className="btn btn-primary btn-lg mb-3 mb-md-0 ms-md-3 flex-fill w-100">{getConfigValue(appState.config?.TerritoryModal?.Select?.Button?.Label, appState)?.Value || null}</button>
                                <button onClick={handleHide} className="btn btn-secondary btn-lg flex-fill w-100" data-bs-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default TerritoriesModal;