import { useEffect, useRef } from "react";
import PropType from 'prop-types';

PdfViewerComponent.propTypes = {
    document: PropType.any,
};

export default function PdfViewerComponent(props) {
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current; // This `useRef` instance will render the PDF.
        
        let PSPDFKit;

        (async function () {
            if (!props.document) {
                return;
            }
            PSPDFKit = await import("pspdfkit")

            PSPDFKit.unload(container) // Ensure that there's only one PSPDFKit instance.

            await PSPDFKit.load({
                // Container where PSPDFKit should be mounted.
                container,
                // The document to open.
                document: props.document,
                // Toolbar
                toolbarItems: [], 
                // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
                baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
                licenseKey: `${process.env.REACT_APP_PSPDFKEY}`

            });
        })();

        return () => PSPDFKit?.unload(container)
    }, [props.document]);

    // This div element will render the document to the DOM.
    return <div ref={containerRef} style={{ width: "100%", height: "100vh" }} />
}