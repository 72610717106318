import React, { useState, useContext } from "react";
import AddressModal from "./AddressModal";
import formatPhoneNumber from "../util/formatPhoneNumber";
import { AppContext } from "../util/AppContext";
import { getConfigValue } from "../util/getConfigValue";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";

function AddressRow({ address, userId, setAlert, loadAddresses }) {
  const [appState] = useContext(AppContext);
  const {
    id,
    nickName,
    firstName,
    lastName,
    address1,
    address2,
    city,
    state,
    zip,
    country,
    phoneNumber,
    dataSource,
    isPrimary,
  } = address;

  
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [alertLevel, setAlertLevel] = useState("danger");
  const [action, setAction] = useState(null);
  const navigate = useNavigate();

  const [isRowVisible, setIsRowVisible] = useState(true); // remove from DOM when deleted

  const isReadOnly = dataSource.toLowerCase() === "dataloader" ? true : false;

  function handleRemoveClick() {
    if (isPrimary) {
      setAlertLevel("primary");
      setAction("primaryError");
      setShowAddressModal(true);
    } else {
      setAlertLevel("danger");
      setAction("delete");
      setShowAddressModal(true);
    }
  }

  function handleSetPrimaryClick() {
    setAlertLevel("primary");
    setAction("setPrimary");
    setShowAddressModal(true);
  }

  function navigateToEdit() {
    navigate("/AddressPage-edit", {
      state: { address: address, userId: userId },
    });
  }

  return (
    <>
      {isRowVisible && (
        <li className="list-group-item py-3 py-md-5">
          <div className="row">
            <div className="col-12 col-md-3 mb-2 mb-md-0">
              <span className="badge rounded-pill type-14 text-bg-primary mb-md-2 px-3">
                {nickName}
              </span>
              {isPrimary && (
                <div className="d-inline ms-4 d-md-block ms-md-0">
                  <span className="far fa-check color-success me-2"></span>
                  <span className="color-grey-d1 type-14">
                    {getConfigValue(
                      appState?.config?.AddressManagement?.Row?.PrimaryAddress,
                      appState
                    )?.Value || null}
                  </span>
                </div>
              )}
            </div>
            <div className="col-10 col-md">
              <p className="my-0">
                <span className="type-bold d-block">
                  {firstName} {lastName}
                </span>
                <span className="d-block">{address1}</span>
                {address2 && <span className="d-block">{address2}</span>}
                <span className="d-block">
                  {city} {state} {zip}
                </span>
                <span className="d-block">{country}</span>
                <span className="d-block">
                  {formatPhoneNumber(phoneNumber)}
                </span>
              </p>

              {isReadOnly && (
                <p className="color-grey-d1 my-0 mt-3 type-14">
                  {getConfigValue(
                    appState?.config?.AddressManagement?.Row?.Message
                      ?.DefaultAddress,
                    appState
                  )?.Value || null}
                </p>
              )}
            </div>
            <div className="col-2 col-lg-1 d-flex justify-content-end align-items-center">
              <Dropdown>
                <Dropdown.Toggle className="btn btn-ghost-secondary btn-sm btn-icon-only">
                  <span className="fa-regular fa-ellipsis btn-icon"></span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {!isReadOnly && (
                    <>
                      <Dropdown.Item
                        onClick={() => navigateToEdit()}
                        className="dropdown-item cursor"
                        role="button"
                      >
                        {getConfigValue(
                          appState?.config?.AddressManagement?.Row?.Edit?.Label,
                          appState
                        )?.Value || null}
                      </Dropdown.Item>
                      <hr className="dropdown-divider" />
                      <Dropdown.Item
                        onClick={handleRemoveClick}
                        className="dropdown-item cursor"
                        role="button"
                      >
                        {getConfigValue(
                          appState?.config?.AddressManagement?.Row?.Remove
                            ?.Label,
                          appState
                        )?.Value || null}
                      </Dropdown.Item>
                      <hr className="dropdown-divider" />
                    </>
                  )}
                  <Dropdown.Item
                    disabled={isPrimary ? true : false}
                    onClick={handleSetPrimaryClick}
                    className="dropdown-item cursor"
                    role="button"
                  >
                    {getConfigValue(
                      appState?.config?.AddressManagement?.Row?.SetPrimary
                        ?.Label,
                      appState
                    )?.Value || null}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </li>
      )}

      <AddressModal
        show={showAddressModal}
        setShow={setShowAddressModal}
        alertLevel={alertLevel}
        userId={userId}
        address={address}
        setIsRowVisible={setIsRowVisible}
        setAlert={setAlert}
        action={action}
        loadAddresses={loadAddresses}
      />
    </>
  );
}

export default AddressRow;
