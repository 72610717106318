export function reactSelectifyProducts(products) {
    if (products && products.length === 0) {
        return [];
    }

    return products.map(product => {
        // react-select needs "label", "value", and "image"
        return {
            ...product,
            label: product.productName,
            value: product.productId,
            image: product.imagePath,
        }
    })
};