export async function logToServer(logLevel, message) {
    const url = `${process.env.REACT_APP_API_URL}Log`;
    const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ logLevel, message })
    });
}