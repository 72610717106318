export function filterByValue(arrayOfObjects, searchString, arrayOfKeys, allFields) {
    if (!Array.isArray(arrayOfObjects)) {
        return [];
    }
    const results = arrayOfObjects.filter(object => {
        let evaluateObject = object;
        //handle case of dynamic tables, filter on the displayed columns
        if (object.columns && object.data) {
            evaluateObject = object.columns;
        }

        return Object.keys(evaluateObject).some(key => {
            if (typeof evaluateObject[key] === 'string' && (allFields || arrayOfKeys?.includes(key))) {
                //This handles the case of HTML tags that begin with "<" and searches everything betweend till the end of the "> tag. It will remove everything between them."
                const cleanValue = evaluateObject[key].toLowerCase()?.replace(/(<([^>]+)>)/gi, "");
                return cleanValue?.includes(searchString.toLowerCase().replace(",", ""))
            }
            return null;
        })
    });
    return results;
}