import { React, useEffect, useState, useRef, useContext } from 'react';
import AddTerritoryModal from "./AddTerrtitoryModal";
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';
import { AppContext } from '../../util/AppContext';
import { getConfigValue } from "../../util/getConfigValue";

AddProductRow.propTypes = {
    product: PropTypes.object.isRequired,
    selectedTerritories: PropTypes.array,
    canAccessAllTerritories: PropTypes.bool.isRequired,
    onChangeTerritory: PropTypes.func.isRequired
};

function AddProductRow({ product, selectedTerritories, canAccessAllTerritories, onChangeTerritory }) {
    const [showTerritoryModal, setShowTerritoryModal] = useState(false);
    const [applicationData, setApplicationData] = useState([]);
    const [accessAllTerritories, setAccessAllTerritories] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipTarget = useRef(null);
    const [appState] = useContext(AppContext);

    function addSelectedTerritories(selectedTerrtitores) {
        setApplicationData([...selectedTerrtitores]);
        onChangeTerritory(product.id, selectedTerrtitores, accessAllTerritories);
    }

    function handleAddTerritoryClick(e) {
        e.preventDefault();
        setApplicationData(selectedTerritories);
        setShowTerritoryModal(true);
    }

    useEffect(() => {
        setApplicationData(selectedTerritories);
        setAccessAllTerritories(canAccessAllTerritories);
    }, [canAccessAllTerritories]);

    return (
        <>
            <div className="row justify-content-between align-items-center mt-4">
                <div className="col-8 col-md-4">
                    <img src={product.imagePath} className="w-100" alt={product.name} />
                    {!accessAllTerritories && selectedTerritories.length > 0 &&
                        <p className="my-0 mt-2 color-grey-d1 type-md-12">{getConfigValue(appState?.config?.UserManagement?.User?.Product?.AccessTo?.Label, appState)?.Value || null} {selectedTerritories.length ?? 0} {getConfigValue(appState?.config?.UserManagement?.User?.Product?.Territories?.Label, appState)?.Value || null}</p>
                    }
                    {!accessAllTerritories && selectedTerritories.length === 0 &&
                        <p className="my-0 mt-2 color-grey-d1 type-md-12">{getConfigValue(appState?.config?.UserManagement?.User?.Product?.AccessTo?.Label, appState)?.Value || null} {getConfigValue(appState?.config?.UserManagement?.User?.Product?.NoAccess?.Label, appState)?.Value || null}</p>
                    }
                    {accessAllTerritories &&
                        <p className="my-0 mt-2 color-grey-d1 type-md-12">{getConfigValue(appState?.config?.UserManagement?.User?.Product?.AccessTo?.Label, appState)?.Value || null} {getConfigValue(appState?.config?.UserManagement?.User?.Product?.AllAccess?.Label, appState)?.Value || null}</p>
                    }
                </div>
                <div className="col-12 col-md-auto mt-4 mt-md-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="accessAllTerritories"
                                checked={accessAllTerritories}
                                onChange={(e) => {
                                    setAccessAllTerritories(!accessAllTerritories);
                                    onChangeTerritory(product.id, [], !accessAllTerritories);
                                }}>
                            </input>
                            <label className="form-check-label" htmlFor="grantall_brand1">{getConfigValue(appState?.config?.UserManagement?.User?.GrantAllTerritories?.Title, appState)?.Value || null}</label>
                        </div>

                        <button type="button"
                            className="btn-reset btn-plantable-tooltip mx-4"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            data-bs-custom-class="pd-tooltip"
                            ref={tooltipTarget}
                            onMouseOver={() => setShowTooltip(true)}
                            onFocus={() => setShowTooltip(true)}
                            onMouseOut={() => setShowTooltip(false)}
                            onBlur={() => setShowTooltip(false)}>
                            <span className="far fa-question-circle type-20 color-precision-bright"></span>
                        </button>
                        <Tooltip
                            text={getConfigValue(appState?.config?.UserManagement?.User?.GrantAllTerritories?.Tooltip, appState)?.Value || null}
                            ref={tooltipTarget}
                            showTooltip={showTooltip}
                        />

                        <button disabled={accessAllTerritories} onClick={e => handleAddTerritoryClick(e)} className="btn btn-ghost btn-md btn-icon-only d-none d-md-flex" data-bs-toggle="modal" data-bs-target="#addTerritories">
                            <span className="fa-regular fa-pencil btn-icon"></span>
                        </button>

                        <button disabled={accessAllTerritories} onClick={e => handleAddTerritoryClick(e)} className="btn btn-outline-primary btn-sm btn-icon d-md-none" data-bs-toggle="modal" data-bs-target="#addTerritories">
                            {getConfigValue(appState?.config?.UserManagement?.User?.Product?.EditTerritories?.Label, appState)?.Value || null}<span className="fa-regular fa-pencil btn-icon"></span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="border-bottom pt-4"></div>
                </div>
            </div>

            <AddTerritoryModal
                show={showTerritoryModal}
                setShow={setShowTerritoryModal}
                applicationData={applicationData}
                addSelectedTerritories={addSelectedTerritories} />
        </>
    );
}
export default AddProductRow;
