import React from 'react';
import Overlay from 'react-bootstrap/Overlay';
import ReactTooltip from 'react-bootstrap/Tooltip';


const Tooltip = React.forwardRef(({ text, showTooltip, index, placement="bottom"}, ref) => {
    return (
        <Overlay target={ref.current} show={showTooltip} key={index} placement={placement}>
            {(props) => (
                <ReactTooltip {...props} style={{ ...props.style, pointerEvents: "none"}}>
                    {text}
                </ReactTooltip>
            )}
        </Overlay>
    );
});


export default Tooltip;
