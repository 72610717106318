import React, { useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import { getConfigValue } from "../../util/getConfigValue";
import { AppContext } from '../../util/AppContext';
import PropType from "prop-types";

RestrictionModal.propTypes = {
    show: PropType.bool,
    setShow: PropType.func,
};

function RestrictionModal({ show, setShow }) {
    const [appState] = useContext(AppContext);

    return (
        <Modal show={show} className="modal-lg" onHide={() => setShow(false)} scrollable={true}>
            <Modal.Header className="primary" closeButton>
                <Modal.Title className="modal-title fs-5">{getConfigValue(appState.config?.MessagePlans?.PrintRestriction?.Modal.Title, appState)?.Value}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="type-20 mt-2 mb-3 pb-2">{getConfigValue(appState.config?.MessagePlans?.PrintRestriction?.Modal.BodyOne, appState)?.Value}</div>
                <div className="type-20 type-bold mt-2 mb-3 pb-2">{getConfigValue(appState.config?.MessagePlans?.PrintRestriction?.Modal.HeaderOne, appState)?.Value}</div>
                <div className="type-20 mt-2 mb-3 pb-2">
                    {getConfigValue(appState.config?.MessagePlans?.PrintRestriction?.Modal.BodyTwo, appState)?.Value}
                    {getConfigValue(appState.config?.MessagePlans?.PrintRestriction?.Modal.BodyThree, appState)?.Value}
                    {getConfigValue(appState.config?.MessagePlans?.PrintRestriction?.Modal.BodyFour, appState)?.Value}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default RestrictionModal;


