import React, { useContext } from "react";
import { AppContext } from "../util/AppContext";
import { getConfigValue } from "../util/getConfigValue";
import Mustache from "mustache";

export function Pagination({ numberOfPages, currentPage, setCurrentPage }) {
    const pageNumbers = [...Array(numberOfPages + 1).keys()].slice(1);
    const [appState, setAppState] = useContext(AppContext);
    const messageTemplate = getConfigValue(appState.config.Common?.PaginationText, appState)?.Value;
    const nextPage = (e) => {
        e.preventDefault();
        if (currentPage !== numberOfPages) {
            setCurrentPage(currentPage + 1, e);
        }
    }
    const prevPage = (e) => {
        e.preventDefault();
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1, e);
        }
    }

    return (

        <nav aria-label="Page navigation">
            <div className="d-flex justify-content-center d-md-none">{messageTemplate && Mustache.render(messageTemplate, { currentPage, numberOfPages })}</div>
            <ul className="pagination justify-content-center">
                <li className={`page-item ${currentPage == 1 ? 'disabled' : ''}`}>
                    <a className="page-link" href="#" onClick={prevPage}>
                        <span aria-hidden="true">
                            <span className="fa-regular fa-chevrons-left me-2"></span>{getConfigValue(appState.config.Common?.Previous, appState)?.Value}
                        </span>
                    </a>
                </li>
                {
                    pageNumbers.map((page) => (
                        <li key={page} className={`page-item ${currentPage == page ? 'active' : ''} d-none d-md-block `} aria-current={`${currentPage == page ? 'page' : ''}`}>
                            <a className="page-link" onClick={(e) => setCurrentPage(page, e)} href="#">{page}</a>
                        </li>
                    ))
                }
                <li className={`page-item ${currentPage == numberOfPages ? 'disabled' : ''}`}>
                    <a className="page-link" href="#" onClick={nextPage}>
                        <span aria-hidden="true">
                            {getConfigValue(appState.config.Common?.Next, appState)?.Value}<span className="fa-regular fa-chevrons-right ms-2"></span>
                        </span>
                    </a>
                </li>
            </ul>
        </nav>
    )
}