export async function getGroupPracticesByTerritory(marketId, territoryId) {
	if (!marketId || !territoryId) {
		throw new Error('Missing required parameters');
	}

	const url = `${process.env.REACT_APP_API_URL}GroupPracticesByTerritory/${encodeURIComponent(marketId)}/${encodeURIComponent(territoryId)}`;
	const response = await fetch(url, {
		method: 'GET',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
		},
	});

	if (response.status === 200) {
		return await response.json();
	} else {
		throw response.statusText;
	}
}

export async function getGroupPractice(filter) {
	const url = `${process.env.REACT_APP_API_URL}GroupPractices/${encodeURIComponent(filter)}`;
	const response = await fetch(url, {
		method: 'GET',
		credentials: 'include',
	});

	if (response.status === 200) {
		return await response.json();
	} else {
		throw response.statusText;
	}
}


export async function getGroupPracticeById(id, marketId, territoryId) {
	if (!marketId || !territoryId) {
		throw new Error('Missing required parameters');
	}

	const url = `${process.env.REACT_APP_API_URL}GroupPractice/${encodeURIComponent(id)}?marketId=${encodeURIComponent(marketId)}&territoryId=${encodeURIComponent(territoryId)}`;
	const response = await fetch(url, {
		method: 'GET',
		credentials: 'include',
	});

	if (response.status === 200) {
		return await response.json();
	} else {
		throw response.statusText;
	}
}