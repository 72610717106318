import { logToServer } from "../API/logging.js";
import GenericErrorMessage from "./GenericErrorMessage.js";
import { ErrorBoundary } from "react-error-boundary";

export default function ReactErrorBoundary(props) {
    return (
        <ErrorBoundary
            FallbackComponent={GenericErrorMessage}
            onError={(error, errorInfo) => {
                // log the error
                console.error(error);  
                console.error(errorInfo);
                //try to log to server
                try{
                    logToServer("Error", JSON.stringify({error, errorInfo}));
                }catch(e){
                    console.error("Error logging to server: ", e);
                }
            }}
            onReset={() => {
                sessionStorage.clear();
                window.location.href = `${process.env.REACT_APP_API_RETURN_URL}`;
            }}
        >
            {props.children}
        </ErrorBoundary>
    );
}