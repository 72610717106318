import React, { useContext } from "react";
import { AppContext } from "../../util/AppContext";
import { getConfigValue } from "../../util/getConfigValue";
import PropTypes from 'prop-types';
import prettyDate from '../../util/prettyDate';

DashboardItemRowHistoryItem.propTypes = {
    historyItem: PropTypes.any.isRequired,
};

function DashboardItemRowHistoryItem({ historyItem }) {
    const [appState] = useContext(AppContext);
    const createdOn = prettyDate(historyItem.createdOnUtc, (getConfigValue(appState?.config?.System?.DateTime?.Format, appState)?.Value || null));

    function getItemStatus(status) {
        switch (status) {
            case -1:
                return getConfigValue(appState?.config?.ProfessionalPrinting.History.Status.Error, appState)?.Value || null;
            case 2:
                return getConfigValue(appState?.config?.ProfessionalPrinting.History.Status.Completed, appState)?.Value || null;
            default:
                return getConfigValue(appState?.config?.ProfessionalPrinting.History.Status.Queued, appState)?.Value || null;
        }
    }

    function getIconClass(status) {
        switch (status) {
            case -1:
                return "fa-circle-exclamation text-warning";
            case 2:
                return "fa-check text-success"
            default:
                return ""
        }
    }

    return (
        <div className="row align-items-lg-center mx-0 py-2 border-bottom">
            <div className="pd-plan-col col-sm col-lg-4 offset-lg-1">
                <div className="row align-items-lg-center">
                    <div className="col-12">
                        <span className="pd-plan-table-label d-block d-lg-none"></span>{createdOn}
                    </div>
                </div>
            </div>
            <div className="pd-plan-col col-sm col-lg-5">
                <div className="row align-items-lg-center">
                    <div className="col-sm col-lg">
                        <span className="pd-plan-table-label d-block d-lg-none"></span>
                        <span className={'fa-regular me-2 ' + getIconClass(historyItem.status)}></span>{getItemStatus(historyItem.status)}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default DashboardItemRowHistoryItem;
