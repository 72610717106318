import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from "../util/AppContext";
import { getConfigValue } from '../util/getConfigValue';

function CategoryDropdown(props) {
    const [appState, setAppState] = useContext(AppContext);
    const {
        pageCategory,
        onDetailsClick,
    } = props;
    function handleDetailsClick() {
        onDetailsClick();
    }
    return (

        <button
        className="border-0 px-0 bg-transparent text-start d-block w-100"
        onClick={handleDetailsClick}
        >
        <div className="border border-dark-subtle card p-2 px-3">
            <p className="my-0 color-grey-d1 type-14">{getConfigValue(appState.config?.PlanList?.MessageTypeLabel, appState)?.Value}</p>
            <p className="my-0 type-14">{pageCategory.category}</p>
        </div>
        <p className="m-0 mt-2 type-12 color-primary text-decoration-underline text-decoration-hover-none">{getConfigValue(appState.config?.PlanList?.MessageTypeLabelInstruction, appState)?.Value}</p>
        </button>
    );
}

export default CategoryDropdown;