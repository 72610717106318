import Modal from 'react-bootstrap/Modal';
import React, { useContext } from 'react';
import { getConfigValue } from '../util/getConfigValue';
import { AppContext } from '../util/AppContext';

function MessageDetailsModal({ show, setShow, categories, onDetailsCategoryClick }) {
    const [appState, setAppState] = useContext(AppContext);

    return (
        <Modal show={show} className="modal-lg" onHide={() => setShow(false)}>
            <Modal.Header className="primary" closeButton>
                <Modal.Title className="type-18">{getConfigValue(appState.config?.MessageDetail?.Modal?.Title, appState)?.Value}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {categories.length > 0 && categories.map((category, index) => (
                    <div className="row justify-content-between" key={index}>
                        {index > 0 && <hr className="my-3" />}
                        <div className="col-12 col-lg-7">
                            <h2 className="type-20 fw-bold">{category.category}</h2>
                            <p className="my-md-0" dangerouslySetInnerHTML={{
                                __html: category.message,
                            }}></p>
                        </div>
                        <div className="col-12 col-lg-4 d-lg-flex flex-column justify-content-lg-center">
                            <button
                                onClick={() => onDetailsCategoryClick(category)}
                                className="btn btn-outline-primary btn-icon mt-2">
                                {category.category}
                                <span className="fa-regular fa-chevron-right btn-icon"></span>
                            </button>
                        </div>
                    </div>
                ))}
            </Modal.Body>
        </Modal>
    );
}

export default MessageDetailsModal;