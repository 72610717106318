import { useRef, useContext, useState } from 'react';
import { AppContext } from "../util/AppContext";
import { getConfigValue } from "../util/getConfigValue";
import AddressDetails from '../components/AddressEntry';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';

CreateDocumentAddNewAddressModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.any.isRequired,
    onSuccessfulSave: PropTypes.any.isRequired,
};

export default function CreateDocumentAddNewAddressModal({ show, setShow, onSuccessfulSave }) {
    const [appState] = useContext(AppContext);
    const [isSaving, setIsSaving] = useState(false);
    const childRef = useRef(null);

    const handleClick = () => {
        childRef.current.onSubmitForm();
    };

    const handleClose = () => setShow(false);
    const handleSave = () => {
        setIsSaving(true);
        setShow(false);
        onSuccessfulSave();
        setIsSaving(false);
    }

    const handleSaveError = () => {
        setIsSaving(false);
    }

    return (
        <Modal className="modal-lg" show={show} onHide={handleClose}>
            <Modal.Header className="primary" closeButton >
                {getConfigValue(appState?.config?.AddressPage?.Title?.Add, appState)?.Value || null}
            </Modal.Header>
            <Modal.Body>
                <AddressDetails ref={childRef} onSave={handleSave} onError={handleSaveError} />
            </Modal.Body>
            <Modal.Footer>
                <Button size="lg" variant="secondary" onClick={handleClose}>
                    {getConfigValue(appState?.config?.Common?.Cancel, appState)?.Value || null}
                </Button>
                <Button size="lg" variant="success" onClick={handleClick} disabled={isSaving}>
                    {getConfigValue(appState?.config?.AddressPage?.Button?.Add, appState)?.Value || null}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}