import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../util/AppContext";
import { Link } from "react-router-dom";
import { getConfigValue } from "../util/getConfigValue";
import { ProductsModal, ProductsModalButton } from "../components/ProductsModal";
import { downloadTemplate } from "../API/documentGenerate";
import ToastAlert from "../components/ToastAlert";

export function HelpContactPage() {
    const [appState, setAppState] = useContext(AppContext);
    const [showProductsModal, setShowProductsModal] = useState(false);
    const [currentProduct, setCurrentProduct] = useState();
    const [productImageSource, setProductImageSource] = useState();
    const [helpDocuments, setHelpDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastTitle, setToastTitle] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [loadingDocument, setLoadingDocument] = useState('');


    useEffect(() => {
        if (appState?.currentProduct === "") {
            setCurrentProduct(appState?.productDetails[0]);
            setProductImageSource(appState?.productDetails[0]?.imagePath);
            setAppState({ ...appState, currentProduct: appState?.productDetails[0] });
        } else {
            setCurrentProduct(appState?.currentProduct);
            setProductImageSource(appState?.currentProduct?.imagePath);
        }
    }, [])

    useEffect(() => {
        if (!appState.config.HelpContact?.Documents || !currentProduct) {
            return;
        }
        var documents = [];

        if (!Array.isArray(appState.config.HelpContact.Documents)) {
            // documents MUST be an array
            const documentItem = appState.config.HelpContact.Documents;
            appState.config.HelpContact.Documents = [documentItem];
        }

        appState.config.HelpContact.Documents.forEach(element => {
            if (!element.ClientProductId || currentProduct.clientProductId === element.ClientProductId) {
                documents.push({
                    DocumentId: element.Value,
                    Name: element.DataField
                })
            }
        });
        setHelpDocuments(documents);
    }, [appState, currentProduct])

    function triggerToastAlert(title, message) {
        setToastTitle(title);
        setToastMessage(message);
        setShowToast(true);
    }

    function getMessages() {
        let messageElemets = '';
        if (!appState.config.HelpContact?.ContactMessage) {
            return messageElemets;
        }
        appState.config.HelpContact?.ContactMessage.forEach(element => {
            messageElemets += element.Value;
        });
        return messageElemets;
    }

    function onProductClick(product, event) {
        if (isLoading) {
            event.preventDefault();
            return;
        }
        setCurrentProduct(product);
        setShowProductsModal(false);
        setProductImageSource(product.imagePath);
        setAppState({ ...appState, currentProduct: product });
    }

    async function downloadDocument(id, event) {
        event.preventDefault();
        if (isLoading) {
            return;
        }
        try {
            setIsLoading(true);
            setLoadingDocument(id);
            const response = await downloadTemplate(id);
            setIsLoading(false);
            setLoadingDocument(null);
            const url = URL.createObjectURL(response.blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = response.fileName;
            link.click();
        } catch (ex) {
            setIsLoading(false);
            setLoadingDocument(null);
            console.error(ex);
            triggerToastAlert("Error", getConfigValue(appState.config.HelpContact?.DownLoad?.Error?.Message, appState)?.Value || null);
        }
    }

    return (
        <>
            <nav aria-label="breadcrumb" className="breadcrumb-wrapper">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-12">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/"><span className="fas fa-house"></span><span className="sr-only">{getConfigValue(appState?.config?.NavBar?.Menu?.Home?.Label, appState)?.Value || null}</span></Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{getConfigValue(appState.config.HelpContact?.Header, appState)?.Value}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="container-xl mt-4">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <h1 className="type-24 mb-4">{getConfigValue(appState.config.HelpContact?.Header, appState)?.Value}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="card pd-card p-4 mb-4 mb-md-0">
                            <h2 className="type-20 type-bold">{getConfigValue(appState.config.HelpContact?.HelpTitle, appState)?.Value}</h2>
                            <p>{getConfigValue(appState.config.HelpContact?.HelpMessage, appState)?.Value}</p>
                            <div className="dropdown-rc mt-3 mb-4">
                                <ProductsModalButton
                                    setShowProductsModal={setShowProductsModal}
                                    productImageSource={productImageSource}
                                    productName={currentProduct?.productName}
                                ></ProductsModalButton>
                            </div>
                            <ul>
                                {helpDocuments.map((element, index) =>
                                    <li key={index}>
                                        <a href="#" onClick={(e) => downloadDocument(element.DocumentId, e)} className={`btn btn-link p-0 m-0 ${loadingDocument == element.DocumentId ? "disabled" : ""}`}>{element.Name}</a>
                                        {loadingDocument == element.DocumentId && <span className="fa-solid fa-spinner fa-spin-pulse color-primary ms-2"></span>}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mb-4 mb-md-0">
                        <div className="card pd-card p-4">
                            <h2 className="type-20 type-bold">{getConfigValue(appState.config.HelpContact?.ContactTitle, appState)?.Value}</h2>
                            <p dangerouslySetInnerHTML={{ __html: getMessages() }}>
                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <ProductsModal
                show={showProductsModal}
                setShow={setShowProductsModal}
                onProductClick={onProductClick}
            />
            <ToastAlert show={showToast} setShow={setShowToast} title={toastTitle} message={toastMessage} />
        </>)
}