import React, { useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import { getConfigValue } from "../../util/getConfigValue";
import { AppContext } from '../../util/AppContext';
import { getSanitizedHtml } from "../../util/getSanitizedHtml";
import PropType from "prop-types";

RestrictionModal.propTypes = {
    show: PropType.bool,
    setShow: PropType.func,
    plan: PropType.shape({
        data: PropType.shape({
            name: PropType.string,
            benType: PropType.string,
            formularyRestriction: PropType.string,
        })
    })
};

function RestrictionModal({ show, setShow, plan }) {
    const [appState] = useContext(AppContext);
    const { name, benType, formularyRestriction } = plan.data;

    return (
        <Modal show={show} className="modal-lg" onHide={() => setShow(false)} scrollable={true}>
            <Modal.Header className="primary" closeButton>
                <Modal.Title className="modal-title fs-5">{getConfigValue(appState.config?.Restriction?.Modal.Title, appState)?.Value}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <h2 className="type-20 color-grey-d1 mt-2 mb-3 pb-2">{getConfigValue(appState.config?.Restriction?.Modal.Header, appState)?.Value} {name} - {benType}</h2>
                <div dangerouslySetInnerHTML={getSanitizedHtml(formularyRestriction)}></div>
            </Modal.Body>
        </Modal>
    );
}

export default RestrictionModal;


