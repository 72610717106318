import dayjs from 'dayjs'


export function formatSellSheetDate(sellSheet, dateFormat) {
    const dateRegex = new RegExp(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/);

    if (!sellSheet) {
        return sellSheet;
    }

    for (const property in sellSheet.columns) {
        if (dateRegex.test(sellSheet.columns[property])) {
            sellSheet.columns[property] = prettyDate(sellSheet.columns[property], dateFormat);
        }
    }
    return sellSheet;
}

export default function prettyDate(date, dateFormat) {
    const d = new Date(date);

    if (!dateFormat) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return d.toLocaleDateString("en-US", options);
    }

    return dayjs(d).format(dateFormat);
}

export function internationalDate(date) {
    const originalDate = new Date(date);

    let month = originalDate.getMonth() + 1;
    let day = originalDate.getDate();

    if (month < 10) {
        month = "0" + month;
    }

    if (day < 10) {
        day = "0" + day;
    }

    return new Date(`${originalDate.getFullYear()}-${month}-${day}`);
}