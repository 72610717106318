export function getConfigValue(item, appState) {
    try {
        const { currentProduct, category } = appState || {};
        const { clientProductId } = currentProduct || {};
        const { messageTypeId } = category || {};

        if (Array.isArray(item)) {
            // Find item based on the product ID and message type, product ID only, message type only, or no matching criteria
            const result = item.find(i => i.ClientProductId === clientProductId && i.MessageTypeId === messageTypeId)
                || item.find(i => !i.ClientProductId && i.MessageTypeId === messageTypeId)
                || item.find(i => i.ClientProductId === clientProductId && !i.MessageTypeId)
                || item.find(i => !i.ClientProductId && !i.MessageTypeId)
                || console.error("Unable to resolve config item") || {};

            return result;
        }

        // Return the item or an empty object if item doesn't exist
        return item || {};
    } catch (error) {
        // Log errors and return an empty object
        console.error("Error in getConfigValue: ", error);
        return {};
    }
}

export function getConfigCollection(item, appState, rootName) {
    try {
        const result = {};
        const itemValue = getConfigValue(item, appState);
        //check the Value property exists, if the value is null, it should still asign it.
        if (itemValue?.Value !== undefined) {
            result[itemValue.Name] = itemValue.Value;
        } else {
            // Iterate over properties of each object
            Object.keys(itemValue).forEach((key) => {
                // process each property of the item
                const subItemName = `${rootName}.${key}`;
                const subItem = itemValue[key];
                const subValue = getConfigCollection(subItem, appState, subItemName);
                // merge the subItem into the result
                mergeObject(result, subItemName, getItemValue(subValue, subItemName));
            });
            return result;
        }
        // Return the item or an empty object if item doesn't exist
        return result || {};
    } catch (error) {
        // Log errors and return empty object
        console.error("Error in getConfigCollection: " +  error + " rootName: " + rootName);
        return {};
    }

}

function getItemValue(valueContainer, name) {
    if(valueContainer[name]) {
        return valueContainer[name];
    }
    // Find the value in the nested object
    let currentLevel = valueContainer;
    name.split('.').forEach(namePart => {
        if(!currentLevel){
            return null;
        }
        currentLevel = currentLevel[namePart];
    });
    return currentLevel;
}

function mergeObject(result, name, value) {

    let currentLevel = result;
    let propertyName = '';
    let lastParent = result;  
    name.split('.').forEach(namePart => {
        if (!currentLevel.hasOwnProperty(namePart)) {
            currentLevel[namePart] = {};
        }
        lastParent = currentLevel;
        currentLevel = currentLevel[namePart];
        propertyName = namePart;
    })
    lastParent[propertyName] = value;
}