import React, { useState, useContext, useRef } from "react";
import { AppContext } from "../../util/AppContext";
import Tooltip from "../Tooltip";
import { getConfigValue } from "../../util/getConfigValue";
import { getSanitizedHtml } from "../../util/getSanitizedHtml";
import PropTypes from 'prop-types';
import DashboardItemRowHistoryItem from "../dashboard/DashboardItemRowHistoryItem";
import Dropdown from "react-bootstrap/Dropdown";

DashboardItemRow.propTypes = {
  sellSheet: PropTypes.any.isRequired,
  headers: PropTypes.any.isRequired,
  onAccessClick: PropTypes.any,
  canShowStatus: PropTypes.bool,
  professionalPrintingEnabled: PropTypes.bool,
  downLoadEnabled: PropTypes.bool,
  printEnabled: PropTypes.bool,
  onPrintClick: PropTypes.any,
  onDownloadClick: PropTypes.any,
  onCreateProfessionalPrintingDocumentClick: PropTypes.any
};

function DashboardItemRow({ sellSheet, headers, onAccessClick, canShowStatus, professionalPrintingEnabled, onPrintClick, onDownloadClick, onCreateProfessionalPrintingDocumentClick, downLoadEnabled, printEnabled }) {
  const [appState] = useContext(AppContext);
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipTarget = useRef(null);
  const [printStatusHistoryExpanded, setPrintStatusHistoryExpanded] = useState(false);
  let sellSheetRowStatusIndicator = 'pd-plan-table-indicator-' + (sellSheet.data.active ? 'success' : 'grey');

  let hasPrintHistory = false;

  if (professionalPrintingEnabled) {
    if (sellSheet.data.printJobHistory !== null && sellSheet.data.printJobHistory.length > 0) {
      hasPrintHistory = true;
    }
  }

  if (!canShowStatus) {
    sellSheetRowStatusIndicator = '';
  }

  function processColumnDefinitions(
    labelPrefix,
    labelSuffix,
    valuePrefix,
    valueSuffix
  ) {
    let columnRow = "";
    if (headers && sellSheet) {
      headers.forEach((header) => {
        let value = sellSheet.columns[header.key];
        let label = header.title;
        columnRow +=
          labelPrefix + label + labelSuffix + valuePrefix + value + valueSuffix;
      });
    }
    return columnRow;
  }

  function getItemStatus(history) {
    if (!history) {
      return 'Queued';
    }

    if (history.some(e => e.status === -1)) {
      return 'Error';
    }

    if (history.some(e => e.status === 2)) {
      return 'Completed';
    }

    return 'Queued';
  }

  const rowDefinition = processColumnDefinitions(
    '<div class="col-6 col-lg"><div class="color-grey-d1">',
    "</div>",
    "<div>",
    "</div></div>"
  );
  return (
    <div>
      <div className={`row pd-plan-table-row ${sellSheetRowStatusIndicator} pd-plan-table-card mb-4 mb-lg- 3 pt-3 pt-lg-0 pb-3 pb-lg-0 justify-content-lg-between align-items-lg-center`}>
        {canShowStatus &&
          <div className="col-12 col-lg-auto d-none d-lg-block">
            {sellSheet.data.active && (getItemStatus(sellSheet.data.printJobHistory) === 'Queued') && (
              <span className="fa-solid fa-check text-success"></span>
            )}
            {(sellSheet.data.active && getItemStatus(sellSheet.data.printJobHistory) === 'Error') && (
              <>
                <span
                  ref={tooltipTarget}
                  onMouseOver={() => setShowTooltip(true)}
                  onMouseOut={() => setShowTooltip(false)}
                  onFocus={() => setShowTooltip(true)}
                  onBlur={() => setShowTooltip(false)}
                  className="fa-regular fa-clock-rotate-left text-warning"
                ></span>

                <Tooltip
                  text={
                    getConfigValue(
                      appState.config.ProfessionalPrinting.History.Status.Error.Tooltip,
                      appState
                    )?.Value
                  }
                  ref={tooltipTarget}
                  showTooltip={showTooltip}
                  index={sellSheet.data.id}
                />
              </>
            )}
            {(sellSheet.data.active && getItemStatus(sellSheet.data.printJobHistory) === 'Completed') && (
              <span className="fa-solid fa-check text-success"></span>
            )}
            {!sellSheet.data.active && (
              <>
                <span
                  ref={tooltipTarget}
                  onMouseOver={() => setShowTooltip(true)}
                  onMouseOut={() => setShowTooltip(false)}
                  className="fa-regular fa-circle-exclamation color-grey-d1"
                ></span>

                <Tooltip
                  text={
                    getConfigValue(
                      appState.config.Dashboard.InactiveTooltip,
                      appState
                    )?.Value
                  }
                  ref={tooltipTarget}
                  showTooltip={showTooltip}
                  index={sellSheet.data.id}
                />
              </>
            )}
          </div>
        }


        <div className="col-12 col-lg">
          <div
            className="row my-lg-2"
            dangerouslySetInnerHTML={getSanitizedHtml(rowDefinition)}
          ></div>
        </div>
        <div className="col-12 col-lg-auto d-lg-flex justify-content-end">
          {sellSheet.data.active && (
            <>
              <div className="d-flex flex-column mt-3">
                <button
                  onClick={() =>
                    onAccessClick(
                      sellSheet.data.id,
                      sellSheet.data.documentGenerationRequest
                    )
                  }
                  className="btn btn-outline-primary btn-md btn-icon d-lg-none"
                  data-bs-toggle="modal"
                  data-bs-target="#previewSheet"
                >
                  {
                    getConfigValue(
                      appState.config.Dashboard.AccessButton,
                      appState
                    )?.Value
                  }
                  <span className="fa-regular fa-arrow-right btn-icon"></span>
                </button>
              </div>
              {(professionalPrintingEnabled && hasPrintHistory) &&
                <button
                  onClick={() => setPrintStatusHistoryExpanded(!printStatusHistoryExpanded)}
                  className="btn btn-ghost-secondary btn-sm btn-icon-only d-none d-lg-flex"
                  data-bs-toggle="modal"
                  data-bs-target="#previewSheet">
                  <span className="fa-regular fa-chevron-down btn-icon"></span>
                </button>
              }
              {onAccessClick &&
                <div className="btn btn-ghost-secondary btn-sm btn-icon-only d-none d-lg-flex">
                  <Dropdown>
                    <Dropdown.Toggle className="btn btn-ghost-secondary btn-sm btn-icon-only">
                      <span className="fa-regular fa-ellipsis btn-icon"></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {onAccessClick &&
                        <Dropdown.Item
                          onClick={() =>
                            onAccessClick(
                              sellSheet.data.id,
                              sellSheet.data.documentGenerationRequest
                            )
                          }
                          className="dropdown-item cursor">
                          {getConfigValue(
                            appState?.config?.Preview?.Preview,
                            appState
                          )?.Value || null}
                        </Dropdown.Item>
                      }
                      {(onPrintClick && printEnabled) &&
                        <Dropdown.Item
                          onClick={(e) =>
                            onPrintClick(
                              sellSheet.data.id,
                              sellSheet.data.documentGenerationRequest
                            )
                          }
                          className="dropdown-item cursor">
                          {getConfigValue(
                            appState?.config?.Preview?.Print,
                            appState
                          )?.Value || null}
                        </Dropdown.Item>
                      }
                      {(onDownloadClick && downLoadEnabled) &&
                        <Dropdown.Item
                          onClick={(e) =>
                            onDownloadClick(
                              sellSheet.data.id,
                              sellSheet.data.documentGenerationRequest
                            )
                          }
                          className="dropdown-item cursor">
                          {getConfigValue(
                            appState?.config?.Preview.Download,
                            appState
                          )?.Value || null}
                        </Dropdown.Item>
                      }
                      {(professionalPrintingEnabled && onCreateProfessionalPrintingDocumentClick) &&
                        <Dropdown.Item
                          onClick={(e) =>
                            onCreateProfessionalPrintingDocumentClick(
                              sellSheet.data.id,
                              sellSheet.data.documentGenerationRequest
                            )
                          }
                          className="dropdown-item cursor">
                          {getConfigValue(
                            appState?.config?.Preview?.SendToProfessionalPrinting,
                            appState
                          )?.Value || null}
                        </Dropdown.Item>}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              }
            </>
          )}
          {(!sellSheet.data.active && professionalPrintingEnabled && hasPrintHistory) && <button
            onClick={() => setPrintStatusHistoryExpanded(!printStatusHistoryExpanded)}
            className="btn btn-ghost-secondary btn-sm btn-icon-only d-none d-lg-flex"
            data-bs-toggle="modal"
            data-bs-target="#previewSheet">
            <span className="fa-regular fa-chevron-down btn-icon"></span>
          </button>}
        </div>

        {printStatusHistoryExpanded &&
          <div className="pd-plan-col pd-plan-datatable col-12 order-lg-last px-0">
            <div className="row align-items-lg-center bg-color-precision-grey mx-0 py-3 type-16 text-white">
              <div className="pd-plan-col col-lg-4 offset-lg-1">
                <div className="row align-items-lg-center">
                  <div className="col-12">
                    <span className="d-lg-none">History</span>
                    <span className="d-none d-lg-block">{getConfigValue(appState?.config?.ProfessionalPrinting?.History?.CreatedOn?.Label, appState)?.Value || null}</span>
                  </div>
                </div>
              </div>
              <div className="pd-plan-col col-lg-5 d-none d-lg-block">
                <div className="row align-items-lg-center">
                  <div className="col-lg">{getConfigValue(appState?.config?.ProfessionalPrinting?.History?.Status?.Label, appState)?.Value || null}</div>
                </div>
              </div>
              <div className="pd-plan-col pd-plan-button col-lg-1"></div>
              <div className="pd-plan-col col-lg-1"></div>
            </div>

            {sellSheet.data.printJobHistory !== null && sellSheet.data.printJobHistory.map((historyItem) => {
              return <DashboardItemRowHistoryItem key={historyItem.jobId} historyItem={historyItem} />;
            })}
          </div>

        }
      </div>
    </div>
  );
}
export default DashboardItemRow;
