export async function getConfig() {
    const url = `${process.env.REACT_APP_API_URL}Configuration`;
    const response = await fetch(url, {
        method: "GET",
        credentials: "include",
    });

    if (response.status === 200) {
        return await response.json();
    } else {
        throw response.statusText;
    }
}

export async function getFailedLoginPageConfig() {
    const url = `${process.env.REACT_APP_API_URL}FailedLoginPageConfiguration`;
    const response = await fetch(url, {
        method: "GET",
        credentials: "include",
    });

    if (response.status === 200) {
        return await response.json();
    } else {
        throw response.statusText;
    }
}

export async function getConfigProducts() {
    const url = `${process.env.REACT_APP_API_URL}Configuration/Products`;
    const response = await fetch(url, {
        method: "GET",
        credentials: "include",
    });

    if (response.status === 200) {
        return await response.json();
    } else {
        throw response.statusText;
    }
}

export async function getConfigMessageTypes(clientId) {
    if (!clientId) {
        throw new Error('No clientId provided.');
    }

    const url = `${process.env.REACT_APP_API_URL}Configuration/MessageTypes/${encodeURIComponent(clientId)}`;
    const response = await fetch(url, {
        method: "GET",
        credentials: "include",
    });

    if (response.status === 200 || response.status === 204) {
        return await response.json();
    } else {
        throw response.statusText;
    }
}
