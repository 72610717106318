import { FilterDropdownRow } from "../userDropdowns/FilterRowDropdown";
import { getConfigValue } from "../../util/getConfigValue";
import { useContext } from "react";
import { AppContext } from "../../util/AppContext";

function FilterDropdown({ filterState, setFilterState, selectedProducts, defaultFilterState }) {
    const [appState] = useContext(AppContext);

    function handleReset(e) {
        e.preventDefault();
        setFilterState(defaultFilterState)
    }

    return (
        <div
            style={{ display: "block" }}
            className="temp-user-dropdown-filters">
            <div className="row justify-content-center align-items-center">
                <div className="col-10 py-2 px-2">
                    <div className='d-flex justify-content-between align-items-center border-bottom mb-3'>
                        <h3 className="type-bold type-16 mt-2 mb-2">{getConfigValue(appState?.config?.QuickSheets?.Admin?.Filter?.Title, appState)?.Value || null}</h3>
                        <a onClick={(e) => handleReset(e)} href="#">{getConfigValue(appState?.config?.QuickSheets?.Admin?.Filter?.Reset, appState)?.Value || null}</a>
                    </div>
                    <h4 className="type-bold type-16 mt-2 mb-3">Product</h4>
                    {selectedProducts.map((product, index) => {
                        return (
                            <FilterDropdownRow key={index} checked={filterState[product.name]}
                                onChange={() => setFilterState({ ...filterState, [product.name]: !filterState[product.name] })}
                                label={product.name}
                            />
                        )
                    })}
                    <h4 className="type-bold type-16 mt-3 mb-3">{getConfigValue(appState?.config?.QuickSheets?.Admin?.Filter?.ByDate, appState)?.Value || null}</h4>
                    <FilterDropdownRow checked={filterState.current}
                        onChange={() => setFilterState({ ...filterState, current: !filterState.current })}
                        label={getConfigValue(appState?.config?.QuickSheets?.Admin?.Filter?.ByDate?.Current, appState)?.Value || null} />
                    <FilterDropdownRow checked={filterState.expired}
                        onChange={() => setFilterState({ ...filterState, expired: !filterState.expired })}
                        label={getConfigValue(appState?.config?.QuickSheets?.Admin?.Filter?.ByDate?.Expired, appState)?.Value || null} />
                    <h4 className="type-bold type-16 mt-3 mb-3">{getConfigValue(appState?.config?.QuickSheets?.Admin?.Filter?.ByHCP, appState)?.Value || null}</h4>
                    <FilterDropdownRow checked={filterState.hcpBased}
                        onChange={() => setFilterState({ ...filterState, hcpBased: !filterState.hcpBased })}
                        label={getConfigValue(appState?.config?.QuickSheets?.Admin?.Filter?.ByHCP.HCPBased, appState)?.Value || null} />
                    <FilterDropdownRow checked={filterState.noHcpAssociation}
                        onChange={() => setFilterState({ ...filterState, noHcpAssociation: !filterState.noHcpAssociation })}
                        label={getConfigValue(appState?.config?.QuickSheets?.Admin?.Filter?.ByHCP.NoHCPAssociation, appState)?.Value || null} />
                </div>
            </div>
        </div>
    )
}
export default FilterDropdown;