import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { getConfigValue } from '../../util/getConfigValue';
import { AppContext } from '../../util/AppContext';
import PropType from 'prop-types';

FormularyModal.propTypes = {
	show: PropType.bool,
	setShow: PropType.func,
	plan: PropType.shape({
		data: PropType.shape({
			name: PropType.string,
			benType: PropType.string,
			formularyDrillDown: PropType.arrayOf(
				PropType.shape({
					formulary: PropType.string,
					coverage: PropType.string,
					livesPercentage: PropType.number,
				})
			),
		}),
	}),
};

function FormularyModal({ show, setShow, plan }) {
	const [appState] = useContext(AppContext);
	const { name, benType, formularyDrillDown } = plan.data;

	if(!formularyDrillDown) {
		return null;
	}
	return (
		<Modal show={show} className='modal-lg' onHide={() => setShow(false)} scrollable={true}>
			<Modal.Header className='primary' closeButton>
				<Modal.Title className='modal-title fs-5'>{getConfigValue(appState.config?.Formulary?.Modal.Title, appState)?.Value}</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modal-body'>
				<h2 className='type-20 color-grey-d1 mt-2 mb-3 pb-2'>
					{getConfigValue(appState.config?.Formulary?.Modal.Header, appState)?.Value} {name} - {benType}
				</h2>
				<table className='table pd-table'>
					<thead>
						<tr className='table-dark'>
							<th scope='col'>{getConfigValue(appState?.config?.Formulary?.Header?.FormularyName, appState)?.Value || null}</th>
							<th scope='col'>{getConfigValue(appState?.config?.Formulary?.Header?.Status, appState)?.Value || null}</th>
							<th scope='col'>{getConfigValue(appState?.config?.Formulary?.Header?.LivesPercentage, appState)?.Value || null}</th>
						</tr>
					</thead>
					<tbody>
						{formularyDrillDown
							.sort((a, b) => (a.rank > b.rank ? 1 : -1))
							.map((formulary, index) => (
								<tr key={formulary.rank}>
									<td>
										<label className='form-check-label' htmlFor='selectHCP1'>
											{formulary.formulary}
										</label>
									</td>
									<td>
										<label className='form-check-label' htmlFor='selectHCP1'>
											{formulary.coverage}
										</label>
									</td>
									<td>{formulary.livesPercentage.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0 })}</td>
								</tr>
							))}
					</tbody>
				</table>
			</Modal.Body>
		</Modal>
	);
}

export default FormularyModal;
