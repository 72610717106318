import React, { useState, useEffect, useContext, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getConfigValue } from "../../util/getConfigValue";
import { AppContext } from "../../util/AppContext";

function AddTerritoryModal({ show, setShow, applicationData, addSelectedTerritories }) {
    const [appState] = useContext(AppContext);
    const [territoryList, setTerritoryList] = useState([]);
    const [filterTerritory, setFilterTerritory] = useState([]);
    const [marketList, setMarketList] = useState(JSON.parse(sessionStorage.getItem('marketsForUser')));
    const [searchInput, setSearchInput] = useState("");
    const [noResultsFound, setNoResultsFound] = useState(false);
    const [savedList, setSavedList] = useState([]);
    const defaultSelectionText = getConfigValue(appState.config?.TerritoryModal?.DefaultSelectionText, appState)?.Value || null;
    const [selectedMarket, setSelectedMarket] = useState(null);
    
    const isTerritorySelected = useCallback( (territory) => {
        // check if territory is a selected, independent of market
        const foundTerritory = savedList.find(a => a.id === territory.id);
        return foundTerritory !== undefined;
    }, [savedList]);

    useEffect(() => {
        if (marketList.length > 0) {
            const territories = [];
            marketList.forEach(market => {
                market.territories.forEach(territory => {
                    territories.push({ id: territory.id, name: territory.name, marketId: parseInt(market.id), prescribers: territory.prescribers });
                })
            });
            setTerritoryList(territories);
        }
    }, [marketList])

    useEffect(() => {
        setSavedList(applicationData);
    }, [applicationData]);

    useEffect(() => {
        if (territoryList.length > 0) {
            setFilterTerritory(territoryList);
        }
    }, [territoryList, savedList])

    useEffect(() => {
        if (searchInput.length > 0) {
            const results = filterByValue(territoryList, searchInput);
            setFilterTerritory(results)
            setNoResultsFound(results.length === 0);
        } else if (searchInput.length === 0) {
            const results = filterByMarket(territoryList);
            setFilterTerritory(results)
            setNoResultsFound(results.length === 0);
        }

    }, [searchInput, territoryList, selectedMarket, savedList]);

    function handleClose() {
        setSavedList(applicationData);
        setShow(false);
    };

    function handleMarketChange(event) {
        const value = event.target.value;

        if (value === defaultSelectionText) {
            setSelectedMarket(null);
        } else {
            const selectedMarket = marketList.find(f => f.name === value);
            setSelectedMarket(parseInt(selectedMarket.id));
        }
    };

    function handleChange(event) {
        setSearchInput(event.target.value.trim());
    };

    function filterByValue(array, searchString) {
        if (!Array.isArray(array)) {
            return [];
        }

        if (selectedMarket !== null) {
            return array.filter(element => element.name.toLowerCase().includes(searchString.toLowerCase()) && element.marketId === selectedMarket);
        }

        return array.filter(element => element.name.toLowerCase().includes(searchString.toLowerCase()));
    };

    function filterByMarket(array) {
        if (!Array.isArray(array)) {
            return [];
        }

        if (selectedMarket !== null) {
            return array.filter(element => element.marketId === selectedMarket);
        }

        return array;
    };
    
    function handleSelectedTerritory(territory, e) {
        const isChecked = e.target.checked;
        if (isChecked) {
            if (!savedList.find(t => t.id === territory.id)) {
                let affectedTerritorties = [...savedList];
                affectedTerritorties.push(territory);
                setSavedList(affectedTerritorties);
            }
        } else {
            let affectedTerritorties = [...savedList];
            affectedTerritorties = affectedTerritorties.filter(e => e.id !== territory.id);
            setSavedList(affectedTerritorties);
        }
    };

    function handleAdd() {
        addSelectedTerritories(savedList);
        setShow(false);
    }

    function isTerritoryDisabled(territory) {
        // check if territory is a DataLoader, independent of market
        const afffectedTerritory = savedList.find(t => t.id === territory.id);
        return afffectedTerritory?.type === "DataLoader" || false;
    }

    function handleCheckUncheckAll(){
        const allTerritories = [...territoryList];
        const selectedTerritories = [...savedList];
        const selectedTerritoriesIds = selectedTerritories.map(t => t.id);
        const unselectedTerritories = allTerritories.filter(t => !selectedTerritoriesIds.includes(t.id));
        const affectedTerritories = [...selectedTerritories];
        if(unselectedTerritories.length > 0){
            affectedTerritories.push(...unselectedTerritories);
            setSavedList(affectedTerritories);
        }else{
            setSavedList(selectedTerritories.filter(t => t.type === "DataLoader"));
        }        
    }

    return (
        <Modal show={show} onHide={handleClose} scrollable={true} >
            <Modal.Header>
                <Modal.Title className="modal-title type-20 fs-5">{getConfigValue(appState?.config?.Territory?.AddAssignment, appState)?.Value || null}</Modal.Title>
            </Modal.Header>
            <div className="modal-subheader px-3 pt-2 pb-1 border-bottom">
                {marketList.length > 1 && (
                    <>
                        <label htmlFor="territories-market-select" className="form-label">{getConfigValue(appState.config?.TerritoryModal?.SelectMarket?.Label, appState)?.Value || null}</label>
                        <select onChange={handleMarketChange} className="form-select mb-3" aria-label="Select a market">
                            <option defaultValue disabled="">{defaultSelectionText}</option>
                            {marketList.map((market) => (
                                <option key={market.id}>{market.name}</option>
                            ))}
                        </select>
                    </>
                )}                
                <label
                    htmlFor="territories-search"
                    className="form-label">
                    {getConfigValue(appState?.config?.Territory?.Search, appState)?.Value || null}
                </label>
                <input
                    type="text"
                    className="form-control icon-search"
                    id="territories-search"
                    aria-label="Search territories"
                    onChange={handleChange}
                    value={searchInput}>
                </input>
                <button className="btn btn-outline-primary btn-sm btn-icon my-3" onClick={handleCheckUncheckAll} >{getConfigValue(appState?.config?.Territory?.CheckUncheckAll, appState)?.Value || null}<span className="fa-regular fa-check btn-icon"></span></button>
            </div>
            <Modal.Body className="modal-body" >

                <ul className="list-group list-group-flush">
                    {filterTerritory.length > 0 && filterTerritory.map((territory) => {
                        return (
                            <li className="list-group-item px-0 py-3 d-flex align-items-center" key={territory.marketId + territory.id}>
                                <div className="form-check">
                                    <input
                                        checked={isTerritorySelected(territory)}
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`add-territory${territory.marketId + territory.id}`}
                                        onChange={(e) => handleSelectedTerritory(territory, e)}
                                        disabled={isTerritoryDisabled(territory)}
                                    ></input>
                                    <label className="form-check-label ms-2" htmlFor={`add-territory${territory.marketId + territory.id}`}>{territory.name}</label>
                                </div>
                            </li>
                        )
                    })}

                </ul>

            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <div className="container-fluid mx-0">
                    <div className="row justify-content-md-end px-0">
                        <div className="col-12 col-md-10 px-0">
                            <div className="d-flex w-100 flex-column flex-md-row-reverse">
                                <Button
                                    className="btn btn-primary btn-lg mb-3 mb-md-0 ms-md-3 flex-fill w-100"
                                    onClick={handleAdd}>
                                    Add
                                </Button>
                                <Button
                                    className="btn btn-secondary btn-lg flex-fill w-100"
                                    data-bs-dismiss="modal"
                                    onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
export default AddTerritoryModal;