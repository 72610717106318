import { useState, useContext, useEffect } from "react";
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddTerritoryModal from '../components/userComponents/AddTerrtitoryModal';
import SelectPlansModal from "../components/quicksheets-admin/SelectPlansModal";
import { getStaticTemplate, documentType } from "../API/documentGenerate";
import ToastAlert from "../components/ToastAlert";
import { getConfigValue } from "../util/getConfigValue";
import { AppContext } from "../util/AppContext";
import { ProductsModal, ProductsModalButton } from "../components/ProductsModal";
import { upsertQuickSheet } from "../API/quickSheets";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
function QuickSheetsAdminEdit(props) {
    const { state } = useLocation();
    const [appState, setAppState] = useContext(AppContext);

    const [showTerritoryModal, setShowTerritoryModal] = useState(false);
    const [showProductsModal, setShowProductsModal] = useState(false);
    const [currentProduct, setCurrentProduct] = useState();
    const [productImageSource, setProductImageSource] = useState();
    const [isAddMode] = useState(state?.isAddMode);
    const [showPlansModal, setShowPlansModal] = useState(false);
    const [documentId, setDocumentId] = useState(state?.quickSheet?.documentId || '');
    const [documentName, setDocumentName] = useState(state?.quickSheet?.documentName || '');
    const [showToast, setShowToast] = useState(false);
    const [toastTitle, setToastTitle] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [selectedTerritories, setSelectedTerritories] = useState(setTerritories());
    const [selectedPlans, setSelectedPlans] = useState(state?.quickSheet?.plans || []);

    const [startDate, setStartDate] = useState(state?.isAddMode ? null : dayjs(state?.quickSheet?.fromDate || null));
    const [expirationDate, setExpirationDate] = useState(state?.isAddMode ? null : dayjs(state?.quickSheet?.expirationDate || null));
    const [minStartDate] = useState(dayjs(new Date().toISOString().slice(0, 10)));
    const [minEndDate, setMinEndDate] = useState(dayjs(new Date().toISOString().slice(0, 10)));

    const navigate = useNavigate();
    const { handleSubmit, formState: { errors } } = useForm(); // form validation
    const isValid = documentId && documentName && currentProduct && startDate && expirationDate;

    let pageTitle = null;
    let saveButton = null;

    if (state?.isAddMode === true) {
        pageTitle = getConfigValue(appState?.config?.QuickSheets?.Admin?.Add?.Title, appState)?.Value || null;
        saveButton = getConfigValue(appState?.config?.QuickSheets?.Admin?.Save?.Button?.Add, appState)?.Value || null;
    }
    else {
        pageTitle = getConfigValue(appState?.config?.QuickSheets?.Admin?.Edit?.Title, appState)?.Value || null;
        saveButton = getConfigValue(appState?.config?.QuickSheets?.Admin?.Save?.Button?.Update, appState)?.Value || null;
    }

    useEffect(() => {
        if (appState?.currentProduct === "" && !state?.quickSheet?.productId) {
            const firstProduct = appState?.productDetails[0];
            setCurrentProduct(firstProduct);
            setProductImageSource(appState?.productDetails[0].imagePath);
            setAppState({ ...appState, currentProduct: firstProduct });
        } else {
            if (state?.quickSheet?.productId) {
                const foundProduct = appState?.productDetails.find((e) => { return e.clientProductId === state.quickSheet.productId });
                setCurrentProduct(foundProduct);
                setProductImageSource(foundProduct.imagePath);
                setAppState({ ...appState, currentProduct: foundProduct });
            }
            else {
                setCurrentProduct(appState?.currentProduct);
                setProductImageSource(appState?.currentProduct?.imagePath);
            }
        }
    }, []);

    async function handlePostSheet() {
        await postQuickSheet();
    }

    async function postQuickSheet() {
        let data = {
            documentId,
            documentName,
            fromDate: formatDate(startDate.$d),
            expirationDate: formatDate(expirationDate.$d),
            territories: buildTerritories(),
            plans: buildPlans(),
            productId: currentProduct.clientProductId
        };

        const response = await upsertQuickSheet(isAddMode, data);

        if (response.status === 200) {
            navigate('/quickSheetsAdminPage', { state: { alert: { type: 'success', heading: "Success", message: getConfigValue(appState?.config?.QuickSheets?.Admin?.Message?.Success, appState)?.Value || null } } });
        } else if (response.status === 403) {
            triggerToastAlert("Error", getConfigValue(appState?.config?.QuickSheets?.Admin?.Message?.Permissions, appState)?.Value || null);
        } else if (response.status === 409) {
            triggerToastAlert("Error", getConfigValue(appState?.config?.QuickSheets?.Admin?.Message?.Duplicate, appState)?.Value || null);
        } else {
            triggerToastAlert("Error", await response.json());
        }
    }

    function buildTerritories() {
        const territories = [];
        selectedTerritories.forEach(item => {
            territories.push({
                "id": item.id,
                "name": item.name
            });
        });

        return territories;
    }

    function setTerritories() {
        const territories = [];
        state?.quickSheet?.territories.forEach(item => {
            territories.push({
                "id": item.id,
                "name": item.name
            });
        });

        return territories;
    }

    function buildPlans() {
        const plans = [];
        selectedPlans.forEach(item => {
            plans.push({
                "planId": item.planId,
                "name": item.name
            });
        });

        return plans;
    }

    function triggerToastAlert(title, message) {
        setToastTitle(title);
        setToastMessage(message);
        setShowToast(true);
    }

    async function lookupDocument(event) {
        event.preventDefault();
        setDocumentName('');

        try {
            const response = await getStaticTemplate(documentId);

            if (response.document_type !== documentType.Static) {
                throw new Error("Document is not a static document");
            }

            setDocumentName(response.filename);
        } catch (ex) {
            console.error(ex);
            if (ex.toString().toLowerCase() === "method not allowed") {
                triggerToastAlert("Error", (getConfigValue(appState?.config?.QuickSheets?.Admin?.InvalidDocumentFormat, appState)?.Value || null));
            }
            else {
                triggerToastAlert("Error", (getConfigValue(appState?.config?.QuickSheets?.Admin?.InvalidDocumentType, appState)?.Value || null));
            }
        }
    }

    function handleAddTerritoryClick() {
        setShowTerritoryModal(true);
    }

    function removeTerritoryClick(territory) {
        setSelectedTerritories(oldTerritoryList => [...oldTerritoryList.filter(oldTerritory => oldTerritory !== territory)]);
    }

    function removePlanClick(plan) {
        setSelectedPlans(oldPlansList => [...oldPlansList.filter(oldPlan => oldPlan !== plan)]);
    }

    function addSelectedTerritories(selectedTerrtitores) {
        setSelectedTerritories(selectedTerrtitores);
    }

    function addSelectedPlans(selectedPlans) {
        setSelectedPlans(selectedPlans);
    }

    function onProductClick(product) {
        setAppState({ ...appState, currentProduct: product });
        setCurrentProduct(product);
        setShowProductsModal(false);
        setProductImageSource(product.imagePath)
    }

    function onStartDate(event) {
        setStartDate(event);

        if (expirationDate === null) {
            setMinEndDate(event);
        }
    }

    function formatDate(value) {
        let day = value.getDate().toString();
        let month = (value.getMonth() + 1).toString();
        let year = value.getFullYear();

        if (month.length < 2) {
            month = "0" + month;
        }

        if (day.length < 2) {
            day = "0" + day;
        }

        return [year, month, day].join('-');
    }

    return (
        <div>
            <nav aria-label="breadcrumb" className="breadcrumb-wrapper">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-12">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/"><span className="fas fa-house"></span><span className="sr-only">Home</span></Link></li>
                                <li className="breadcrumb-item"><Link to="/">Settings</Link></li>
                                <li className="breadcrumb-item"><Link to="/QuickSheetsAdminPage">Quick sheets</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{pageTitle}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="container-xxl mt-4">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        <h1 className="type-24 my-0">{pageTitle}</h1>
                    </div>
                </div>
            </div>
            <div className="container-xxl mt-4">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">

                        <div className="card pd-card py-3 mb-5">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <h2 className="type-20 type-bold mb-3">{getConfigValue(appState?.config?.QuickSheets?.Admin?.DocumentInfo, appState)?.Value || null}</h2>
                                        <label htmlFor="doc-id" className="form-label">{getConfigValue(appState?.config?.QuickSheets?.Admin?.DocumentId, appState)?.Value || null}</label>
                                        {
                                            documentName.length > 0 && (<span className="fa-regular fa-check text-success ms-2"></span>)
                                        }

                                        <div className="input-group">
                                            <input
                                                defaultValue={documentId}
                                                disabled={isAddMode ? false : true}
                                                onChange={e => setDocumentId(e.target.value)}
                                                type="text"
                                                className={`form-control ${errors.documentId ? "is-invalid" : ""}`}
                                                id="documentId"
                                                aria-label="document id" />
                                            <button className="btn btn-primary btn-icon-only" type="button" id="button-addon2" onClick={(e) => lookupDocument(e)} disabled={!isAddMode}>
                                                <span className="fa-solid fa-magnifying-glass"></span><span className="visually-hidden">{getConfigValue(appState?.config?.QuickSheets?.Admin?.SearchDocument, appState)?.Value || null}</span>
                                            </button>
                                        </div>
                                        <div className="type-12 color-grey-d1 mt-1">{getConfigValue(appState?.config?.QuickSheets?.Admin?.SearchDocument?.HelpText, appState)?.Value || null}</div>
                                        <div className="my-3"></div>

                                        {/* DEV NOTES
                                            + This is never entered directly by the user. It's populated by an API connection to Widen
                                            based on the Doc ID entered above. */}

                                        <label htmlFor="doc-name" className="form-label">{getConfigValue(appState?.config?.QuickSheets?.Admin?.DocumentName, appState)?.Value || null}</label>

                                        {/* DEV NOTES
                                            + Show the icon below once the API call to Widen is complete and valid.
                                            + Do not show either icon upon page load in the default state. */}
                                        {
                                            documentName.length > 0 && (<span className="fa-regular fa-check text-success ms-2"></span>)
                                        }

                                        <input type="text" className="form-control" id="doc-name" defaultValue={documentName} disabled />
                                        <div className="type-12 color-grey-d1 mt-1">{getConfigValue(appState?.config?.QuickSheets?.Admin?.DocumentName?.HelpText, appState)?.Value || null}</div>
                                        <div className="my-3"></div>
                                        <label htmlFor="doc-product" className="form-label">{getConfigValue(appState?.config?.QuickSheets?.Admin?.Product, appState)?.Value || null} *</label>
                                        <div className="col-12 col-md-4 col-lg-3">
                                            {<ProductsModalButton
                                                setShowProductsModal={setShowProductsModal}
                                                productImageSource={productImageSource}
                                                productName={currentProduct?.name}
                                            ></ProductsModalButton>}
                                        </div>
                                        <div className="my-6"></div>
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <h2 className="type-20 type-bold mb-3">{getConfigValue(appState?.config?.QuickSheets?.Admin?.Territories, appState)?.Value || null}</h2>

                                                {/* DEV NOTES
                                                        + Use the following commented out line for new documents or when no territories are assigned. */}

                                                {selectedTerritories.length === 0 && <p className="color-grey-d1">{getConfigValue(appState?.config?.QuickSheets?.Admin?.Territories?.NoneSelected, appState)?.Value || null}</p>}
                                                <ul className="list-group list-group-flush">
                                                    {selectedTerritories.length > 0 && selectedTerritories.map((territory, index) => {
                                                        return (
                                                            <li className="list-group-item px-0 d-flex align-items-center justify-content-between" key={index}>
                                                                <span className="territory-name">{territory.name}</span>
                                                                <button type='button' className="btn btn-ghost btn-md btn-icon-only"
                                                                    onClick={(e) => removeTerritoryClick(territory, e)}
                                                                ><span className="fa-regular fa-circle-minus btn-icon"></span></button>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>

                                                <button
                                                    type="button"
                                                    className="btn btn-outline-primary btn-sm btn-icon mt-3 mt-md-4"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#addTerritories"
                                                    onClick={handleAddTerritoryClick}>
                                                    {getConfigValue(appState?.config?.QuickSheets?.Admin?.Territories?.SelectTerritory, appState)?.Value || null}
                                                    <span className="fa-regular fa-plus btn-icon"></span>
                                                </button>

                                                <div className="my-5"></div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <h2 className="type-20 type-bold mb-3">{getConfigValue(appState?.config?.QuickSheets?.Admin?.Plans, appState)?.Value || null}</h2>

                                                {/* DEV NOTES
                                                        + Use the following commented out line for new documents or when no plans are assigned. */}

                                                {selectedPlans.length === 0 && <p className="color-grey-d1">{getConfigValue(appState?.config?.QuickSheets?.Admin?.Plans?.NoneSelected, appState)?.Value || null}</p>}

                                                <ul className="list-group list-group-flush">
                                                    {selectedPlans.length > 0 && selectedPlans.map((plan, index) => {
                                                        return (
                                                            <li className="list-group-item px-0 d-flex align-items-center justify-content-between" key={index}>
                                                                <span className="territory-name">{plan.name}</span>
                                                                <button type='button' className="btn btn-ghost btn-md btn-icon-only"
                                                                    onClick={(e) => removePlanClick(plan, e)}
                                                                ><span className="fa-regular fa-circle-minus btn-icon"></span></button>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                                <button
                                                    onClick={() => setShowPlansModal(true)}
                                                    className="btn btn-outline-primary btn-sm btn-icon mt-3 mt-md-4"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#selectPlans">{getConfigValue(appState?.config?.QuickSheets?.Admin?.Plans?.SelectPlans, appState)?.Value || null}
                                                    <span className="fa-regular fa-plus btn-icon"></span>
                                                </button>

                                                <div className="type-12 color-grey-d1 mt-3">{getConfigValue(appState?.config?.QuickSheets?.Admin?.Plans?.HelpText, appState)?.Value || null}</div>
                                                <div className="my-3"></div>
                                            </div>
                                        </div>
                                        <div className="my-6 my-lg-3"></div>
                                        <h2 className="type-20 type-bold mb-3">{getConfigValue(appState?.config?.QuickSheets?.Admin?.ValidityDates, appState)?.Value || null}</h2>

                                        <label htmlFor="doc-date_begin" className="form-label">{getConfigValue(appState?.config?.QuickSheets?.Admin?.ValidityDates?.BeginningDate, appState)?.Value || null} *</label>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                            <DatePicker
                                                className="form-control qs-start-date"
                                                name="doc-date_begin"
                                                value={startDate}
                                                onChange={(e) => { onStartDate(e); }}
                                                minDate={minStartDate}
                                                maxDate={expirationDate} />
                                        </LocalizationProvider>

                                        <div className="type-12 color-grey-d1 mt-1">{getConfigValue(appState?.config?.QuickSheets?.Admin?.ValidityDates?.BeginningDate?.HelpText, appState)?.Value || null}</div>
                                        <div className="my-3"></div>
                                        <label htmlFor="doc-date_expiry" className="form-label">{getConfigValue(appState?.config?.QuickSheets?.Admin?.ValidityDates?.ExpirationDate, appState)?.Value || null} *</label>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                            <DatePicker
                                                className="form-control"                                                
                                                name="doc-date_expiry"
                                                value={expirationDate}
                                                onChange={(e) => { setExpirationDate(e); }}
                                                minDate={startDate} />
                                        </LocalizationProvider>
                                        <div className="type-12 color-grey-d1 mt-1">{getConfigValue(appState?.config?.QuickSheets?.Admin?.ValidityDates?.ExpirationDate?.HelpText, appState)?.Value || null}</div>
                                        <div className="my-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center justify-content-md-end">
                            <div className="col-12 col-sm-8 col-md-8 col-lg-7">
                                <div className="d-flex flex-column flex-md-row-reverse">
                                    <button className="btn btn-success btn-lg mb-3 mb-md-0 ms-md-3 flex-fill w-100" disabled={!isValid} onClick={handleSubmit(handlePostSheet)}>
                                        {saveButton}
                                    </button>
                                    <Link to="/quickSheetsAdminPage" className="btn btn-secondary btn-lg flex-fill w-100">Cancel</Link>
                                </div>
                            </div>
                        </div>
                        <div className="mb-7"></div>

                    </div>
                </div>
                <AddTerritoryModal
                    show={showTerritoryModal}
                    setShow={setShowTerritoryModal}
                    addSelectedTerritories={addSelectedTerritories}
                    applicationData={selectedTerritories} />
                <ProductsModal
                    show={showProductsModal}
                    setShow={setShowProductsModal}
                    onProductClick={onProductClick}
                />
                <SelectPlansModal
                    show={showPlansModal}
                    setShow={setShowPlansModal}
                    addSelectedPlans={addSelectedPlans}
                    applicationData={selectedPlans} />
            </div>

            <ToastAlert show={showToast} setShow={setShowToast} title={toastTitle} message={toastMessage} />
        </div>
    );
}

export default QuickSheetsAdminEdit;