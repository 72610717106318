import React, { useContext } from "react";
import formatPhoneNumber from "../util/formatPhoneNumber";
import { AppContext } from "../util/AppContext";
import { getConfigValue } from "../util/getConfigValue";
import PropTypes from 'prop-types';

CreateDocumentAddressRow.propTypes = {
    address: PropTypes.any.isRequired,
    userId: PropTypes.string.isRequired,
    setAlert: PropTypes.any,
    loadAddresses: PropTypes.any,
    onSelectAddress: PropTypes.any,
    isChecked: PropTypes.bool.isRequired,
};

function CreateDocumentAddressRow({ address, userId, setAlert, loadAddresses, onSelectAddress, isChecked }) {
    const [appState] = useContext(AppContext);
    const {
        nickName,
        firstName,
        lastName,
        address1,
        address2,
        city,
        state,
        zip,
        country,
        phoneNumber,
        isPrimary,
    } = address;

    return (
        <li className="list-group-item py-3 py-md-5">
            <div className="row">
                <div className="col-5">
                    <div className="d-flex align-items-center">
                        <input className="form-check-input flex-shrink-0" type="radio" name="select-address"
                            onChange={(event) => onSelectAddress(address)}
                            checked={isChecked} />
                        <label className="form-check-label type-20 color-grey-d1 ms-3" htmlFor="address-home">{nickName}</label>
                    </div>
                </div>
                <div className="col-10 col-md">
                    <p className="my-0">
                        <span className="type-bold d-block">
                            {firstName} {lastName}
                        </span>
                        <span className="d-block">{address1}</span>
                        {address2 && <span className="d-block">{address2}</span>}
                        <span className="d-block">
                            {city} {state} {zip}
                        </span>
                        <span className="d-block">{country}</span>
                        <span className="d-block">
                            {formatPhoneNumber(phoneNumber)}
                        </span>
                        {isPrimary && (
                            <div className="d-inline ms-4 d-md-block ms-md-0">
                                <span className="far fa-check color-success me-2"></span>
                                <span className="color-grey-d1 type-14">
                                    {getConfigValue(
                                        appState?.config?.AddressManagement?.Row?.PrimaryAddress,
                                        appState
                                    )?.Value || null}
                                </span>
                            </div>
                        )}
                    </p>
                </div>
            </div>
        </li>
    );
}

export default CreateDocumentAddressRow;
