export async function getSystemDates() {
    const url = `${process.env.REACT_APP_API_URL}Static/SystemDates`;
    const response = await fetch(url, {
        method: "GET",
        credentials: "include",
    });

    if (response.status === 200) {
        return await response.json();
    } else {
        throw response.statusText;
    }
};