import { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getPlansForUser } from '../../API/quickSheets';
import { filterByValue } from "../../util/filterByValue";
import { getConfigValue } from "../../util/getConfigValue";
import { AppContext } from "../../util/AppContext";
import { useErrorBoundary } from "react-error-boundary";

function SelectPlansModal({ show, setShow, applicationData, addSelectedPlans }) {
    const [appState] = useContext(AppContext);
    const [plans, setPlans] = useState([]);
    const [filteredPlans, setFilteredPlans] = useState([]);
    const [channels, setChannels] = useState([]);
    const [savedList, setSavedList] = useState([]);
    const defaultSelectionText = getConfigValue(appState?.config?.SelectPlans?.SearchChannel.DefaultSelectionText, appState)?.Value || null;
    const [searchInput, setSearchInput] = useState("");
    const [noResultsFound, setNoResultsFound] = useState(false);
    const [sortedField, setSortedField] = useState('name');
    const [isAscending, setIsAscending] = useState(true);
    const [selectedChannel, setSelectedChannel] = useState(false);
    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        loadUserPlans();
    }, []);

    async function loadUserPlans() {
        let userPlans = sessionStorage.getItem('userPlans');
        if (!userPlans) {
            await getPlansForUser(sessionStorage.getItem('userClaimId'))
                .then((data) => {
                    const plans = JSON.stringify(data);
                    sessionStorage.setItem('userPlans', plans);
                    userPlans = plans;
                })
                .catch((error) => {
                    showBoundary(error);
                });
        }

        if (userPlans.length > 0) {
            const plans = JSON.parse(userPlans);
            setPlans(plans);
            setFilteredPlans(plans);
            setChannels([...new Set(plans.map(item => item.channel))]);
        }
    }

    useEffect(() => {
        getFilteredPlans(selectedChannel, searchInput);
    }, [searchInput, plans, selectedChannel]);

    useEffect(() => {
        const plansCopy = [...filteredPlans];

        // disable sorting if field does not exist or is empty
        if (!doesFieldExist(plansCopy, sortedField) || isFieldEmpty(plansCopy, sortedField)) {
            return;
        }

        setFilteredPlans(plansCopy.sort((a, b) => {
            // sort by name if fields match
            if (isAscending) {
                if (a[sortedField] !== b[sortedField]) {
                    return a[sortedField].toLocaleLowerCase() > b[sortedField].toLocaleLowerCase() ? 1 : -1;
                }

            } else {
                if (a[sortedField] !== b[sortedField]) {
                    return a[sortedField].toLocaleLowerCase() < b[sortedField].toLocaleLowerCase() ? 1 : -1;
                }
            }
        }));
    }, [isAscending, sortedField]);

    function handleSelectedPlans(plan, e) {
        const isChecked = e.target.checked;
        if (isChecked) {
            if (!applicationData.find(t => t.planId === plan.planId)) {
                setSavedList(state => [...state, plan]);
            }
        } else {
            setSavedList(state => state.filter((e) => e !== plan))
        }
    };

    function handleSearch(event) {
        setSearchInput(event.target.value);
    };

    function handlePlanChange(event) {
        const value = event.target.value;

        if (value === defaultSelectionText) {
            setSelectedChannel(null);
        } else {
            setSelectedChannel(value);
        }
    }

    function getFilteredPlans(channel, search) {
        let results = [];

        if (channel && channel.length > 1) {
            results = plans.filter((plan) => plan.channel === channel);
        }

        if (search.length > 0) {
            results = filterByValue(results.length === 0 ? plans : results, search.trim(), [], true);
        }

        if (!channel && !search) {
            setFilteredPlans(plans);
        }
        else {
            setFilteredPlans(results);
            setNoResultsFound(results.length === 0);
        }
    }

    function handleClose() {
        setShow(false);
        setSavedList([]);
    };

    function handleAdd() {
        addSelectedPlans((oldData) => ([...oldData, ...savedList]))
        setShow(false);
        setSavedList([]);
    }

    function handleSortClick(fieldName) {
        setIsAscending(prev => !prev);
        setSortedField(fieldName);
    }

    function doesFieldExist(list, fieldName) {
        return list.some(provider => provider.hasOwnProperty(fieldName));
    }

    function isFieldEmpty(list, fieldName) {
        return list.every(provider => provider[fieldName] === null);
    }

    function getSortClass(fieldName) {
        if (fieldName !== sortedField) {
            return 'fa-light fa-sort';
        }

        return isAscending ? ' fa-solid fa-arrow-down-short-wide' : 'fa-solid fa-arrow-down-wide-short';
    }

    return (
        <Modal show={show} onHide={handleClose} size={"lg"} scrollable={true}>
            <Modal.Header>
                <Modal.Title className="modal-title fs-5">{getConfigValue(appState?.config?.SelectPlans?.Title, appState)?.Value || null}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body" >
                <div className="row d-flex align-items-center">
                    <div className="col-12 col-md-6 mb-3 mb-md-0">
                        <label htmlFor="plan-select-search" className="form-label">{getConfigValue(appState?.config?.SelectPlans?.Search, appState)?.Value || null}</label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control icon-search"
                                id="plan-select-search"
                                placeholder="Search"
                                onChange={handleSearch}
                                value={searchInput}>
                            </input>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        {channels?.length > 1 && (
                            <>
                                <label htmlFor="territories-market-select" className="form-label">{getConfigValue(appState?.config?.SelectPlans?.SearchChannel, appState)?.Value || null}</label>
                                <select onChange={handlePlanChange} className="form-select" aria-label="Select a channel">
                                    <option defaultValue disabled="">{defaultSelectionText}</option>
                                    {channels.map((channel, index) => (
                                        <option key={index}>{channel}</option>
                                    ))}
                                </select>
                            </>
                        )}


                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <table className="table pd-table mb-0">
                            <thead>
                                <tr className="table-dark">
                                    <th scope="col"></th>
                                    <th scope="col">{getConfigValue(appState?.config?.SelectPlans?.PlanTable?.PlanName, appState)?.Value || null}
                                        <button className="btn-ghost btn-table active" onClick={() => handleSortClick('name')}>
                                            <div className={getSortClass('name')}></div>
                                        </button>
                                    </th>
                                    <th scope="col">{getConfigValue(appState?.config?.SelectPlans?.PlanTable?.Channel, appState)?.Value || null}
                                        <button className="btn-ghost btn-table" onClick={() => handleSortClick('channel')}>
                                            <div className={getSortClass('channel')}></div>
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredPlans?.map((plan, index) => {
                                    return (
                                        <tr key={index}>
                                            <th scope="row">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="select-Plan"
                                                    id={`add-plan-${index}`}
                                                    onChange={(e) => handleSelectedPlans(plan, e)}
                                                    defaultChecked={applicationData?.find(applicationData => applicationData.planId === plan.planId)} />
                                            </th>
                                            <td>{plan.name}</td>
                                            <td>{plan.channel}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <div className="container-fluid mx-0">
                    <div className="row justify-content-md-end px-0">
                        <div className="col-12 col-md-10 px-0">
                            <div className="d-flex w-100 flex-column flex-md-row-reverse">
                                <Button
                                    className="btn btn-success btn-lg mb-3 mb-md-0 ms-md-3 flex-fill w-100"
                                    onClick={handleAdd}>
                                    Add
                                </Button>
                                <Button
                                    className="btn btn-secondary btn-lg flex-fill w-100"
                                    data-bs-dismiss="modal"
                                    onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default SelectPlansModal;