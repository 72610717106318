export async function getAddresses(userId) {
    if (!userId) {
        throw new Error('No userId provided.');
    }

    const url = `${process.env.REACT_APP_API_URL}Addresses?userId=${encodeURIComponent(userId)}`;
    const response = await fetch(url, {
        method: "GET",
        credentials: "include",
    });

    if (response.status === 200) {
        return await response.json();
    } else {
        throw response.statusText;
    }
}

export async function updateAddress(userId, address) {
    if (!userId || !address) {
        throw new Error('No userId or address provided.');
    }

    const url = `${process.env.REACT_APP_API_URL}Address?userId=${encodeURIComponent(userId)}`;
    const parameters = {
        method: "PUT",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            ...address,
            dataSource: "Application",
        })
    }
    const response = await fetch(url, parameters);

    if (response.status === 200 || response.status === 409 || response.status === 422) {
        return await response.json();
    } else {
        throw response.statusText;
    }
}

export async function addAddress(userId, address) {
    if (!userId) {
        throw new Error('No userId provided.');
    }

    const url = `${process.env.REACT_APP_API_URL}Address?userId=${encodeURIComponent(userId)}`;
    const parameters = {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            ...address,
            dataSource: "Application",
        })
    }
    const response = await fetch(url, parameters);

    if (response.status === 200) {
        return await response.json();
    } else {
        throw response.statusText;
    }
}

export async function deleteAddress(userId, addressId) {
    if (!userId || !addressId) {
        throw new Error('No userId or addressId provided.');
    }

    const url = `${process.env.REACT_APP_API_URL}Address?userId=${encodeURIComponent(userId)}&id=${encodeURIComponent(addressId)}`;
    const response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
    });

    if (response.status === 200) {
        return await response.json();
    } else {
        throw response.statusText;
    }
}

export async function setAsPrimaryAddress(userId, addressId) {
    if (!userId || !addressId) {
        throw new Error('No userId or address id provided.');
    }

    const url = `${process.env.REACT_APP_API_URL}Address/SetPrimary?userId=${encodeURIComponent(userId)}&addressId=${encodeURIComponent(addressId)}`;
    const parameters = {
        method: "PUT",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        }        
    }
    const response = await fetch(url, parameters);

    if (response.status === 200 || response.status === 409 || response.status === 422) {
        return await response.json();
    } else {
        throw response.statusText;
    }
}

export async function getStates() {
    const url = `${process.env.REACT_APP_API_URL}Settings/StatesCountries`;
    const response = await fetch(url, {
        method: "GET",
        credentials: "include",
    });

    if (response.status === 200) {
        return await response.json();
    } else {
        throw response.statusText;
    }
};