import React from 'react';

export function FilterDropdownRow({checked, onChange, label}) {
    return (
        
        // <div className='d-flex justify-content-between align-items-center mb-2'>
            <div className='form-check mb-3 mb-lg-2'>
                <label className='form-check-label d-flex align-items-center type-16'>
                <input
                    className='form-check-input flex-shrink-0'
                    type='checkbox'
                    checked={checked}
                    onChange={() => onChange()}
                />
                <span>{label}</span>
                </label>
            </div>
        // </div>
    )
    
}
