/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import EditUserDropdown from '../components/userDropdowns/EditUserDropdown';
import { useForm } from 'react-hook-form';
import AlertModal from '../components/AlertModal';
import { AppContext } from '../util/AppContext';
import { getConfigValue } from "../util/getConfigValue";
import { useErrorBoundary } from "react-error-boundary";
import AddProductRow from '../components/userComponents/AddProductRow';

function EditUserPage() {
    const { state } = useLocation();
    const [user] = useState(state.user);
    const [currentUser, setCurrentUser] = useState();
    const [formRole, setFormRole] = useState(state.user.role);
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [status, setStatus] = useState();
    const [phoneNumber] = useState("");
    const [applicationData, setApplicationData] = useState([]);
    const [dataFeed, setDataFeed] = useState([]);
    const [alert, setAlert] = useState(state?.alert || null);
    const [userDisabledWarning, setUserDisabledWarning] = useState(state?.alert || null);
    const [appState] = useContext(AppContext);
    const [userRole] = useState(appState.role);
    const [includeInUtilizationReporting, setincludeInUtilizationReporting] = useState(user.includeInUtilizationReporting);
    const [showAllAppTerritories, setShowAllAppTerritories] = useState(false);
    const [showAllFeedTerritories, setShowAllFeedTerritories] = useState(false);
    const [accessModified, setAccessModified] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm(); // form validation
    const userType = "User";
    const adminUserType = "Admin";
    const precisionUserType = "Precision";
    const { showBoundary } = useErrorBoundary();

    const maxTerritories = parseInt(appState.config.EditUser?.MaxTerritoriesDisplay?.Value, 10) || 10;

    const [products, setProducts] = useState([]);

    function trimWhiteSpace(e) {
        e.target.value = e.target.value.trim();
    }

    function userNavigation() {
        if (userRole) {
            if (userRole !== adminUserType && userRole !== precisionUserType) {
                navigate('/');
            }
        }
    }

    userNavigation();

    //extract Access Array Object
    useEffect(() => {
        if (currentUser) {
            let data = [];

            appState.productDetails.map(e => {
                data.push({ product: e, selectedTerritories: getProductTerritoryAccess(currentUser.access, e), GrantAllAccess: getProductAccessOverride(currentUser.access, e) })
            });

            setProducts(data);
        };
    }, [currentUser])

    //Call for Users
    useEffect(() => {
        const fetchUser = async () => {
            await fetch(
                `${process.env.REACT_APP_API_URL}User/${encodeURIComponent(user.eMail)}`, {
                method: "GET",
                credentials: "include",
            })
                .then(response => response.json())
                .then(responseJson => {
                    setCurrentUser(responseJson);
                    setStatus(responseJson.status);
                })
                .catch(e => showBoundary(e));
        }
        fetchUser();
    }, [])

    useEffect(() => {
        setFirstName(user.firstName)
        setLastName(user.lastName)
    }, [user])

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        if (isDirty()) {
            setAlert({ type: 'warning', heading: getConfigValue(appState?.config?.UserManagement?.EditUser?.Modified?.Title, appState)?.Value || null, message: getConfigValue(appState?.config?.UserManagement?.EditUser?.Modified?.Message, appState)?.Value || null });
        } else {
            setAlert(null);
        }
    }, [currentUser, firstName, lastName, formRole, accessModified, includeInUtilizationReporting, status]);

    useEffect(() => {
        if (!currentUser) {
            return;
        }

        if (isDirty() && status === "3") {
            setUserDisabledWarning({ type: 'warning', heading: null, message: getConfigValue(appState?.config?.UserManagement?.Modal?.Message?.NotFromDataFeed, appState)?.Value || null });
        } else {
            setUserDisabledWarning(null);
        }
    }, [status]);



    function getProductAccessOverride(accessArray, product) {
        let accessGranted = false;

        accessArray.forEach(item => {
            if (item.productTerritoryAccessOverride !== null) {
                let affectedProduct = item.productTerritoryAccessOverride.find(p => p.productId === product.clientProductId);
                accessGranted = affectedProduct?.accessAllTerritories || false;
            }
        });

        return accessGranted;
    }

    function getProductTerritoryAccess(accessArray, product) {
        let territories = [];
        let territoriesExist = false;
        accessArray.forEach(item => {
            item.markets.forEach(market => {
                market.territories.forEach(territory => {
                    territory.productId.forEach(ap => {
                        territoriesExist = territories.some(t => t.id === territory.id);
                        if (ap === product.clientProductId && !territoriesExist) {
                            territories.push({ id: territory.id, marketId: market.id, name: territory.name, prescribers: territory.prescribers, type: item.type });
                        }
                    });
                })
            });
        });
        return territories;
    }
    function handleAlignedProducts() {
        let alignedProducts = products.filter(e => e.GrantAllAccess || e.selectedTerritories.length > 0);

        return alignedProducts.map(e => e.product.clientProductId);
    }

    function handleProductTerritoryOverrides() {
        return products.filter(e => e.GrantAllAccess).map(e => {
            return {
                ProductId: e.product.clientProductId, accessAllTerritories: e.GrantAllAccess
            }
        })
    }

    function handleUpdateUser() {
        const updateUser = async () => {
            const url = `${process.env.REACT_APP_API_URL}user`;
            const parameters = {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    dataSource: "Application",
                    role: { name: formRole },
                    firstName,
                    lastName,
                    email: user.eMail,
                    productIds: handleAlignedProducts(),
                    access: accessInfo,
                    status: parseInt(status, 10),
                    phoneNumber,
                    includeInUtilizationReporting
                })
            }
            const response = await fetch(url, parameters);
            if (response.status === 200) {
                navigate('/UserInfoPage', { state: { alert: { type: 'success', heading: (getConfigValue(appState?.config?.UserManagement?.Success?.Title, appState)?.Value || null), message: (getConfigValue(appState?.config?.UserManagement?.Messages?.Edit?.Success, appState)?.Value || null) } } });
            } else if (response.status === 403) {
                setAlert({ type: 'danger', heading: (getConfigValue(appState?.config?.UserManagement?.Failure?.Title, appState)?.Value || null), message: (getConfigValue(appState?.config?.UserManagement?.Messages?.Edit?.InsufficientPrivilidge, appState)?.Value || null) });
            } else if (response.status === 409) {
                setAlert({ type: 'danger', heading: (getConfigValue(appState?.config?.UserManagement?.Failure?.Title, appState)?.Value || null), message: (getConfigValue(appState?.config?.UserManagement?.Messages?.GeneralError, appState)?.Value || null) });
            } else {
                setAlert({ type: 'danger', heading: (getConfigValue(appState?.config?.UserManagement?.Failure?.Title, appState)?.Value || null), message: (getConfigValue(appState?.config?.UserManagement?.Messages?.GeneralError, appState)?.Value || null) });
            }
        }
        updateUser();
    }

    function handleAccessInfo() {
        const markets = [];

        products.filter(e => e.selectedTerritories.length > 0 || e.GrantAllAccess).map(item => {
            item.selectedTerritories.filter(t => t.type !== "DataLoader").forEach(t => {
                let existingMarket = markets.find(m => m.id === t.marketId);

                // only allow unique market/territory mappings
                if (existingMarket === undefined) {
                    markets.push({
                        "id": t.marketId,
                        "territories": [{
                            "id": t.id,
                            "productId": []
                        }]
                    });
                } else {
                    let existingTerritory = existingMarket.territories.find(mt => mt.id === t.id);
                    if (existingTerritory === undefined) {
                        existingMarket.territories.push({
                            "id": t.id,
                            "productId": []
                        });
                    }
                }
            });

        });

        // update market/territory product access
        let productTerritories = [];
        products.forEach(p => {
            if (p.selectedTerritories.length > 0) {
                p.selectedTerritories.filter(t => t.type !== "DataLoader").forEach(t => {
                    productTerritories.push({ productId: p.product.clientProductId, territoryId: t.id, marketId: t.marketId });
                });
            }
        })

        markets.forEach(m => {
            m.territories.forEach(t => {
                let ids = productTerritories.filter(pt => pt.marketId === m.id && pt.territoryId === t.id).map(x => x.productId);
                t.productId = ids;
            });
        });

        return markets;
    }

    const accessInfo = [{
        type: "Application",
        productTerritoryAccessOverride: handleProductTerritoryOverrides(),
        markets: handleAccessInfo(),
    }]


    function addSelectedTerritories(productId, selectedTerrtitores, grantAll) {
        let items = [...products];
        let product = items.find(e => e.product.id === productId);
        product.GrantAllAccess = grantAll;
        product.selectedTerritories = [];

        if (!grantAll) {
            product.selectedTerritories = selectedTerrtitores;
        }

        setProducts(items);
        setAccessModified(true);
    }

    function handleSetFormRole(role) {
        if (role !== formRole) {
            setFormRole(role);

            if (role !== userType) {
                // remove assigned territorties
                setApplicationData([]);
            }
        }
    }

    function isDirty() {
        if (accessModified) {
            return true;
        }
        const newUser = {
            role: formRole,
            firstName,
            lastName,
            email: user.eMail,
            includeInUtilizationReporting,
            status: parseInt(status, 10)
        }
        const compareObject = {
            ...currentUser,
            role: currentUser.role.name
        };
        return Object.keys(newUser).some(key => {
            if (compareObject.hasOwnProperty(key) && newUser[key] !== compareObject[key]) {
                return true;
            }
            return false;
        });
    }

    function canSave() {
        if (!currentUser) {
            return false;
        }
        return isDirty();
    }

    return (
        <div>
            <nav aria-label="breadcrumb" className="breadcrumb-wrapper">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-12">
                            <ol className="breadcrumb">
                                <Link to="/" className="breadcrumb-item">
                                    <span className="fas fa-house"></span>
                                    <span className="sr-only">Home</span>
                                </Link>
                                <li className="breadcrumb-item"><a href="#">Settings</a></li>
                                <Link to="/UserInfoPage" className="breadcrumb-item">Users</Link>
                                <li className="breadcrumb-item active" aria-current="page">{user.firstName} {user.lastName}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </nav>

            <div className="container-xxl mt-4">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        {alert && (
                            <AlertModal alert={alert} />
                        )}
                        <h1 className="type-24 my-0">{user.firstName} {user.lastName}</h1>
                    </div>
                </div>
            </div>
            <div className="container-xxl mt-4">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">

                        <form onSubmit={handleSubmit(handleUpdateUser)}>
                            <div className="card pd-card py-3 mb-5">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-12">
                                            <h2 className="type-20 type-bold mt-2">{getConfigValue(appState?.config?.UserManagement?.EditUser?.Soruces?.Label, appState)?.Value || null}</h2>
                                            <div className="row mt-3 mb-5">
                                                <div className="col-12 col-sm-6 mt-sm-0">
                                                    <p className="my-0">{getConfigValue(appState?.config?.UserManagement?.EditUser?.DataFeed?.Label, appState)?.Value || null} <span className="d-block mt-1">{user.source === "DataLoader" ? <span className="fa-regular fa-signal-stream me-2 color-success"></span> : <span className="fa-regular fa-signal-stream color-grey-d1 me-2"></span>}{user.source !== "Application" ? "Present in Feed" : "None"}</span></p>
                                                </div>
                                                <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                                    <p className="my-0">{getConfigValue(appState?.config?.UserManagement?.EditUser?.ApplicationOverrides?.Label, appState)?.Value || null} <span className="d-block mt-1">{user.source === "Application" ? <span className="fa-regular fa-user me-2 color-success"></span> : <span className="fa-regular fa-user-xmark color-grey-d1 me-2"></span>}{user.source === "Application" ? "Present in Feed" : "None"}</span></p>
                                                </div>
                                            </div>
                                            <h2 className="type-20 type-bold mb-3">{getConfigValue(appState?.config?.UserManagement?.EditPage?.UserInformation?.Label, appState)?.Value || null}</h2>
                                            <label htmlFor="user-name-first" className="form-label">{getConfigValue(appState?.config?.UserManagement?.User?.FirstName, appState)?.Value || null} *</label>
                                            {user.source === "DataLoader" ?
                                                <>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="user-name-first"
                                                        defaultValue={user.firstName}
                                                        disabled
                                                    >
                                                    </input>
                                                    <div className="type-12 color-grey-d1 mt-1">{getConfigValue(appState?.config?.UserManagement?.AddEditUser?.UserRole?.Messages?.FirstName?.DataLoader, appState)?.Value || null}</div>
                                                </>
                                                :
                                                <>
                                                    <input
                                                        {...register("firstName", {
                                                            required: (getConfigValue(appState?.config?.UserManagement?.User?.FirstName, appState)?.Value || null) + " name is required.",
                                                            onBlur: trimWhiteSpace,
                                                            pattern: {
                                                                value: /^[A-Z0-9 ,.'_-]+$/i,
                                                                message: "Please enter valid " + (getConfigValue(appState?.config?.UserManagement?.User?.FirstName, appState)?.Value || null)
                                                            },
                                                            maxLength: {
                                                                value: 50,
                                                                message: "Max length is 50.",
                                                            },
                                                        })}
                                                        type="text"
                                                        className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
                                                        id="user-name-first"
                                                        defaultValue={user.firstName}
                                                        onChange={(e) => {
                                                            setFirstName(e.target.value);
                                                        }}>
                                                    </input>
                                                    {errors.firstName && <div className="invalid-feedback">{errors.firstName.message}</div>}
                                                </>
                                            }
                                            <div className="my-3"></div>
                                            <label htmlFor="user-name-last" className="form-label">{getConfigValue(appState?.config?.UserManagement?.User?.LastName, appState)?.Value || null} *</label>
                                            {user.source === "DataLoader" ?
                                                <>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="user-name-last"
                                                        defaultValue={user.lastName}
                                                        disabled
                                                    >
                                                    </input>
                                                    <div className="type-12 color-grey-d1 mt-1">{getConfigValue(appState?.config?.UserManagement?.AddEditUser?.UserRole?.Messages?.LastName?.DataLoader, appState)?.Value || null}</div>
                                                </>
                                                :
                                                <>
                                                    <input
                                                        {...register("lastName", {
                                                            required: (getConfigValue(appState?.config?.UserManagement?.User?.LastName, appState)?.Value || null) + " is required.",
                                                            onBlur: trimWhiteSpace,
                                                            pattern: {
                                                                value: /^[A-Z0-9 ,.'_-]+$/i,
                                                                message: "Please enter valid " + (getConfigValue(appState?.config?.UserManagement?.User?.LastName, appState)?.Value || null)
                                                            },
                                                            maxLength: {
                                                                value: 50,
                                                                message: "Max length is 50.",
                                                            },
                                                        })}
                                                        type="text"
                                                        className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
                                                        id="user-name-last"
                                                        defaultValue={user.lastName}
                                                        onChange={(e) => {
                                                            setLastName(e.target.value);
                                                        }}>
                                                    </input>
                                                    {errors.lastName && <div className="invalid-feedback">{errors.lastName.message}</div>}
                                                </>
                                            }
                                            <div className="my-3"></div>
                                            <label htmlFor="user-email" className="form-label">{getConfigValue(appState?.config?.UserManagement?.User?.eMail, appState)?.Value || null}</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="user-email"
                                                defaultValue={user.eMail}
                                                disabled
                                            >
                                            </input>

                                            <div className="my-4"></div>
                                            <div className="form-label">{getConfigValue(appState?.config?.UserManagement?.User?.UtilizationReporting?.Title, appState)?.Value || null}</div>
                                            <div className="form-check my-2 py-1">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="includeInUtilization"
                                                    disabled={user.source === "DataLoader"}
                                                    defaultChecked={includeInUtilizationReporting}
                                                    onChange={(e) => {
                                                        setincludeInUtilizationReporting(!includeInUtilizationReporting);
                                                    }}>
                                                </input>
                                                <label className="form-check-label" htmlFor="includeInUtilization">{getConfigValue(appState?.config?.UserManagement?.User?.UtilizationReporting?.Label, appState)?.Value || null}</label>
                                            </div>
                                            <p className="m-0 type-12 color-grey-d1">{getConfigValue(appState?.config?.UserManagement?.User?.UtilizationReporting?.Prompt, appState)?.Value || null}</p>

                                            <div className="my-6"></div>

                                            <div className="container-xxl mt-4">
                                                <div className="row">
                                                    <div className="col-12">
                                                        {userDisabledWarning && (
                                                            <AlertModal alert={userDisabledWarning} />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>


                                            <EditUserDropdown setFormRole={handleSetFormRole} setStatus={setStatus} />

                                            <h2 className="type-20 type-bold mb-2">{getConfigValue(appState?.config?.UserManagement?.User?.MarketsTerritories?.Title, appState)?.Value || null}</h2>
                                            <div className="row">
                                                {formRole === "Admin" || formRole === "Precision" ?
                                                    <p>{getConfigValue(appState?.config?.UserManagement?.User?.MarketsTerritories?.SpecialUsers?.Prompt, appState)?.Value || null}</p> :
                                                    <div className="col-12">
                                                        <p>{getConfigValue(appState?.config?.UserManagement?.User?.AddMarketAndTerritory?.Title, appState)?.Value || null}</p>

                                                        <ul className="list-group list-group-flush list-unstyled">
                                                            {products && products.length > 0 && products.map((product) => {
                                                                return (
                                                                    <li key={product.product.id}>
                                                                        <AddProductRow product={product.product} canAccessAllTerritories={product.GrantAllAccess} selectedTerritories={product.selectedTerritories} onChangeTerritory={addSelectedTerritories} />
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center justify-content-md-end">
                                <div className="col-12 col-sm-8 col-md-8 col-lg-7 col-xl-6">
                                    <div className="d-flex flex-column flex-md-row-reverse">
                                        <button type="submit" className={`btn btn-success btn-lg mb-3 mb-md-0 ms-md-3 flex-fill w-100 ${!canSave() ? 'disabled' : ''}`}>{getConfigValue(appState?.config?.UserManagement?.SaveUser?.Button?.Title, appState)?.Value || null}</button>
                                        <Link to="/UserInfoPage" className="btn btn-secondary btn-lg flex-fill w-100">Cancel</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-7"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default EditUserPage;