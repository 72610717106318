import { getSanitizedHtml } from "../../util/getSanitizedHtml"
import PropType from 'prop-types';

const PrintOptionCheckbox = ({ value, label, checked, onChange, onClick, disabled }) => {

    return (
        <div className="form-check mb-3 d-flex align-items-center">
            <input
                disabled={disabled}
                className="form-check-input type-14 flex-shrink-0"
                type="checkbox"
                id={`checkbox-sheet-options-includehcp-${value}`}
                value={value}
                checked={checked}
                onChange={() => onChange()}
                onClick={(e) => onClick(e)}
            ></input>
            <label className="form-check-label type-14" htmlFor={`checkbox-sheet-options-includehcp-${value}`} dangerouslySetInnerHTML={getSanitizedHtml(label)}></label>
        </div>
    )
}

PrintOptionCheckbox.propTypes = {
    value: PropType.string,
    label: PropType.string,
    checked: PropType.bool,
    onChange: PropType.func,
    onClick: PropType.func,
    disabled: PropType.bool,

}

export default PrintOptionCheckbox;