export function getFileNameFromHeader(response){
    let fileName = 'DynamicFileName.pdf';
    let disposition = response.headers.get('content-disposition');
    if(disposition){
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) { 
            fileName = matches[1].replace(/['"]/g, '');
        }            
    }
    return fileName;   
}