/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../util/AppContext';
import { getConfigValue } from '../util/getConfigValue';
import UserInfoRow from '../components/userComponents/UserInfoRow';
import useComponentVisible from "../hooks/useComponentVisible";
import UserFilterDropdown from '../components/userDropdowns/UserFilterDropdown';
import AlertModal from '../components/AlertModal';
import { useErrorBoundary } from "react-error-boundary";

function UserInfoPage() {
    const [users, setUsers] = useState([]);
    const [refreshUsersToken, setRefreshUsersToken] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [filteredUsers, setFilteredUsers] = useState([])
    const [searchInput, setSearchInput] = useState("");
    const [filterMenuRef, filterMenuIsVisible, setFilterMenuIsVisible] =  useComponentVisible(false);
    const defaultFilterState = {
        user: true,
        admin: true,
        precisionAdmin: true,
        enabled: true,
        disabled: false,
        dataFeed: true,
        application: true,
    };
    const [filterState, setFilterState] = useState(defaultFilterState);
    const { state } = useLocation();
    const [ alert, setAlert ] = useState(state?.alert || null);
    const [ appState ] = useContext(AppContext);
    const [ userRole ] = useState(appState.role);
    const navigate = useNavigate();
    const { showBoundary } = useErrorBoundary();

    function userNavigation() {
        if (userRole) {
            if (userRole !== 'Admin' && userRole !== 'Precision') {
                navigate('/');
            }
        }
    }

    userNavigation();

    function filterByValue(arrayOfObjects, searchString) {
        if (!Array.isArray(arrayOfObjects)) {
            return [];
        }
        const withFullName = arrayOfObjects.map(obj => ({...obj, fullname: obj.firstName + " " + obj.lastName}) )
        const results = withFullName.filter(object =>
            Object.keys(object).some(key => {
                if (typeof object[key] === 'string') {
                    return object[key].toLowerCase().includes(searchString.toLowerCase().replace(",", ""))
                }
                return null;
            }));
        return results;
    }

    // Call for Users
    useEffect(() => {
        setIsLoading(true);
        const fetchUser = async () => {
            await fetch(
                `${process.env.REACT_APP_API_URL}Users/`, {
                method: "POST",
                credentials: "include",
            })
                .then(response => {
                    return response.json()
                })
                .then(responseJson => {
                    setUsers(responseJson);
                })
                .catch(error => {
                    showBoundary(error);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        };
            fetchUser();
    }, [refreshUsersToken])

    useEffect(() => {
        function filterUsers(element) {
            let shouldInclude = false;

            if (filterState.user === true && element.role === 'User') {
                shouldInclude = true;
            }
            else if (filterState.admin === true && element.role === 'Admin') {
                shouldInclude = true;
            }
            else if (filterState.precisionAdmin === true && element.role === 'Precision') {
                shouldInclude = true;
            }

            if (filterState.enabled === true && element.status !== 'Disabled') {
                shouldInclude = shouldInclude && true;
            }
            else if (filterState.disabled === true && element.status === 'Disabled') {
                shouldInclude = shouldInclude && true;
            }
            else {
                shouldInclude = false;
            }

            if (filterState.dataFeed === true && element.source === 'DataLoader') {
                shouldInclude = shouldInclude && true;
            }
            else if (filterState.application === true && element.source === 'Application') {
                shouldInclude = shouldInclude && true;
            }
            else {
                shouldInclude = false;
            }
            return shouldInclude;
        }

        if (searchInput.length > 0) {
            const results = filterByValue(users, searchInput.trim());
            setFilteredUsers(results.filter(filterUsers));
        } else if (searchInput.length === 0) {
            setFilteredUsers(users.filter(filterUsers));
        }
    }, [filterState, searchInput, users]);

    return (
        <div>
            <nav aria-label="breadcrumb" className="breadcrumb-wrapper">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-12">
                            <ol className="breadcrumb">
                            <Link to="/" className="breadcrumb-item">
                                <span className="fas fa-house"></span>
                                <span className="sr-only">Home</span>
                            </Link>
                            <li className="breadcrumb-item">
                                <a href="#">Settings</a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Users
                            </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </nav>
            
            {alert && (
            
            <div className="container-xl mt-4">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <AlertModal alert={alert} />
                    </div>
                </div>
            </div>
            )}

            <div className="container-xl mt-4">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <h1 className="type-24 mb-4">Users</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-7">
                        <div className="input-group">
                            <input
                                value={searchInput}
                                type="text"
                                className="form-control icon-search"
                                id="users-search"
                                aria-label="Search by name or email"
                                placeholder="Search by name or email"
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-9 col-md d-flex mt-3 mt-md-0 ps-md-0 justify-content-start justify-content-lg-end">
                        <div className="d-flex w-100">
                            {/* Functionality of filter selection  */}
                            <button
                                type='button'
                                className="btn btn-outline-primary btn-icon w-100 me-2"
                                onClick={() => setFilterMenuIsVisible(!filterMenuIsVisible)}
                                data-bs-toggle="collapse"
                                data-bs-target="#options-row-1"
                                aria-expanded="false"
                                aria-controls="options-row-1"
                                >
                                Filters
                            <span className="fa-regular fa-chevron-down btn-icon"></span>
                            </button>
                            {filterMenuIsVisible && <UserFilterDropdown setFilterState={setFilterState} filterState={filterState} defaultFilterState={defaultFilterState}/> }
                            <Link to="/AddUserPage" className="btn btn-outline-primary btn-icon w-100 ms-2">New user <span className="fa-regular fa-plus btn-icon"></span></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl mt-5 mb-5 mb-lg-7">
                <div className="container-xl">

                    {/* <!-- Plan table header row --> */}

                    <div className="row pd-plan-table-header align-items-lg-center d-flex py-3">
                        <div className="pd-plan-col col-10 col-md-7 col-lg-4">User</div>
                        <div className="pd-plan-col col-sm-2 d-none d-lg-block">Role</div>
                        <div className="pd-plan-col col-sm-2 d-none d-md-block">Status</div>
                        <div className="pd-plan-col col-sm-3 d-none d-lg-block">Source</div>
                        {/* <!-- Button - options --> */}
                        <div className="pd-plan-col pd-plan-button col-lg-1"></div>
                    </div>

                    {/* <!-- Content row --> */}

                    {isLoading ? (
                        // Loading
                        <div className="row justify-content-center mt-7">
                            <div className="col-12 col-md-6 col-lg-7 col-xl-8">
                                <div className="d-flex justify-content-center">
                                    <div className="fa-spinner fa-spin-pulse fa-regular fa-3x text-secondary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : filteredUsers.length > 0 ?
                        // Results found
                        filteredUsers.map((user, index) => {
                            return (
                                <UserInfoRow key={index} user={user} setAlert={setAlert} refreshUsers={() => setRefreshUsersToken(refreshUsersToken+1)} />
                                )
                            })
                        :
                        // No results found
                        <div className="row justify-content-center mt-7">
                            <div className="col-12 col-md-6 col-lg-7 col-xl-8">
                                <div className="alert alert-warning gap-2" role="alert" style={{ flexDirection: 'column'}}>
                                    <h4 className="alert-heading">{getConfigValue(appState.config?.Common?.NoResultsFound, appState)?.Value}</h4>
                                    <p>{getConfigValue(appState.config?.Common?.AdjustFilters, appState)?.Value}</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
export default UserInfoPage;
