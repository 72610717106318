export async function getSpecialtyPharmacies(productId, planIdentity) {
    if (!productId || !planIdentity) {
        throw new Error('Missing required parameters');
    }

    const url = `${process.env.REACT_APP_API_URL}SpecialtyPharmacies/${encodeURIComponent(productId)}/${encodeURIComponent(planIdentity)}`;
    const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.status === 200) {
        return await response.json();
    } else {
        throw response.statusText;
    }
}

