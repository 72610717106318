import React, { useEffect, useContext, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { AppContext } from "../util/AppContext";
import { getPrescriberById } from '../API/prescriber'
import { useErrorBoundary } from "react-error-boundary";
import { getConfigValue } from '../util/getConfigValue';


function AlignedMessagesForUserPage() {
	const [appState, setAppState] = useContext(AppContext);
	const { commonTerritoryId, prescriberId, level, productId, messageCode } = useParams();
	const { showBoundary } = useErrorBoundary();
	const navigate = useNavigate();
	const [invalidParams, setInvalidParams] = useState(false);

	useEffect(() => {

		//Handle Config appState
		if (!appState.configLoaded || !appState.configRefreshed) {
			return;
		}

		//Set Market and Territory in AppState Function
		async function handleMarketTerritoryAppState() {
			let market = null;
			let territory = null;
			const markets = JSON.parse(sessionStorage.getItem('marketsForUser'));
			markets.forEach(m => {
				// check territory
				const t = m.territories.find(t => t.id === commonTerritoryId);
				if (t) {
					market = m;
					territory = t;
					return true;
				}
			});

			return [market, territory];
		};

		//Set Prescriber in AppState Function
		async function handlePrescriberAppState(prescriberId, marketId, territoryId) {
			let prescriber = null;
			let products = null;
			let prescriberType = null;
			await getPrescriberById(prescriberId, marketId, territoryId)
				.then(data => {
					prescriber = data;
					products = prescriber?.products.map(product => product) || null;
					prescriberType = level;
				}).catch(error => { showBoundary(error) });
			return [prescriber, products, prescriberType]
		};

		//Set MessageCode in AppState Function
		function handleMessageCodeAppState(products) {
			const product = products?.find(i => i.productId === parseInt(productId));
			const message = product?.messages.find(m => m.messageCode === messageCode);
			return message;
		};

		// Navigate to PlanList Page 
		function navigatetoPlanListPage() {
			navigate("/PlanListPage")
		}

		const setStateData = async function () {
			if (!commonTerritoryId || !prescriberId || !level || !productId || !messageCode) {
				setInvalidParams(true);
				return;
			};

			let currentProduct = null;
			if (!appState?.productDetails) {
				return;
			};


			currentProduct = appState?.productDetails?.find(p => p.clientProductId === parseInt(productId));
			const [market, territory] = await handleMarketTerritoryAppState();

			if (!market || !territory) {
				setInvalidParams(true);
				return;
			}

			const [prescriber, products, prescriberType] = await handlePrescriberAppState(prescriberId, market?.id, territory?.id);
			const category = handleMessageCodeAppState(products);

			if (!prescriber || !products || !prescriberType || !category || !currentProduct) {
				setInvalidParams(true);
				return;
			}

			setAppState({
				...appState,
				market: market,
				territory: territory,
				prescriber: prescriber,
				products: products,
				prescriberType: prescriberType,
				category: category,
				currentProduct: currentProduct,
			});
			navigatetoPlanListPage();
		};
		setStateData();

	}, [commonTerritoryId, prescriberId, level, productId, messageCode, appState?.productDetails, appState.configLoaded, appState.configRefreshed, showBoundary, navigate, appState, setAppState])

	return invalidParams &&
		<div className="container-xl">
			<div className="row justify-content-center">
				<div className="col-12 col-sm-10 col-md-6">
					<div className="alert alert-danger mt-8" role="alert">
						<div className="alert-icon"></div>
						<div className="alert-content">
							<h4 className="h5 alert-heading">
								{getConfigValue(appState?.config?.MessagePlans?.ForUserErrorMessage?.Title, appState)?.Value || null}</h4>
							<p>{getConfigValue(appState?.config?.MessagePlans?.ForUserErrorMessage?.Label, appState)?.Value || null}</p>
							<p>{getConfigValue(appState?.config?.MessagePlans?.ForUserErrorMessage?.Body, appState)?.Value || null}</p>
						</div>
					</div>
					<div className="text-center mt-5">
						<Link
							to='/'
							className='btn btn-primary btn-lg'>
							{getConfigValue(appState.config?.MessagePlans?.PrintOnDemand?.Label, appState)?.Value}
						</Link>
					</div>
				</div>
			</div>
		</div>

}

export default AlignedMessagesForUserPage;