/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useState, useContext, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { roleOptions, roleOptionsAdmin } from '../components/userDropdowns/roleOptions';
import AlertModal from '../components/AlertModal';
import { AppContext } from '../util/AppContext';
import { getConfigValue } from "../util/getConfigValue";
import AddProductRow from '../components/userComponents/AddProductRow';

function AddUserPage() {
    const [showTerritoryModal, setShowTerritoryModal] = useState(false);
    const [firstName, setfirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState();
    const [includeInUtilizationReporting, setincludeInUtilizationReporting] = useState(true);
    const [applicationData, setApplicationData] = useState([]);
    const navigate = useNavigate();
    const [isValid, setIsValid] = useState(false);
    const [attemptPost, setAttemptPost] = useState(false);
    const { state } = useLocation();
    const [alert, setAlert] = useState(state?.alert || null);
    const [appState] = useContext(AppContext);
    const [userRole] = useState(appState.role);
    const { register, handleSubmit, formState: { errors } } = useForm(); // form validation
    const [products, setProducts] = useState([]);

    useEffect(() => {
        // build products/territories array
        let data = [];
        appState.productDetails.map(e => {
            data.push({ product: e, selectedTerritories: [], GrantAllAccess: false })
        });

        setProducts(data);
        setRole("User");
    }, []);

    function trimWhiteSpace(e) {
        e.target.value = e.target.value.trim();
    }

    function handleAddUser() {
        setAttemptPost(true);
        setIsValid(true);
        postUser();
    }
    function userNavigation() {
        if (userRole) {
            if (userRole !== 'Admin' && userRole !== 'Precision') {
                navigate('/');
            }
        }
    }

    userNavigation();

    function postUser() {
        const addUser = async () => {
            const url = `${process.env.REACT_APP_API_URL}user`;
            const parameters = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    dataSource: "Application",
                    role: { name: role },
                    firstName,
                    lastName,
                    email,
                    productIds: handleAlignedProducts(),
                    access: accessInfo,
                    includeInUtilizationReporting
                })
            }
            const response = await fetch(url, parameters);

            if (response.status === 200) {
                navigate('/UserInfoPage', { state: { alert: { type: 'success', heading: (getConfigValue(appState?.config?.UserManagement?.Success?.Title, appState)?.Value || null), message: (getConfigValue(appState?.config?.UserManagement?.Messages?.Add?.Success, appState)?.Value || null) } } });
            } else if (response.status === 403) {
                setAlert({ type: 'danger', heading: (getConfigValue(appState?.config?.UserManagement?.Failure?.Title, appState)?.Value || null), message: (getConfigValue(appState?.config?.UserManagement?.Messages?.Add?.InsufficientPrivilidge, appState)?.Value || null) });
            } else if (response.status === 409) {
                setAlert({ type: 'danger', heading: (getConfigValue(appState?.config?.UserManagement?.Failure?.Title, appState)?.Value || null), message: (getConfigValue(appState?.config?.UserManagement?.Messages?.Add?.Duplicate, appState)?.Value || null) });
            } else {
                setAlert({ type: 'danger', heading: (getConfigValue(appState?.config?.UserManagement?.Failure?.Title, appState)?.Value || null), message: (getConfigValue(appState?.config?.UserManagement?.Messages?.GeneralError, appState)?.Value || null) });
            }
        }
        addUser();
    }

    function addSelectedTerritories(productId, selectedTerrtitores, grantAll) {
        setAttemptPost(false);
        let items = [...products];
        let product = items.find(e => e.product.id === productId);
        product.GrantAllAccess = grantAll;
        product.selectedTerritories = [];

        if (!grantAll) {
            product.selectedTerritories = selectedTerrtitores;
        }

        setProducts(items);
        setAttemptPost(false);
    }

    function handleAlignedProducts() {
        let alignedProducts = products.filter(e => e.GrantAllAccess || e.selectedTerritories.length > 0);

        return alignedProducts.map(e => e.product.clientProductId);
    }

    function handleProductTerritoryOverrides() {
        return products.filter(e => e.GrantAllAccess).map(e => {
            return {
                ProductId: e.product.clientProductId, accessAllTerritories: e.GrantAllAccess
            }
        })
    }

    function handleAccessInfo() {
        const markets = [];

        products.filter(e => e.selectedTerritories.length > 0 || e.GrantAllAccess).map(item => {
            item.selectedTerritories.forEach(t => {
                let existingMarket = markets.find(m => m.id === t.marketId);

                // only allow unique market/territory mappings
                if (existingMarket === undefined) {
                    markets.push({
                        "id": t.marketId,
                        "territories": [{
                            "id": t.id,
                            "productId": []
                        }]
                    });
                } else {
                    let existingTerritory = existingMarket.territories.find(mt => mt.id === t.id);
                    if (existingTerritory === undefined) {
                        existingMarket.territories.push({
                            "id": t.id,
                            "productId": []
                        });
                    }
                }
            });

        });

        // update market/territory product access
        let productTerritories = [];
        products.forEach(p => {
            if (p.selectedTerritories.length > 0) {
                p.selectedTerritories.forEach(t => {
                    productTerritories.push({ productId: p.product.clientProductId, territoryId: t.id, marketId: t.marketId });
                });
            }
        })

        markets.forEach(m => {
            m.territories.forEach(t => {
                let ids = productTerritories.filter(pt => pt.marketId === m.id && pt.territoryId === t.id).map(x => x.productId);
                t.productId = ids;
            });
        });

        return markets;
    }

    const accessInfo = [{
        type: "Application",
        productTerritoryAccessOverride: handleProductTerritoryOverrides(),
        markets: handleAccessInfo(),
    }]

    return (
        <div>
            <nav aria-label="breadcrumb" className="breadcrumb-wrapper">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-12">
                            <ol className="breadcrumb">
                                <Link className="breadcrumb-item" to='/'><span className="fas fa-house"></span><span className="sr-only">Home</span></Link>
                                <li className="breadcrumb-item"><a href="#">Settings</a></li>
                                <Link to="/UserInfoPage" className="breadcrumb-item">Users</Link>
                                <li className="breadcrumb-item active" aria-current="page">Add new user</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </nav>
            {alert && (
                <div className="container-xxl mt-4">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8">
                            <AlertModal alert={alert} />
                        </div>
                    </div>
                </div>
            )}
            <div className="container-xxl mt-4">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        <h1 className="type-24 my-0">{getConfigValue(appState?.config?.UserManagement?.AddUser?.Title, appState)?.Value || null}</h1>
                    </div>
                </div>
            </div>
            <div className="container-xxl mt-4">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        <form onSubmit={handleSubmit(handleAddUser)}>
                            <div className="card pd-card py-3 mb-5">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-12">
                                            <h2 className="type-20 type-bold mb-3">{getConfigValue(appState?.config?.UserManagement?.User?.NameEmail?.Title, appState)?.Value || null}</h2>
                                            <label htmlFor="user-name-first" className="form-label">{getConfigValue(appState?.config?.UserManagement?.User?.FirstName, appState)?.Value || null} * </label>
                                            <input
                                                {...register("firstName", {
                                                    required: (getConfigValue(appState?.config?.UserManagement?.User?.FirstName, appState)?.Value || null) + " is required.",
                                                    onBlur: trimWhiteSpace,
                                                    pattern: {
                                                        value: /^[A-Z0-9 ,.'_-]+$/i,
                                                        message: "Please enter a valid " + (getConfigValue(appState?.config?.UserManagement?.User?.FirstName, appState)?.Value || null)
                                                    },
                                                    maxLength: {
                                                        value: 50,
                                                        message: "Max length is 50.",
                                                    },
                                                })}
                                                onChange={e => setfirstName(e.target.value)}
                                                type="text"
                                                className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
                                                id="firstName"
                                                aria-label="First name" />
                                            {errors.firstName && <div className="invalid-feedback">{errors.firstName.message}</div>}
                                            <div className="my-3"></div>
                                            <label htmlFor="user-name-last" className="form-label">{getConfigValue(appState?.config?.UserManagement?.User?.LastName, appState)?.Value || null}</label>
                                            <input
                                                {...register("lastName", {
                                                    required: (getConfigValue(appState?.config?.UserManagement?.User?.LastName, appState)?.Value || null) + " is required.",
                                                    onBlur: trimWhiteSpace,
                                                    pattern: {
                                                        value: /^[A-Z0-9 ,.'_-]+$/i,
                                                        message: "Please enter a valid " + getConfigValue(appState?.config?.UserManagement?.User?.LastName, appState)?.Value || null
                                                    },
                                                    maxLength: {
                                                        value: 50,
                                                        message: "Max length is 50.",
                                                    },
                                                })}
                                                onChange={e => setLastName(e.target.value)}
                                                type="text"
                                                className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
                                                id="lastName"
                                                aria-label="Last name" />
                                            {errors.lastName && <div className="invalid-feedback">{errors.lastName.message}</div>}
                                            <div className="my-3"></div>
                                            <label htmlFor="user-email" className="form-label">{getConfigValue(appState?.config?.UserManagement?.User?.eMail, appState)?.Value || null} * </label>
                                            <input
                                                {...register("email", {
                                                    required: (getConfigValue(appState?.config?.UserManagement?.User?.eMail, appState)?.Value || null) + " is required.",
                                                    onBlur: trimWhiteSpace,
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                        message: "Please enter a valid " + (getConfigValue(appState?.config?.UserManagement?.User?.eMail, appState)?.Value || null)
                                                    },
                                                    maxLength: {
                                                        value: 50,
                                                        message: "Max length is 50.",
                                                    },
                                                })}
                                                onChange={e => setEmail(e.target.value)}
                                                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                                id="email"
                                                type="email"
                                                aria-label="Email" />
                                            {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}

                                            <div className="my-4"></div>
                                            <div className="form-label">{getConfigValue(appState?.config?.UserManagement?.User?.UtilizationReporting?.Title, appState)?.Value || null}</div>
                                            <div className="form-check my-2 py-1">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="includeInUtilization"
                                                    defaultChecked={includeInUtilizationReporting}
                                                    onChange={(e) => {
                                                        setincludeInUtilizationReporting(!includeInUtilizationReporting);
                                                    }}>
                                                </input>
                                                <label className="form-check-label" htmlFor="includeInUtilization">{getConfigValue(appState?.config?.UserManagement?.User?.UtilizationReporting?.Label, appState)?.Value || null}</label>
                                            </div>
                                            <p className="m-0 type-12 color-grey-d1">{getConfigValue(appState?.config?.UserManagement?.User?.UtilizationReporting?.Prompt, appState)?.Value || null}</p>


                                            <div className="my-6"></div>
                                            <h2 className="type-20 type-bold mb-3">{getConfigValue(appState?.config?.UserManagement?.AddEditUser?.DropDown?.Title, appState)?.Value || null}</h2>
                                            <label
                                                htmlFor="user-role"
                                                className="form-label">
                                                {getConfigValue(appState?.config?.UserManagement?.AddEditUser?.DropDown?.Role?.Label, appState)?.Value || null} *
                                            </label>
                                            <select
                                                {...register("role", {
                                                    required: (getConfigValue(appState?.config?.UserManagement?.AddEditUser?.DropDown?.Role?.Label, appState)?.Value || null) + " is required.",
                                                })}
                                                onChange={(e) => setRole(e.target.value)}
                                                className={`form-select ${errors.role ? "is-invalid" : ""}`}
                                                aria-label="Select this user's role"
                                                id="user-role">
                                                {userRole === "Precision" ?
                                                    roleOptions.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.value} label={item.label}>{item.label}</option>
                                                        )
                                                    }) :
                                                    roleOptionsAdmin.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.value} label={item.label}>{item.label}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {errors.role && <div className="invalid-feedback">{errors.role.message}</div>}

                                            <div className="my-6"></div>
                                            <h2 className="type-20 type-bold mb-2">{getConfigValue(appState?.config?.UserManagement?.User?.MarketsTerritories?.Title, appState)?.Value || null}</h2>
                                            <div className="row">
                                                {role === "Admin" || role === "Precision" ?
                                                    <p>{getConfigValue(appState?.config?.UserManagement?.User?.MarketsTerritories?.SpecialUsers?.Prompt, appState)?.Value || null}</p> :
                                                    <div className="col-12">
                                                        <p>{getConfigValue(appState?.config?.UserManagement?.User?.AddMarketAndTerritory?.Title, appState)?.Value || null}</p>

                                                        {attemptPost && !isValid ? <p className='text-danger'>{getConfigValue(appState?.config?.UserManagement?.User?.TerritorySelection?.Label, appState)?.Value || null}</p> : null}

                                                        <ul className="list-group list-group-flush list-unstyled">
                                                            {products && products.length > 0 && products.map((product) => {
                                                                return (
                                                                    <li key={product.product.id}>
                                                                        <AddProductRow product={product.product} selectedTerritories={product.selectedTerritories} onChangeTerritory={addSelectedTerritories} canAccessAllTerritories={product.GrantAllAccess} />
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center justify-content-md-end">
                                <div className="col-12 col-sm-8 col-md-8 col-lg-7 col-xl-6">
                                    <div className="d-flex flex-column flex-md-row-reverse">
                                        <button type="submit" className="btn btn-success btn-lg mb-3 mb-md-0 ms-md-3 flex-fill w-100">{getConfigValue(appState?.config?.UserManagement?.AddUser?.Button?.Title, appState)?.Value || null}</button>
                                        <Link to="/UserInfoPage" className="btn btn-secondary btn-lg flex-fill w-100">Cancel</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-7"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AddUserPage;