export async function setUserState(emailAddress, newStatus) {
    if (!emailAddress) {
        throw new Error('No emailAddress was provided.');
    }

    const url = `${process.env.REACT_APP_API_URL}user/status?emailAddress=${encodeURIComponent(emailAddress)}&newStatus=${newStatus}`;
    const parameters = {
        method: "PUT",
        credentials: "include",
        headers: {
            "Content-Type": "text/plain; charset=utf-8"
        },
    }

    return await fetch(url, parameters);
};