import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import CategoryDropdown from "../components/CategoryDropdown";
import SingleWinPlanListRow from "../components/SingleWinPlanListRow";
import { getMessages } from "../API/message";
import getName from "../util/getName";
import { AppContext } from "../util/AppContext";
import MessageDetailsModal from "../components/MessageDetailsModal";
import MessageTypes from "../util/messageTypes";
import { ProductsModal, ProductsModalButton } from "../components/ProductsModal";
import { getConfigValue } from "../util/getConfigValue";
import PrintOptionDropdown from "../components/printOptions/printOptionDropdown";
import { useErrorBoundary } from "react-error-boundary";
import useScrollPosition from "../hooks/useScrollPosition";

function WinPlanListPage() {
    const [appState, setAppState] = useContext(AppContext);
    const [showProductsModal, setShowProductsModal] = useState(false);
    const [currentProduct, setCurrentProduct] = useState(appState?.currentProduct);
    const [productImageSource, setProductImageSource] = useState(appState?.currentProduct?.imagePath);
    const [prescriberId] = useState(appState.prescriber?.id);
    const [messageCode, setMessageCode] = useState(appState.category?.messageCode);
    const [categories, setCategories] = useState([]);
    const [pageCategory, setPageCategory] = useState(appState.category);
    const [plans, setPlans] = useState(appState.plans);
    const { showBoundary } = useErrorBoundary();
    const [isConfigMenuVisible, setIsConfigMenuVisible] = useState(false);
    const [showMessageDetailsModal, setShowMessageDetailsModal] = useState(false);
    const configMenuRef = useRef(null);
    const configMenuContentRef = useRef(null);

    const isImprovedAccessStatic = true;

    const navigate = useNavigate();
    const scrollPosition = useScrollPosition();

    useEffect(() => {
        if (categories === null || categories.length === 0) {
            getMessageCategories();
        }
    }, []);

    useEffect(() => {
        async function loadMessages() {
            getMessages(messageCode, prescriberId, appState.currentProduct.id, appState.category.messageTypeId)
                .then((responseJson) => {
                    const responsePlans = responseJson.dynamicTable || [];
                    const responseMainMessage = responseJson.prescriberMessage.mainMessage || '';
                    setAppState({ ...appState, plans: responsePlans, mainMessage: responseMainMessage });
                    setPlans(responsePlans);
                })
                .catch(e => showBoundary(e));
        }
        if (messageCode && prescriberId) {
            loadMessages();
        }
        // eslint-disable-next-line
    }, [messageCode, prescriberId, appState.category])

    function getMessageCategories() {
        if (appState.products.length > 0) {
            const categories = appState.products.find((x) => {
                return x.productId === appState.currentProduct.clientProductId;
            })
            setCategories(categories.messages);
        }
    }

    function updateSelectMessageCategory(pageCategory) {
        setMessageCode(pageCategory.messageCode);
        setAppState({
            ...appState,
            category: pageCategory,
            messageCode: pageCategory.messageCode,
        });
    }

    function onProductClick(product) {
        setAppState({ ...appState, currentProduct: product });
        setCurrentProduct(product);
        setShowProductsModal(false);
        setProductImageSource(product.imagePath)
        navigate('/MessageSelectionPage');
    }

    function closeOpenMenus(event) {
        // click outside of menus and content menus = close menus
        if (configMenuRef.current && !configMenuRef.current.contains(event.target) && !configMenuContentRef.current.contains(event.target) && isConfigMenuVisible) {
            setIsConfigMenuVisible(false);
        }
    }

    function onDetailsClick() {
        setShowMessageDetailsModal(true);
    }

    function navigateToPage(category) {
        if (category.messageType === MessageTypes.ImprovedAccess) {
            navigate('/WinPlanListPage');
        } else {
            navigate('/PlanListPage');
        }
    }

    function onDetailsCategoryClick(category) {
        setPageCategory(category);
        setShowMessageDetailsModal(false);
        updateSelectMessageCategory(category);
        navigateToPage(category);
    }

    function planListHeaderSticky() {
        const planListHeader = document.getElementById('plan-list-header');
        if (planListHeader) {
            if (scrollPosition > 150) {
                planListHeader.classList.add("plan-list-header-sticky");
                planListHeader.classList.add("border-bottom");
                document.getElementById('plan-list-header-spacer').classList.add('d-block');
                document.getElementById('plan-list-header-spacer').classList.remove('d-none');
            }
            else if (planListHeader.classList.contains("plan-list-header-sticky")) {
                planListHeader.classList.remove("plan-list-header-sticky");
                planListHeader.classList.remove("border-bottom");
                document.getElementById('plan-list-header-spacer').classList.remove('d-block');
                document.getElementById('plan-list-header-spacer').classList.add('d-none');
            }
        }
    }

    document.addEventListener('mousedown', closeOpenMenus);

    return (
        <>
            <div>
                <div className="title-bar">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="type-24 mt-4 mb-4">{getConfigValue(appState.config?.Focus?.Header, appState)?.Value || null}</h1>
                                <ul className="wizard-list hide-noncurrent-mobile horizontal mb-5">
                                    <li className="wizard-step state-complete">
                                        <span className="wizard-step-icon"></span>
                                        <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Territory?.Label, appState)?.Value || null}</span>
                                    </li>
                                    <li className="wizard-step state-complete">
                                        <span className="wizard-step-icon"></span>
                                        <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Target?.Label, appState)?.Value || null}</span>
                                    </li>
                                    <li className="wizard-step state-current">
                                        <span className="wizard-step-icon"></span>
                                        <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Configure?.Label, appState)?.Value || null}</span>
                                    </li>
                                    <li className="wizard-step state-future">
                                        <span className="wizard-step-icon"></span>
                                        <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Generate?.Label, appState)?.Value || null}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                className="container-fluid pt-3 pt-md-4 pb-1 color-bg-bodybg z-2"
                id="plan-list-header"
                onScroll={planListHeaderSticky()}
                >
                    <div className="container-xl">
                        <div className='row d-flex align-items-md-center mb-2 mb-lg-4'>
                            <div className='col-12 col-md-6 order-2 order-md-1'>
                                <h1 className='type-20 color-grey-d1 mt-4 mt-md-0 hide-sticky'>
                                    {getConfigValue(appState.config?.PlanList?.Title, appState)?.Value}
                                </h1>
                                <div className="row justify-content-end show-sticky-only">
                                    <div className="row justify-content-start">
                                        <div className="col-6 col-md-auto type-14 text-md-start"><div className="color-grey-d1">{getConfigValue(appState.config?.PlanList?.ProductLabel, appState)?.Value}</div>{ appState.currentProduct.name }</div>
                                        <div className="col-6 col-md-auto type-14 text-md-start"><div className="color-grey-d1">{getConfigValue(appState.config?.PlanList?.MessageTypeLabel, appState)?.Value}</div>{ pageCategory.category }</div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 order-1 order-md-2'>
                                <div className='row justify-content-end'>
                                    <div className='col-6 col-md-auto type-14 text-md-end'>
                                        <div className='color-grey-d1'>
                                            {getConfigValue(appState.config?.PlanList?.ProviderLabel, appState)?.Value}
                                        </div>
                                        {getName(appState)}
                                    </div>
                                    <div className='col-6 col-md-auto type-14 text-md-end'>
                                        <div className='color-grey-d1'>
                                            {getConfigValue(appState.config?.PlanList?.TerritoryLabel, appState)?.Value}
                                        </div>
                                        {appState.territory?.name}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-8 d-none" id="plan-list-header-spacer"></div>

                {/* <!-- Dropdowns row --> */}

                <div className="container-xl">

                    <div className="row">
                        {/* This section will be replaced with the config data to pull in the brands */}
                        <div className="col-12 col-md-4">
                            <ProductsModalButton
                                setShowProductsModal={setShowProductsModal}
                                productImageSource={productImageSource}
                                productName={currentProduct?.productName}
                            ></ProductsModalButton>
                        </div>

                        <div className="col-12 col-md-4 mt-4 mt-md-0">

                            <CategoryDropdown
                                pageCategory={pageCategory}
                                onDetailsClick={onDetailsClick}
                            />

                        </div>

                        <div className="col-12 col-md-4 mt-4 mt-md-0">
                            <PrintOptionDropdown
                                isImprovedAccessStatic={isImprovedAccessStatic}
                                isConfigMenuVisible={isConfigMenuVisible}
                                setIsConfigMenuVisible={setIsConfigMenuVisible}
                                configMenuContentRef={configMenuContentRef}
                                configMenuRef={configMenuRef}
                                isDisabled={isImprovedAccessStatic}
                            />
                        </div>
                    </div>

                    {/* <!-- Instructions row. May depend on message type --> */}

                    <div className="row my-5 my-lg-1">
                        <div className="col-12">
                            <p>
                                {getConfigValue(appState.config?.WinPlanList?.CallToAction, appState)?.Value}
                            </p>
                        </div>
                    </div>

                    {/* <!-- Plan list --> */}

                    <div className="container-xl">

                        <div className="row pd-plan-table-header align-items-lg-center d-none d-lg-flex py-3">

                            {/* <!-- Fixed column headers --> */}

                            <div className="pd-plan-col col-lg-3">
                                <div className="row align-items-lg-center">
                                    <div className="col-12">{getConfigValue(appState?.config?.SingleWinPlanListRow?.Channel?.Label, appState)?.Value || null}</div>
                                </div>
                            </div>

                            <div className="pd-plan-col col-lg-4">
                                <div className="row align-items-lg-center">
                                    <div className="col-12">{getConfigValue(appState?.config?.SingleWinPlanListRow?.Plan?.Label, appState)?.Value || null}</div>
                                </div>
                            </div>

                            {/* <!-- Variable column headers --> */}

                            <div className="pd-plan-col col-lg-3">
                                <div className="row align-items-lg-center">
                                    <div className="col-12">{getConfigValue(appState?.config?.SingleWinPlanListRow?.Status?.Label, appState)?.Value || null}</div>
                                </div>
                            </div>

                            <div className="pd-plan-col col-lg-2">
                                <div className="row align-items-lg-center">
                                    <div className="col-12"></div>
                                </div>
                            </div>
                        </div>

                        {plans?.rows &&
                            plans.rows
                                .filter((plan) => plan.data.isWin === true)
                                .map((plan, index) => {
                                    return (
                                        <SingleWinPlanListRow key={index} plan={plan.data} index={index} />
                                    );
                                })}

                    </div>
                    <div className="my-8"></div>
                </div>
            </div>

            <div className="button-bottom-container-sticky">
                <div className="container-xl">
                    <div className="row justify-content-center justify-content-lg-between py-3">
                        <div className="col-10 col-sm-8 col-md-6 col-xl-5">
                            <div className="d-flex flex-column flex-lg-row">
                                <button className="btn btn-outline-primary btn-lg flex-fill w-100 disabled">Preview</button>
                                <div className="flex-fill w-100 ms-lg-3 pe-3"></div>
                            </div>
                            <hr className="my-5 d-lg-none" />
                        </div>
                        <div className="w-100 d-lg-none"></div>
                        <div className="col-10 col-sm-8 col-md-6 col-xl-5 justify-content-end">
                            <div className="d-flex flex-column flex-lg-row-reverse">
                                <button className="btn btn-success btn-lg mb-3 mb-lg-0 ms-lg-3 flex-fill w-100 disabled" type="button">Generate</button>
                                <Link
                                    to="/MessageSelectionPage"
                                    className="btn btn-secondary btn-lg pe-7 flex-fill w-100">
                                    Back
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <MessageDetailsModal
                show={showMessageDetailsModal}
                setShow={setShowMessageDetailsModal}
                categories={categories}
                onDetailsCategoryClick={onDetailsCategoryClick}
            />
            <ProductsModal
                show={showProductsModal}
                setShow={setShowProductsModal}
                onProductClick={onProductClick}
            />
        </>
    );
}

export default WinPlanListPage;
