import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../util/AppContext";
import { roleOptions } from "./roleOptions";
import { FilterDropdownRow } from "./FilterRowDropdown";

function UserFilterDropdown({filterState , setFilterState, defaultFilterState}) {
    const [appState] = useContext(AppContext);
    const [role, setRole] = useState('');

    useEffect(() => {
        if (appState.role && typeof appState.role === 'string') {
            setRole(appState.role);
        }
    }, [appState.role]);

    function handleReset(e) {
        e.preventDefault();
        setFilterState(defaultFilterState)
    }

    return (
        <div
            style={{ display: "block" }}
            className="temp-user-dropdown-filters">
                <div className="row justify-content-center align-items-center">
                    <div className="col-10 py-2 px-2">
                        <div className='d-flex justify-content-between align-items-center border-bottom mb-3'>
                            <h3 className="type-bold type-16 mt-2 mb-2">Filters</h3><a onClick={(e) => handleReset(e)} href="#">Reset</a>
                        </div>
                        <h4 className="type-bold type-16 mt-2 mb-3">User Types</h4>
                            <FilterDropdownRow checked={filterState.user} 
                                onChange={() => setFilterState({...filterState, user: !filterState.user})} 
                                label={roleOptions.find(x => x.value === 'User').label}/>                       

                            <FilterDropdownRow checked={filterState.admin} 
                                onChange={() => setFilterState({...filterState, admin: !filterState.admin})} 
                                label={roleOptions.find(x => x.value === 'Admin').label}/> 
                            {role === "Precision" ? 
                                <FilterDropdownRow checked={filterState.precisionAdmin} 
                                    onChange={() => setFilterState({...filterState, precisionAdmin: !filterState.precisionAdmin})} 
                                    label={roleOptions.find(x => x.value === 'Precision').label}/> : "" }
                        <h4 className="type-bold type-16 mt-3 mb-3">Status</h4>
                            <FilterDropdownRow checked={filterState.enabled} 
                                onChange={() => setFilterState({...filterState, enabled: !filterState.enabled})} 
                                label="Enabled"/> 
                            <FilterDropdownRow checked={filterState.disabled} 
                                onChange={() => setFilterState({...filterState, disabled: !filterState.disabled})} 
                                label="Disabled"/> 
                        <h4 className="type-bold type-16 mt-3 mb-3">Source</h4>
                            <FilterDropdownRow checked={filterState.dataFeed} 
                                onChange={() => setFilterState({...filterState, dataFeed: !filterState.dataFeed})} 
                                label="Data Feed"/> 
                            <FilterDropdownRow checked={filterState.application} 
                                onChange={() => setFilterState({...filterState, application: !filterState.application})} 
                                label="Application"/> 
                    </div>
                </div>
            </div>
    )
}
export default UserFilterDropdown;