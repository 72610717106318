import React, { useState, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getConfigValue } from '../../util/getConfigValue';
import { AppContext } from '../../util/AppContext';
import { roleOptions } from '../userDropdowns/roleOptions';
import { userActions } from '../userComponents/userActions';
import { setUserState } from '../../API/userMangement';
import { useErrorBoundary } from 'react-error-boundary';

function UserModal({ show, setShow, user, action, setAlert, onUpdateUserStatus }) {
	const [appState, setAppState] = useContext(AppContext);
	const [isSaving, setIsSaving] = useState(false);

	const [title, setTitle] = useState(null);
	const [body, setBody] = useState(null);
	const [buttonText, setButtonText] = useState(null);
	const [buttonColor, setButtonColor] = useState(null);
	const { showBoundary } = useErrorBoundary();

	useEffect(() => {
		if (!show) {
			return;
		}
		if (action === userActions.disable) {
			setTitle(getConfigValue(appState?.config?.UserManagement?.Modal?.DisableUser?.Title, appState)?.Value || null);
			setButtonText(getConfigValue(appState?.config?.UserManagement?.Modal?.Button?.DisableUser?.Label, appState)?.Value || null);
			setButtonColor('danger');
			setBody(getConfigValue(appState?.config?.UserManagement?.Modal?.DisableUser?.Prompt, appState)?.Value || null);
		} else if (action === userActions.impersonate) {
			setTitle(getConfigValue(appState?.config?.UserManagement?.Modal?.Impersonate?.Title, appState)?.Value || null);
			setButtonText(getConfigValue(appState?.config?.UserManagement?.Modal?.Button?.Impersonate?.Label, appState)?.Value || null);
			setButtonColor('primary');
			setBody(getConfigValue(appState?.config?.UserManagement?.Modal?.Impersonate?.Prompt, appState)?.Value || null);
		}
	}, [action, show]);

	const handleClose = () => setShow(false);

	async function handleDeleteUser() {
		try {
			setIsSaving(true);
			const newStatus = 'Disabled';
			const response = await setUserState(user.eMail, newStatus);

			if (response.status !== 200) {
				setIsSaving(false);
				throw new Error(response.status);
			} else {
				// update the collection
				if (onUpdateUserStatus) {
					onUpdateUserStatus(newStatus);
				}

				setIsSaving(false);
				handleClose();
			}
		} catch (e) {
			showBoundary(e);
		}
	}

	async function handleImpersonation() {
		setIsSaving(true);
		await fetch(`${process.env.REACT_APP_API_URL}login/${encodeURIComponent(user.eMail)}?returnUrl=${process.env.REACT_APP_API_RETURN_URL}`, {
			method: 'POST',
			credentials: 'include',
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error(response.status);
				} else {
					return response.json();
				}
			})
			.then((data) => {
				if (data) {
					setIsSaving(false);
					setAppState({
						...appState,
						impersonate: {
							isActive: true,
							firstName: user.firstName,
							lastName: user.lastName,
							email: user.eMail,
							role: roleOptions.find((x) => x.value === user.role).label,
						},
					});
					window.location.href = data.authenticationURL;
				}
			})
			.catch((error) => {
				setIsSaving(false);
				handleClose();
				setAlert({
					type: 'danger',
					heading: getConfigValue(appState?.config?.UserManagement?.Impersonate?.Error?.Title, appState)?.Value,
					message: getConfigValue(appState?.config?.UserManagement?.Impersonate?.Error?.Message, appState)?.Value + ' ' + user.eMail,
				});
				setTimeout(function () {
					setAlert(null);
				}, 5000);
			});
	}

	function handleClick() {
		if (action === userActions.disable) {
			handleDeleteUser();
		} else if (action === userActions.impersonate) {
			handleImpersonation();
		}
	}

	return (
		<Modal className='modal-lg' show={show} onHide={handleClose}>
			<Modal.Header className={`modal-header ${action === userActions.disable ? 'danger' : ''}`} closeButton>
				<Modal.Title className='modal-title fs-5'>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modal-body'>
				<p>{body}</p>
				<div className='row mt-2'>
					<div className='col-12 col-lg-6'>
						<p>
							{getConfigValue(appState?.config?.UserManagement?.Modal?.User?.Name, appState)?.Value}
							<span className='d-block color-grey-d1 mt-1'>
								{user.firstName} {user.lastName}
							</span>
						</p>
					</div>
					<div className='col-12 col-lg-6'>
						<p>
							{getConfigValue(appState?.config?.UserManagement?.Modal?.User?.Email, appState)?.Value}
							<span className='d-block color-grey-d1 mt-1'>{user.eMail}</span>
						</p>
					</div>
				</div>
				{action === userActions.disable && user.source === 'DataLoader' ? (
					<p className='mt-3'>
						<strong>Note:</strong>
						<br />
						{getConfigValue(appState?.config?.UserManagement?.Modal?.Message?.NotFromDataFeed, appState)?.Value}
					</p>
				) : (
					''
				)}
			</Modal.Body>
			<Modal.Footer className='modal-footer'>
				<div className='col-12 col-md-10 col-lg-7 px-0'>
					<div className='d-flex w-100 flex-column flex-md-row-reverse'>
						<Button
							disabled={isSaving ? true : null}
							className={`btn btn-${buttonColor} btn-lg mb-3 mb-md-0 ms-md-3 flex-fill w-100 ${isSaving ? 'btn-working' : ''}`}
							size='lg'
							onClick={handleClick}>
							{buttonText}
						</Button>
						<Button className='btn btn-secondary btn-lg flex-fill w-100' size='lg' onClick={handleClose}>
							{getConfigValue(appState?.config?.Common?.Cancel, appState)?.Value}
						</Button>
					</div>
				</div>
			</Modal.Footer>
		</Modal>
	);
}
export default UserModal;
