import { getFileNameFromHeader } from "../util/getFileNameFromHeader";
import PropTypes from 'prop-types';

export const trackingEventType = {
    Preview: 6,
    DownLoad: 7,
    Print: 8,
}

export const documentGenerationType = {
    Generate: 0,
    Preview: 1
}

export const documentType = {
    All: 0,
    Dynamic: 1,
    Static: 2,
}

placePrintOrder.propTypes = {
    userId: PropTypes.string.isRequired,
    numberOfCopies: PropTypes.number.isRequired,
    sellSheetId: PropTypes.number.isRequired,
    addressId: PropTypes.string.isRequired,
};

export async function documentGenerate(props) {
    const url = `${process.env.REACT_APP_API_URL}Document/Generate`;
    const parameters = {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(props)
    }
    const response = await fetch(url, parameters);
    if (response.status === 200) {
        let fileName = getFileNameFromHeader(response);
        let sellSheetId = response.headers.get('sellsheetId');

        var blob = await response.blob();
        return {
            blob,
            fileName,
            sellSheetId
        }
    } else {
        throw response.statusText;
    }
}

export async function trackDocumentRequest(eventType, props, sellSheetId) {
    const url = `${process.env.REACT_APP_API_URL}TrackActivity`;

    const data = {
        eventType: eventType,
        value: JSON.stringify(props),
        sellSheetId: sellSheetId
    };

    const parameters = {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    }

    const response = await fetch(url, parameters);
    if (!response.ok) {
        throw response.statusText;
    }
}

export async function downloadTemplate(id) {
    const url = `${process.env.REACT_APP_API_URL}Template/Download/${id}`;
    const parameters = {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        }
    }
    const response = await fetch(url, parameters);
    if (response.ok) {
        let fileName = getFileNameFromHeader(response);
        var blob = await response.blob();
        return {
            blob,
            fileName
        }
    } else {
        throw response.statusText;
    }
}

export async function getStaticTemplate(id) {
    const url = `${process.env.REACT_APP_API_URL}Template/${id}`;
    const parameters = {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        }
    }
    const response = await fetch(url, parameters);
    if (response.ok) {
        return await response.json();
    } else {
        throw response.statusText;
    }
}

export async function placePrintOrder(userId, numberOfCopies, sellSheetId, addressId) {
    const data = {
        userId: userId,
        sellSheetId: sellSheetId,
        numberOfCopies: numberOfCopies,
        mailingAddressId: addressId
    }

    const url = `${process.env.REACT_APP_API_URL}Document/SendToQueue`;
    const parameters = {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    }

    const response = await fetch(url, parameters);

    if (response.ok) {
        return response;
    } else {
        throw response.statusText;
    }
}
