import React, { useState, useContext } from "react";
import { AppContext } from "../util/AppContext";

function ErrorPage() {
	const [appState] = useContext(AppContext);
	const [pageProperties] = useState(appState.config?.Login?.Error);

	function getDialogTitle() {
		return [pageProperties.Title.Value];
	};

	function getBodyContents() {
		let messageElemets = '';

		pageProperties.Body.forEach(element => {
			messageElemets += element.Value;
		});
		return messageElemets;
	}

	return (
		<div className="container-xl">
			<div className="row justify-content-center">
				<div className="col-12 col-sm-10 col-md-6">
					<div className="alert alert-danger mt-8" role="alert">
						<div className="alert-icon"></div>
						<div className="alert-content">
							<h4 className="h5 alert-heading" dangerouslySetInnerHTML={{ __html: getDialogTitle() }}></h4>
							<p dangerouslySetInnerHTML={{ __html: getBodyContents() }}></p>
						</div>
					</div>
				</div>
			</div>
		</div>

	)
}

export default ErrorPage