import { useContext, useState } from "react";
import { AppContext } from "../../util/AppContext";
import { FilterDropdownRow } from "../userDropdowns/FilterRowDropdown";
import { getConfigValue } from "../../util/getConfigValue";
import { PropTypes } from "prop-types";

DashboardFilterDropdown.propTypes = {
    filterState: PropTypes.shape({
        activeHistory: PropTypes.number,
        current: PropTypes.bool,
        archive: PropTypes.bool,
        submitted: PropTypes.bool.isRequired,
        delayed: PropTypes.bool.isRequired,
        processing: PropTypes.bool.isRequired
    }),
    setFilterState: PropTypes.func,
    defaultFilterState: PropTypes.shape({
        activeHistory: PropTypes.number,
        current: PropTypes.bool,
        archive: PropTypes.bool,
        submitted: PropTypes.bool.isRequired,
        delayed: PropTypes.bool.isRequired,
        processing: PropTypes.bool.isRequired
    })
};

function DashboardFilterDropdown({ filterState, setFilterState, defaultFilterState }) {
    const [appState] = useContext(AppContext);
    const [activityHistoryOptions] = useState(getActivityHistoryOptions);
    const professionalPrintingEnabled = getConfigValue(appState?.config?.ProfessionalPrinting?.Enabled, appState)?.Value === 'true' || false;

    function getActivityHistoryOptions() {
        if (appState?.config?.Dashboard?.Filter?.Months) {
            return Object.entries(appState?.config?.Dashboard?.Filter?.Months)
                .filter(e => e[0] !== "Default" && e[0] !== "Title")
                .map(function (e) {
                    return { ...e[1], id: e[0] };
                })
                .sort(function (a, b) { return a.Order - b.Order });
        }
    }

    function getActivityHistoryValue(item) {
        let value = parseInt(item);

        if (isNaN(value)) {
            value = null;
        }

        return value;
    }

    function handleReset(e) {
        e.preventDefault();
        setFilterState(defaultFilterState);
    }

    return (
        <div
            style={{ display: "block" }}
            className="temp-user-dropdown-filters start-0">
            <div className="row justify-content-center align-items-center">
                <div className="col-10 py-2 px-2">
                    <div className='d-flex justify-content-between align-items-center border-bottom mb-3'>
                        <h3 className="type-bold type-16 mt-2 mb-2">{getConfigValue(appState.config.Dashboard.Filter.Title, appState)?.Value}</h3><a onClick={(e) => handleReset(e)} href="#">{getConfigValue(appState.config.Dashboard.Filter.Reset, appState)?.Value}</a>
                    </div>
                    <h4 className="type-bold type-16 mt-2 mb-3">{getConfigValue(appState.config.Dashboard.Filter.Subtitle, appState)?.Value}</h4>
                    <FilterDropdownRow checked={filterState.current}
                        onChange={() => setFilterState({ ...filterState, current: !filterState.current })}
                        label={getConfigValue(appState.config.Dashboard.Filter.Current, appState)?.Value} />
                    <FilterDropdownRow checked={filterState.archive}
                        onChange={() => setFilterState({ ...filterState, archive: !filterState.archive })}
                        label={getConfigValue(appState.config.Dashboard.Filter.Archive, appState)?.Value} />

                    {professionalPrintingEnabled &&
                        <div className="d-flex flex-column">
                            <h4 className="type-bold type-16 mt-2 mb-3">{getConfigValue(appState.config?.Dashboard?.Filter?.ProfessionalPrinting?.Subtitle, appState)?.Value}</h4>
                            <FilterDropdownRow checked={filterState.submitted}
                                onChange={() => setFilterState({ ...filterState, submitted: !filterState.submitted })}
                                label={getConfigValue(appState.config.Dashboard?.Filter?.ProfessionalPrinting?.Submitted, appState)?.Value} />

                            <FilterDropdownRow checked={filterState.delayed}
                                onChange={() => setFilterState({ ...filterState, delayed: !filterState.delayed })}
                                label={getConfigValue(appState.config.Dashboard?.Filter?.ProfessionalPrinting?.Delayed, appState)?.Value} />

                            <FilterDropdownRow checked={filterState.processing}
                                onChange={() => setFilterState({ ...filterState, processing: !filterState.processing })}
                                label={getConfigValue(appState.config.Dashboard?.Filter?.ProfessionalPrinting?.Processing, appState)?.Value} />
                        </div>
                    }

                    <div className="d-flex flex-column">
                        <h4 className="type-bold type-16 mt-2 mb-3">{getConfigValue(appState?.config?.Dashboard?.Filter?.Months?.Title, appState)?.Value}</h4>
                        <select
                            value={filterState.activeHistory}
                            onChange={(e) => setFilterState({ ...filterState, activeHistory: getActivityHistoryValue(e.target.value) })}
                            className="form-select"
                            aria-label="Select the number of months to view"
                            id="filter-months">
                            {
                                activityHistoryOptions.map((item, index) => {
                                    return (
                                        <option key={item.id} value={item.id} label={item.Value} >{item.Value}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DashboardFilterDropdown;