import { useState, useEffect, useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import useComponentVisible from '../hooks/useComponentVisible';
import prettyDate from "../util/prettyDate";
import { AppContext } from "../util/AppContext";
import { getConfigValue } from "../util/getConfigValue";
import { getCachedThumbNail } from "../API/quickSheets";
import { useErrorBoundary } from "react-error-boundary";

export default function QuickSheetsListView({ documents, onPrintDocument, onDownloadDocument, isLoading, hasNoResults, onPreviewDoucment }) {
    const [appState] = useContext(AppContext);
    const [feedbackMessage, setFeedbackMessage] = useState('');

    useEffect(() => {
        if (isLoading) {
            setFeedbackMessage('Loading...');
        } else if (hasNoResults) {
            setFeedbackMessage(getConfigValue(appState?.config?.Common?.NoResultsFound, appState)?.Value || null);
        } else {
            setFeedbackMessage('');
        }
    }, [isLoading, hasNoResults]);

    return (
        <>
            {feedbackMessage ? (
                <div className="grid text-center">
                    <div className="g-col-12 g-col-sm-6 g-col-md-4 g-col-xl-3 d-flex">
                        <p>{feedbackMessage}</p>
                    </div>
                </div>
            ) : (
                <div className="card pd-card px-3">
                    <div className="row">
                        <div className="col-12">
                            <ul className="list-group list-group-flush">

                                {documents.length > 0 && documents.map((document, index) => (
                                    <QuickSheetsListRow
                                        key={index}
                                        document={document}
                                        onPrintDocument={onPrintDocument}
                                        onDownloadDocument={onDownloadDocument}
                                        onPreviewDoucment={onPreviewDoucment}
                                    />
                                ))}
                            </ul >
                        </div >
                    </div >
                </div >
            )
            }

        </>
    );
}

function QuickSheetsListRow({ document, onPrintDocument, onDownloadDocument, onPreviewDoucment }) {
    const [appState] = useContext(AppContext);
    const [rowMenuRef, rowMenuIsVisible, setRowMenuIsVisible] = useComponentVisible(false);
    const [thumbNail, setThumbNail] = useState(null);
    const { showBoundary } = useErrorBoundary();
    const [systemDateFormat] = useState(getConfigValue(appState?.config?.System?.DateTime?.Format, appState)?.Value || null);

    useEffect(() => {
        getCachedThumbNail(document.documentId).then(text => {
            setThumbNail(text);
        }).catch(e => showBoundary(e));

    }, []);

    const BlobImage = ({ data }) => <img style={{ width: "90px", height: "120px" }} src={`data:image/jpeg;base64,${thumbNail}`} alt='quick sheets preview' />

    return (
        <li className="list-group-item px-0 py-3">
            <div className="row d-flex align-items-center">
                <div className="col-10 col-md-11">
                    <div className="d-flex align-items-center">
                        <div className="list-preview">
                            <button className="btn_qs_preview btn-reset text-start d-none d-md-block" onClick={() => onPreviewDoucment(document)}>
                                <div className="img_preview">
                                    {thumbNail && <BlobImage />}
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-2">
                                    <p className="m-0 type-12 color-primary text-decoration-underline text-decoration-hover-none">{getConfigValue(appState?.config?.QuickSheets?.Download?.Preview, appState)?.Value || null}</p>
                                    <span className="fa-regular fa-magnifying-glass-plus color-primary type-14"></span>
                                </div>
                            </button>
                        </div>
                        <div className="list-info ms-4">
                            <h3 className="type-20">{document.documentName}</h3>
                            <p className="color-grey-d1 my-0">Expires {prettyDate(document.expirationDate, systemDateFormat)}</p>
                        </div>
                    </div>
                </div>
                <div className="col-2 col-md-1 d-flex justify-content-end">

                    <Dropdown>
                        <Dropdown.Toggle className="btn btn-ghost-secondary btn-sm btn-icon-only">
                            <span className="fa-regular fa-ellipsis btn-icon"></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu show={rowMenuIsVisible}>
                            <Dropdown.Item onClick={() => onPrintDocument(document)} className="dropdown-item cursor" role="button">{getConfigValue(appState?.config?.QuickSheets?.Download?.Print, appState)?.Value || null}</Dropdown.Item>
                            <hr className="dropdown-divider" />
                            <Dropdown.Item onClick={() => onDownloadDocument(document)} className="dropdown-item cursor" role="button">{getConfigValue(appState?.config?.QuickSheets?.Download?.Download, appState)?.Value || null}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                </div>
            </div>
        </li>

    )
}
