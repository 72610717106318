import React, { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import { AppContext } from '../util/AppContext';
import { getConfigValue } from '../util/getConfigValue';

function FocusPage() {
    const [appState, setAppState] = useContext(AppContext);
    const [selectedFocus, setSelectedFocus] = useState(appState.focus || null);

    function setFocus(focus) {
        setSelectedFocus(focus);
        setAppState({
            ...appState,
            focus: focus
        })
    }

    return (
        <div>
            <div className="title-bar">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="type-24 mt-4 mb-4">{getConfigValue(appState.config?.Focus?.Header, appState)?.Value || null}</h1>
                            <ul className="wizard-list hide-noncurrent-mobile horizontal mb-5">
                                <li className="wizard-step state-future">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Territory?.Label, appState)?.Value || null}</span>
                                </li>
                                <li className="wizard-step state-future">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Target?.Label, appState)?.Value || null}</span>
                                </li>
                                <li className="wizard-step state-future">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Configure?.Label, appState)?.Value || null}</span>
                                </li>
                                <li className="wizard-step state-future">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Generate?.Label, appState)?.Value || null}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl mt-5">
                <div className="row justify-content-center mb-6">
                    <div className="col-12 col-sm-10 col-lg-8">
                        <h1 className="type-20 color-grey-d1 mb-5">{getConfigValue(appState.config?.Focus?.SelectPrimaryFocus?.Label, appState)?.Value || null}</h1>
                        <div className="card pd-card pt-4 pt-md-7 pb-4 pb-md-7">
                            <div className="row justify-content-center">
                                <div className="col-10 col-md-5 mb-6 mb-md-0">
                                    <Link to="/TerritoryPage" onClick={() => setFocus('HCPs')}>
                                        <button className="btn-tile pt-6 pb-6 mb-3">
                                            <span className="fa-solid fa-user-doctor"></span>
                                            <span className="btn-tile-label">{getConfigValue(appState.config?.Focus?.SelectHCP?.Label, appState)?.Value || null}</span>
                                        </button>
                                    </Link>
                                    <p className="text-center mb-0">{getConfigValue(appState.config?.Focus?.SelectHCP?.Description?.Label, appState)?.Value || null}</p>
                                </div>
                                <div className="col-10 col-md-5">
                                    <button className="btn-tile pt-6 pb-6 mb-3">
                                        <span className="fa-solid fa-envelope-open-text"></span>
                                        <span className="btn-tile-label">{getConfigValue(appState.config?.Focus?.SelectMessage?.Label, appState)?.Value || null}</span>
                                    </button>
                                    <p className="text-center mb-0">{getConfigValue(appState.config?.Focus?.SelectMessage?.Description?.Label, appState)?.Value || null}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mb-7">
                    <div className="col-10 col-sm-8 col-md-4 offset-md-6 col-lg-3 offset-lg-5 col-xl-3 offset-xl-5 col-xxl-2 offset-xxl-6 justify-content-end">
                        <div className="d-flex flex-column flex-md-row-reverse">
                            <Link to="/" className="btn btn-outline-primary btn-lg pe-7 flex-fill w-100">Cancel</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FocusPage;