import React, { useState, useEffect, useContext } from 'react';
import { deleteAddress, setAsPrimaryAddress } from '../API/address';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import formatPhoneNumber from '../util/formatPhoneNumber';
import { getConfigValue } from "../util/getConfigValue";
import { AppContext } from "../util/AppContext";
import { useErrorBoundary } from "react-error-boundary";

function AddressModal({ show, setShow, alertLevel, userId, address, setIsRowVisible, setAlert, action, loadAddresses }) {
    const [appState] = useContext(AppContext);
    const [isSaving, setIsSaving] = useState(false);
    const { id, nickName, firstName, lastName, address1, address2, city, state, zip, country, phoneNumber } = address;

    const [title, setTitle] = useState(null);
    const [body, setBody] = useState(null);
    const [buttonText, setButtonText] = useState(null);
    const [buttonColor, setButtonColor] = useState(null);
    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        if (action === 'delete') {
            setTitle(getConfigValue(appState?.config?.AddressManagement?.RemoveAddress?.Title, appState)?.Value || null);
            setButtonText(getConfigValue(appState?.config?.AddressManagement?.RemoveAddress?.ButtonText, appState)?.Value || null)
            setButtonColor('danger')
            setBody(getConfigValue(appState?.config?.AddressManagement?.RemoveAddress?.Message, appState)?.Value || null)
        } else if (action === 'setPrimary') {
            setTitle(getConfigValue(appState?.config?.AddressManagement?.SetPrimary?.Title, appState)?.Value || null);
            setButtonText(getConfigValue(appState?.config?.AddressManagement?.SetPrimary?.ButtonText, appState)?.Value || null)
            setButtonColor('success')
            setBody(getConfigValue(appState?.config?.AddressManagement?.SetPrimary?.Message, appState)?.Value || null)
        } else if (action === 'primaryError') {
            setTitle(getConfigValue(appState?.config?.AddressManagement?.Error?.Title, appState)?.Value || null);
            setButtonText(getConfigValue(appState?.config?.AddressManagement?.Error?.ButtonText, appState)?.Value || null);
            setButtonColor('primary');
            setBody(getConfigValue(appState?.config?.AddressManagement?.Error?.Message, appState)?.Value || null);
        }
    }, [action]);

    const handleClose = () => setShow(false);

    function handleClick() {
        if (!address) {
            console.error('No address to delete.');
            return;
        }

        if (action === 'delete') {
            deleteFormAddress(userId, id);
        } else if (action === 'setPrimary') {
            setFormAddressAsPrimary(userId, address, id);
        } else if (action === 'primaryError') {
            handleClose();
        }
    }

    function deleteFormAddress(userId, addressId) {
        setIsSaving(true);
        deleteAddress(userId, addressId)
            .then((responseJson) => {
                if (responseJson === "") {
                    const alert = { type: 'success', heading: (getConfigValue(appState?.config?.AddressPage?.Success?.Title, appState)?.Value || null), message: (getConfigValue(appState?.config?.AddressManagement?.Messages?.Delete?.Success, appState)?.Value || null) };

                    setIsRowVisible(false);
                    setAlert(alert);
                    handleClose();
                    setIsSaving(false);
                } else {
                    const alert = { type: 'danger', heading: (getConfigValue(appState?.config?.AddressPage?.Failure?.Title, appState)?.Value || null), message: (getConfigValue(appState?.config?.AddressManagement?.Messages?.Delete?.Failure, appState)?.Value || null) };

                    setAlert(alert);
                    setIsSaving(false);
                }
            })
            .catch(error => {
                setIsSaving(false);
                showBoundary(error);
            });
    }

    function setFormAddressAsPrimary(userId, address, addressId) {
        setIsSaving(true);
        setAsPrimaryAddress(userId, address.id)
            .then((responseJson) => {
                if (
                    responseJson.toLowerCase() === "previous primary address has been reset" ||
                    responseJson === addressId
                ) {
                    const alert = { type: 'success', heading: (getConfigValue(appState?.config?.AddressPage?.Success?.Title, appState)?.Value || null), message: (getConfigValue(appState?.config?.AddressManagement?.Messages?.SetPrimary?.Success, appState)?.Value || null) };

                    setAlert(alert);
                    loadAddresses();
                    handleClose();
                    setIsSaving(false);
                } else {
                    const alert = { type: 'danger', heading: (getConfigValue(appState?.config?.AddressPage?.Failure?.Title, appState)?.Value || null), message: (getConfigValue(appState?.config?.AddressManagement?.Messages?.Delete?.Failure, appState)?.Value || null) };

                    setAlert(alert);
                    setIsSaving(false);
                }
            })
            .catch(error => {
                setIsSaving(false);
                showBoundary(error);
            });
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
        >
            <Modal.Header className={alertLevel} closeButton >
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{body}</p>
                <span className="badge rounded-pill type-14 text-bg-primary px-3 mb-3">{nickName}</span>
                <p>
                    <span className="type-bold d-block">{firstName} {lastName}</span>
                    <span className="d-block">{address1}</span>
                    {address2 && <span className="d-block">{address2}</span>}
                    <span className="d-block">{city} {state} {zip}</span>
                    <span className="d-block">{country}</span>
                    <span className="d-block">{formatPhoneNumber(phoneNumber)}</span>
                </p>

            </Modal.Body>
            <Modal.Footer>

                {action !== 'primaryError' && (
                    <Button size="lg" variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                )}

                <Button disabled={isSaving ? true : null} className={isSaving ? "btn-working" : ""} size="lg" variant={buttonColor} onClick={handleClick}>
                    {buttonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddressModal;