import { useNavigate } from "react-router-dom";
import { Outlet, } from 'react-router-dom';
import { useState, useContext, useEffect } from 'react';
import { AppContext } from './AppContext';
import { reactSelectifyProducts } from "../util/reactSelectify";
import { getMarketsForCurrentUser } from './getMarkets';
import { getConfig, getConfigProducts, getFailedLoginPageConfig } from '../API/config';
import { roleOptions } from "../components/userDropdowns/roleOptions";
import { TimeoutHandler } from "./timeoutHandler";

export const AuthRoutes = () => {
	const [appState, setAppState] = useContext(AppContext);
	const [isLoggedIn, setIsLoggedIn] = useState(appState.user !== undefined && appState.user !== '');
	const navigate = useNavigate();

	//Handle Authentication
	useEffect(() => {
		let ignore = false;
		if (isLoggedIn) {
			return;
		}
		let alternate = false;
		let pathName = window.location.pathname;
		if (window.location.pathname === '/alternate') {
			alternate = true;
		} else {
			pathName = pathName.substring(1);
		}
		
		fetch(`${process.env.REACT_APP_API_URL}login?returnUrl=${process.env.REACT_APP_API_RETURN_URL}${alternate ? 'alternate' : pathName}`, {
			method: 'POST',
			credentials: 'include',
		})
			.then((response) => {
				if (response.status === 202) {
					setIsLoggedIn(true);
					return null;
				}
				if (!response.ok) {
					throw new Error(response.status);
				} else {
					return response.json();
				}
			})
			.then((data) => {
				if (data) {
					window.location.href = data.authenticationURL;
				}
			})
			.catch((error) => {
				console.log('error: ' + error);
			});
		return () => {
			ignore = true;
		};
	}, [isLoggedIn]);

	//Get user claims
	useEffect(() => {
		if (!isLoggedIn) {
			return;
		}

		function setAppData(data) {
			if (!data) {
				return;
			}
			let email = data.userClaims.filter((item) => item.type === 'email')[0].value;
			let originalEmail = data.userClaims.filter((item) => item.type === 'email/Original')[0]?.value;
			let hasTerritories = data.userClaims.filter((item) => item.type === 'HasTerritories')[0].value;
			let canImpersonate = data.userClaims.filter((item) => item.type === 'CanImpersonate')[0].value;
			let role = data.userClaims.filter((item) => item.type.includes('role'))[0].value;
			let name = data.userClaims.filter((item) => item.type.endsWith('name'))[0].value;

			sessionStorage.setItem('userClaimEmail', !originalEmail ? email : originalEmail);
			sessionStorage.setItem('userClaimHasTerritories', hasTerritories);
			sessionStorage.setItem('userClaimCanImpersonate', canImpersonate);
			sessionStorage.setItem('userClaimRole', role);
			sessionStorage.setItem('userClaimName', name);
			sessionStorage.setItem('userClaimId', email);



			if (!appState.user) {
				let impersonate = {
					isActive: false
				};
				//this covers the scenario when a browser tab is closed and re opened, having the session still active but not the appState properly set
				if (originalEmail && appState.impersonate.isActive === false) {
					impersonate = {
						isActive: true,
						email: email,
						role: roleOptions.find((x) => x.value === role).label,
					};
				}

				setAppState((appState) => ({
					...appState,
					user: email,
					role: role,
					impersonate: impersonate
				}));

			}
		}

		async function getUserClaim() {
			let response = await fetch(`${process.env.REACT_APP_API_URL}whoami`, {
				method: 'GET',
				credentials: 'include',
			})
				.then(async (response) => {
					if (!response.ok) {
						await getFailedLoginPageConfig()
							.then((responseJson) => {
								setAppState((prevState) => ({
									...prevState,
									config: JSON.parse(responseJson),
								}));
							})
							.catch((error) => {
								console.error(error);
							});

						navigate('/ErrorPage');
						return null;
					}

					return response.json();
				});

			return response;
		}
		let ignore = false;
		if (appState.user === undefined || appState.user === '') {
			getUserClaim()
				.then((data) => {
					if (!ignore) {
						setAppData(data);
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
		return () => {
			ignore = true;
		};
	}, [isLoggedIn, appState.user, navigate, setAppState]);

	//get the markets and territories for the loggedIn or impersonated user
	useEffect(() => {
		if (!isLoggedIn || appState.user === undefined || appState.user === '') {
			return;
		}
		const role = appState.impersonate.isActive && appState.impersonate.role !== "" ? appState.impersonate.role : appState.role;
		const email = appState.impersonate.isActive && appState.impersonate.email !== "" ? appState.impersonate.email : appState.user;
		getMarketsForCurrentUser(role, email);
	}, [isLoggedIn, appState.user, appState.role, appState.impersonate]);

	//Get configurations
	useEffect(() => {
		if (!isLoggedIn) {
			return;
		}
		let ignore = false;

		if ((!appState.configLoaded) || (appState.configLoaded && (!appState.configRefreshed || false))) {
			//regardless request a refresh of the config
			getConfig()
				.then((responseJson) => {
					if (!ignore) {
						localStorage.setItem("appConfig", responseJson);
						setAppState((prevState) => ({
							...prevState,
							config: JSON.parse(responseJson),
							configLoaded: true,
							configRefreshed: true
						}));
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
		return () => {
			ignore = true;
		};
	}, [isLoggedIn, appState.config, appState.configLoaded, appState.configRefreshed, setAppState]);

	//Get configurations from cache
	useEffect(() => {
		if (!appState.configLoaded) {
			const configCache = JSON.parse(localStorage.getItem("appConfig"));
			if (configCache) {
				setAppState((prevState) => ({
					...prevState,
					config: configCache,
					configLoaded: true
				}));
			}
		}
	}, [appState.config, appState.configLoaded, setAppState]);

	//Get the products configuration
	useEffect(() => {
		if (!isLoggedIn) {
			return;
		}
		let ignore = false;
		if (!appState.productDetails || appState.productDetails.length === 0) {
			getConfigProducts()
				.then((data) => {
					if (!ignore) {
						const sortedProducts = data.sort((a,b) => a.order - b.order);
						setAppState((appState) => ({
							...appState,
							productDetails: reactSelectifyProducts(sortedProducts)
						}));
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
		return () => {
			ignore = true;
		};

	}, [isLoggedIn, appState.productDetails, setAppState]);


	return (<>
		{isLoggedIn && appState.configLoaded && <TimeoutHandler></TimeoutHandler>}
		<Outlet />
	</>);

};

export default AuthRoutes;

export async function logOutPost() {
	const url = `${process.env.REACT_APP_API_URL}logout`;
	const response = await fetch(url, {
		method: 'POST',
		credentials: 'include',
	});

	if (response.status === 200) {
		sessionStorage.clear();
		window.location.href = `${process.env.REACT_APP_API_RETURN_URL}`;
	} else {
		throw response.statusText;
	}
}