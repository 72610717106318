import React, { useEffect, useState, createContext } from "react";

const initState = {
    user: "",
    focus: "",
    territory: {},
    market: {},
    prescriber: {},
    products: [],
    plans: [],
    currentProduct: "",
    category: "",
    mainMessage: "",
    role: "",
    config: {
        data: {},
        products: [],
        messageTypes: [],
        Dashboard: {}
    },
    configLoaded: false,
    impersonate: {
        isActive: false,
        firstName: "",
        lastName: "",
        email: "",
        role: "",
    },
};

export const AppContext = createContext(initState);

export function AppContextProvider({ children }) {
    const [appState, setAppState] = useState(
        // passing in function = lazy initialization (performance boost)
        () => initState
    );

    useEffect(() => {
        sessionStorage.setItem("appState", JSON.stringify(appState));
    }, [appState]);

    return (
        <AppContext.Provider value={[appState, setAppState]}>
            {children}
        </AppContext.Provider>
    );
}
