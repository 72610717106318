import React, { useContext, useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { getConfigValue } from "../../util/getConfigValue";
import { AppContext } from '../../util/AppContext';
import PropType from "prop-types";
import { getSpecialtyPharmacies } from "../../API/specialtyPharmacy";

SpecialtyPharmacyModal.propTypes = {
    show: PropType.bool,
    setShow: PropType.func,
    plan: PropType.shape({
        data: PropType.shape({
            name: PropType.string,
            dimPlanIdentity: PropType.number,
        })
    })
};


function SpecialtyPharmacyModal({ show, setShow, plan }) {
    const [appState] = useContext(AppContext);
    const [specialtyData, setSpecialtyData] = useState([]);
    const { name, dimPlanIdentity } = plan.data

    useEffect(() => {
        const productId = appState?.currentProduct?.clientProductId;
        const planIdentity = dimPlanIdentity;
        if (show) {
            getSpecialtyPharmacies(productId, planIdentity)
                .then((results) => {
                    setSpecialtyData(results);
                })
        }
    }, [show])
    
    return (
        <Modal show={show} className="modal-lg" onHide={() => setShow(false)} scrollable={true}>
            <Modal.Header className="primary" closeButton>
                <Modal.Title className="modal-title fs-5">{getConfigValue(appState.config?.SpecialtyPharmacy?.Modal.Title, appState)?.Value}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <h2 className="type-20 color-grey-d1 mt-2 mb-3 pb-2">{name}</h2>
                <table className='table pd-table'>
                    <thead>
                        <tr className='table-dark'>
                            <th scope='col'>{getConfigValue(appState?.config?.SpecialtyPharmacy?.Header?.PlanName, appState)?.Value || null}</th>
                            <th scope='col'>{getConfigValue(appState?.config?.SpecialtyPharmacy?.Header?.PharmacyName, appState)?.Value || null}</th>
                            <th scope='col'>{getConfigValue(appState?.config?.SpecialtyPharmacy?.Header?.Phone, appState)?.Value || null}</th>
                            <th scope='col'>{getConfigValue(appState?.config?.SpecialtyPharmacy?.Header?.Fax, appState)?.Value || null}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {specialtyData
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((specialty, index) => (
                                <tr key={specialty.id}>
                                    <td>
                                        <label className='form-check-label' htmlFor='selectHCP1'>
                                            {name}
                                        </label>
                                    </td>
                                    <td>
                                        <label className='form-check-label' htmlFor='selectHCP1'>
                                            {specialty.name}
                                        </label>
                                    </td>
                                    <td>
                                        <label className='form-check-label' htmlFor='selectHCP1'>
                                            {specialty.phone}
                                        </label>
                                    </td>
                                    <td>
                                        <label className='form-check-label' htmlFor='selectHCP1'>
                                            {specialty.fax}
                                        </label>
                                    </td>
                                </tr>
                            ))}

                    </tbody>
                </table>
            </Modal.Body>
        </Modal>
    );
}

export default SpecialtyPharmacyModal;