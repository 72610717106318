import { useState, useEffect, useContext } from 'react';
import useComponentVisible from '../hooks/useComponentVisible';
import prettyDate from "../util/prettyDate";
import { AppContext } from "../util/AppContext";
import { getConfigValue } from "../util/getConfigValue";
import { getCachedThumbNail } from "../API/quickSheets"
import { useErrorBoundary } from "react-error-boundary";

export default function QuickSheetsGridView({ documents, onPrintDocument, onDownloadDocument, isLoading, hasNoResults, onPreviewDoucment }) {
    const [appState] = useContext(AppContext);
    const [feedbackMessage, setFeedbackMessage] = useState('');

    useEffect(() => {
        if (isLoading) {
            setFeedbackMessage('Loading...');
        } else if (hasNoResults) {
            setFeedbackMessage(getConfigValue(appState?.config?.Common?.NoResultsFound, appState)?.Value || null);
        } else {
            setFeedbackMessage('');
        }
    }, [isLoading, hasNoResults]);

    return (
        <>
            {feedbackMessage ? (
                <div className="grid text-center">
                    <div className="g-col-12 g-col-sm-6 g-col-md-4 g-col-xl-3 d-flex">
                        <p>{feedbackMessage}</p>
                    </div>
                </div>
            ) : (
                <div className="grid text-center">

                    {documents.length > 0 && documents.map((document) => (
                        <QuickSheetsGridItem
                            document={document}
                            onPrintDocument={onPrintDocument}
                            onDownloadDocument={onDownloadDocument}
                            onPreviewDoucment={onPreviewDoucment}
                            key={document.documentId}
                        />
                    ))}
                </div>
            )
            }

        </>
    );
}

function QuickSheetsGridItem({ document, onPrintDocument, onDownloadDocument, onPreviewDoucment }) {
    const [appState] = useContext(AppContext);
    const [itemMenuRef, itemMenuIsVisible, setItemMenuIsVisible] = useComponentVisible(false);
    const [thumbNail, setThumbNail] = useState(null);
    const { showBoundary } = useErrorBoundary();
    const [systemDateFormat] = useState(getConfigValue(appState?.config?.System?.DateTime?.Format, appState)?.Value || null);

    useEffect(() => {
        getCachedThumbNail(document.documentId).then(text => {
            setThumbNail(text);
        }).catch(e => showBoundary(e));

    }, []);

    const BlobImage = ({ data }) => <div className="img-container"><img src={`data:image/jpeg;base64,${thumbNail}`} alt='quick sheets preview' /></div>

    return (
        <div className="g-col-12 g-col-sm-6 g-col-md-4 g-col-xl-3 d-flex">
            <div className="card pd-card d-flex flex-column text-start">
                <button className="btn_qs_preview btn-reset text-start" type="button" onClick={() => onPreviewDoucment(document)}>
                    <div className="img_preview grid position-relative" >
                        {thumbNail && <BlobImage />}
                        <div className="btn btn-primary btn-icon-only position-absolute bottom-0 end-0" onClick={() => onPreviewDoucment(document)}>
                            <div className="fa-regular fa-magnifying-glass-plus"></div>
                        </div>
                    </div>
                </button>
                <div className="d-flex flex-column p-3 pt-2 justify-content-between flex-grow-1">
                    <div>
                        <h3 className="type-18 mt-3">{document.documentName}</h3>
                        <p className="color-grey-d1 my-0">Expires {prettyDate(document.expirationDate, systemDateFormat)}</p>
                    </div>
                    <div className="dropdown align-self-end">
                        <button onClick={() => setItemMenuIsVisible(prev => !prev)} className="btn btn-ghost-secondary btn-sm btn-icon-only mt-3" type="button" data-bs-toggle="dropdown" aria-expanded="false"><span className="fa-regular fa-ellipsis btn-icon"></span></button>
                        <ul ref={itemMenuRef} className="dropdown-menu" style={{ display: itemMenuIsVisible ? 'block' : 'none' }}>
                            <li><a onClick={() => onPrintDocument(document)} className="dropdown-item" role="button">{getConfigValue(appState?.config?.QuickSheets?.Download?.Print, appState)?.Value || null}</a></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><a onClick={() => onDownloadDocument(document)} className="dropdown-item" role="button">{getConfigValue(appState?.config?.QuickSheets?.Download?.Download, appState)?.Value || null}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}