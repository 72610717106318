import { useState, useEffect, useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { getPrescribers } from '../API/prescriber';
import { getConfigValue } from '../util/getConfigValue';
import { AppContext } from '../util/AppContext';
import { debounce } from '@mui/material';
import { useErrorBoundary } from "react-error-boundary";
import PropType from 'prop-types';

ProvidersModal.propTypes = {
	show: PropType.bool,
	setShow: PropType.func,
	onProviderClick: PropType.func,
	currentProvider: PropType.string
};

function ProvidersModal({ show, setShow, onProviderClick, currentProvider }) {
	const [appState, setAppState] = useContext(AppContext);
	const [providers, setProviders] = useState(null);
	const [lastSearch, setLastSearch] = useState(null);
	const [feedbackMessage, setFeedbackMessage] = useState();
	const [maxRecordsMessage, setMaxRecordsMessage] = useState();
	const [sortedProviders, setSortedProviders] = useState([]);
	const [selectedProvider, setSelectedProvider] = useState();
	const [searchInput, setSearchInput] = useState('');
	const [sortedField, setSortedField] = useState('displayName');
	const [isAscending, setIsAscending] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [noResultsFound, setNoResultsFound] = useState(false);
	const { showBoundary } = useErrorBoundary();

	let maxRecords = 100;
	if (getConfigValue(appState?.config?.Target?.List?.MaxRecordsToDisplay, appState)?.Value) {
		maxRecords = parseInt(getConfigValue(appState?.config?.Target?.List?.MaxRecordsToDisplay, appState)?.Value, 10);
	}

	//Define debounced search function so the API is not called on every keystroke but only after 500ms
	const debouncedSearch = useRef(
		debounce((search) => {
			setIsLoading(true);
			getPrescribers(search)
				.then((data) => {
					setProviders(data);
					setLastSearch(search);
					setIsLoading(false);
				})
				.catch((error) => {
					showBoundary(error);
				});
		}, 500)
	).current;

	useEffect(() => {
		if (lastSearch === searchInput) {
			return;
		}
		//search when there is no data loaded, the searchInput is cleared or the searchInput is longer than 3 chars
		if (providers === null || searchInput.length === 0 || searchInput.length >= 3) {
			debouncedSearch(searchInput);
		}
	}, [JSON.stringify(providers), searchInput]);

	useEffect(() => {
		if (isLoading) {
			setFeedbackMessage(getConfigValue(appState.config.Common?.Loading, appState)?.Value);
		} else if (noResultsFound) {
			setFeedbackMessage(getConfigValue(appState.config.Common?.NoResultsFound, appState)?.Value);
		} else {
			setFeedbackMessage('');
		}
	}, [isLoading, noResultsFound, appState]);

	let timer;
	useEffect(() => {
		// cleanup
		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, []);

	useEffect(() => {
		if (currentProvider === null) {
			setSelectedProvider(currentProvider)
		}
	}, [currentProvider])

	function resetModal() {
		// avoid showing reset changes in UI as modal closes
		timer = setTimeout(() => {
			setSearchInput('');
			setLastSearch(null);
			setProviders(null);
			setSortedProviders([]);
			setSortedField('displayName');
			setIsAscending(true);
		}, 250);
	}

	useEffect(() => {
		//reset sort after a search
		setSortedField('displayName');
		setIsAscending(true);
		if (providers === null) {
			return;
		}

		setNoResultsFound(providers.length === 0);

		setSortedProviders(providers.sort(sortAscendingByName));

		if (providers.length === maxRecords) {
			setMaxRecordsMessage(getConfigValue(appState?.config?.Target?.List?.MaxRecordsMessage, appState)?.Value);
		} else {
			setMaxRecordsMessage(null);
		}
	}, [providers, appState]);

	useEffect(() => {
		if (providers === null) {
			return;
		}
		const providersCopy = [...providers];

		// disable sorting if field does not exist or is empty
		if (!doesFieldExist(providersCopy, sortedField) || isFieldEmpty(providersCopy, sortedField)) {
			return;
		}

		if (sortedField === 'displayName') {
			if (isAscending) {
				setSortedProviders(providersCopy.sort(sortAscendingByName));
			} else {
				setSortedProviders(providersCopy.sort(sortDescendingByName));
			}
		} else {
			setSortedProviders(
				providersCopy.sort((a, b) => {
					// sort by name if fields match
					if (isAscending) {
						if (a[sortedField] === b[sortedField]) {
							return sortAscendingByName(a, b);
						} else {
							return a[sortedField] > b[sortedField] ? 1 : -1;
						}
					} else {
						if (a[sortedField] === b[sortedField]) {
							return sortAscendingByName(a, b);
						} else {
							return a[sortedField] < b[sortedField] ? 1 : -1;
						}
					}
				})
			);
		}
	}, [providers, isAscending, sortedField]);

	function doesFieldExist(providerList, fieldName) {
		return providerList.some((provider) => provider.hasOwnProperty(fieldName));
	}

	function isFieldEmpty(providerList, fieldName) {
		return providerList.every((provider) => provider[fieldName] === null);
	}

	function sortAscendingByName(a, b) {
		return a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1;
	}

	function sortDescendingByName(a, b) {
		return a.displayName.toLowerCase() < b.displayName.toLowerCase() ? 1 : -1;
	}

	function handleSearchInput(event) {
		const search = event.target.value.trim();
		setSearchInput(search);
	}

	function handleSelect() {
		onProviderClick(selectedProvider);
		resetModal();
	}

	function handleHide() {
		setShow(false);

		// avoid showing reset changes in UI as modal closes
		resetModal();
	}

	function handleSortClick(fieldName) {
		setIsAscending((prev) => !prev);
		setSortedField(fieldName);
	}

	function getSortClass(fieldName) {
		if (fieldName !== sortedField) {
			return 'fa-light fa-sort';
		}

		return isAscending ? ' fa-solid fa-arrow-down-short-wide' : 'fa-solid fa-arrow-down-wide-short';
	}

	return (
		<Modal show={show} onHide={handleHide} size='lg' scrollable>
			<Modal.Header className='primary' closeButton>
				<Modal.Title className='type-18'>{getConfigValue(appState.config?.ProviderModal?.Title, appState)?.Value || null}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='row justify-content-center'>
					<div className='col-12'>
						<div className='row mb-4'>
							<div className='col-12'>
								{maxRecordsMessage && (
									<div>
										<div className='col-12 p-0'>
											<div className='alert alert-primary' role='alert'>
												<div className='alert-content'>
													<p>{maxRecordsMessage}</p>
												</div>
											</div>
										</div>
									</div>
								)}
								<div className='input-group'>
									<input
										onChange={handleSearchInput}
										selected
										type='text'
										className='form-control icon-search'
										id='exampleFormControlInput3'
										aria-describedby='inputGroupPrepend'
										placeholder='Search HCPs'
									/>
								</div>
							</div>
						</div>
						<table className='table pd-table mb-0'>
							<thead>
								<tr className='table-dark'>
									<th scope='col'></th>
									<th scope='col'>
										{getConfigValue(appState.config?.ProviderModal?.Table?.Name, appState)?.Value || null}{' '}
										<button onClick={() => handleSortClick('displayName')} className='btn-ghost btn-table active'>
											<div className={getSortClass('displayName')}></div>
										</button>
									</th>
									<th scope='col'>
										{getConfigValue(appState.config?.ProviderModal?.Table?.Speciality, appState)?.Value || null}{' '}
										<button onClick={() => handleSortClick('specialty')} className='btn-ghost btn-table'>
											<div className={getSortClass('specialty')}></div>
										</button>
									</th>
									<th scope='col'>
										{getConfigValue(appState.config?.ProviderModal?.Table?.ZipCode, appState)?.Value || null}{' '}
										<button onClick={() => handleSortClick('zipCode')} className='btn-ghost btn-table'>
											<div className={getSortClass('zipCode')}></div>
										</button>
									</th>
									<th scope='col'>
										{getConfigValue(appState.config?.ProviderModal?.Table?.Win, appState)?.Value || null}{' '}
										<button onClick={() => handleSortClick('hasWinPlans')} className='btn-ghost btn-table'>
											<div className={getSortClass('hasWinPlans')}></div>
										</button>
									</th>
								</tr>
							</thead>
							<tbody>
								{!feedbackMessage &&
									sortedProviders.length > 0 &&
									sortedProviders.map((provider, index) => (
										<tr key={index}>
											<th scope='row'>
												<input
													checked={provider?.id === selectedProvider?.id ? true : false}
													onChange={() => setSelectedProvider(provider)}
													className='form-check-input'
													type='radio'
													name='selectHCP'
													id={`selectHCP-${index}`}
												/>
											</th>
											<td>
												<label className='form-check-label' htmlFor={`selectHCP-${index}`}>
													{provider.displayName}
												</label>
											</td>
											<td>
												<label className='form-check-label' htmlFor={`selectHCP-${index}`}>
													{provider.specialty}
												</label>
											</td>
											<td>
												<label className='form-check-label' htmlFor={`selectHCP-${index}`}>
													{provider.zipCode}
												</label>
											</td>
											<td>
												<span className={`fa-solid fa-check ${provider.hasWinPlans === true ? 'color-success' : ''}`}></span>
											</td>
										</tr>
									))}
							</tbody>
						</table>
						{feedbackMessage ? (
							<div className='row mb-4'>
								<div className='color-grey-d1 py-4'>{feedbackMessage}</div>
							</div>
						) : null}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className='container-fluid mx-0'>
					<div className='row justify-content-md-end px-0'>
						<div className='col-12 col-md-9 col-lg-7 px-0'>
							<div className='d-flex w-100 flex-column flex-md-row-reverse'>
								<button onClick={handleSelect} className='btn btn-primary btn-lg mb-3 mb-md-0 ms-md-3 flex-fill w-100'>
									{getConfigValue(appState.config?.Common?.Select, appState)?.Value || null}
								</button>
								<button onClick={handleHide} className='btn btn-secondary btn-lg flex-fill w-100' data-bs-dismiss='modal'>
									{getConfigValue(appState.config?.Common?.Cancel, appState)?.Value || null}
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal.Footer>
		</Modal>
	);
}

export default ProvidersModal;
