import React, { useContext } from "react";
import { AppContext } from "../util/AppContext";
import { getConfigValue } from "../util/getConfigValue";
import { useLocation, useNavigate, Link } from "react-router-dom";
import AlertModal from '../components/AlertModal';
import useComponentVisible from '../hooks/useComponentVisible';

function SuccessfullySubmitPrintingRequest() {
    const [appState, setAppState] = useContext(AppContext);
    const alert = { type: 'success', heading: (getConfigValue(appState?.config?.ProfessionalPrinting?.SendToVendor?.Success?.Title, appState)?.Value || null), message: (getConfigValue(appState?.config?.ProfessionalPrinting?.SendToVendor?.Success?.Message, appState)?.Value || null) };

    const navigate = useNavigate();
    const { state } = useLocation();
    const [buttonRef, isDropdownVisible, setIsDropdownVisible] = useComponentVisible(false);

    function startNewSalesAid(startNew = true) {
        setAppState({
            ...appState,
            ...(startNew && {
                market: {},
                territory: {}
            }),
            prescriber: {},
            products: [],
            currentProduct: "",
            plans: [],
            category: "",
            mainMessage: "",
        });
        if (startNew) {
            navigate('/TerritoryPage');
        } else {
            navigate('/HCPPage');
        }
    }

    function handleClick() {
        setIsDropdownVisible(state => !state);
    }

    return (
        <>
            <div className="title-bar">
                <div className="container-lg">
                    <div className="row justify-content-center">
                        <div className="col-11 col-lg-12 col-xl-10 px-0">
                            <h1 className="type-24 mt-4 mb-4">{getConfigValue(appState.config?.ProfessionalPrinting?.Header, appState)?.Value || null}</h1>
                            <ul className="wizard-list hide-noncurrent-mobile horizontal mb-5">
                                <li className="wizard-step state-complete">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.ProfessionalPrinting?.BreadCrumb?.CreateDocument?.Label, appState)?.Value || null}</span>
                                </li>
                                <li className="wizard-step state-complete">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.ProfessionalPrinting?.BreadCrumb?.PlaceOrder?.Label, appState)?.Value || null}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-lg mt-5">
                <div className="row justify-content-center">
                    <div className="col-11 col-md-8">
                        <div className="col-12">
                            <AlertModal alert={alert} />
                        </div>

                        <h2 className="type-20 my-4">{getConfigValue(appState.config?.ProfessionalPrinting?.SendToVendor?.OrderDetails?.Label, appState)?.Value || null}</h2>

                        <div className="card pd-card p-3 mb-5">
                            <div className="row d-flex align-items-center">
                                <div className="col-12 col-md-5">
                                    <h3 className="type-18 color-grey-d3 mb-4">{getConfigValue(appState.config?.ProfessionalPrinting?.SendToVendor?.DeliveryAddress?.Label, appState)?.Value || null}</h3>
                                </div>
                                <div className="col">
                                    <p className="my-0">
                                        <span className="type-bold d-block">{state.address.nickName}</span>
                                        <span className="d-block">{state.address.address1}</span>
                                        <span className="d-block">{state.address.address2}</span>
                                        <span className="d-block">{state.address.state}</span>
                                        <span className="d-block">{state.address.country}</span>
                                        <span className="d-block">{state.address.phoneNumber}</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center justify-content-lg-end mb-5">
                            <div className="col-12 col-sm-9 col-md-7 col-lg-6 col-xl-5 order-lg-2">
                                <div className="dropdown dropup d-flex">
                                    <button
                                        onClick={handleClick}
                                        ref={buttonRef}
                                        className="btn btn-primary dropdown-toggle btn-lg pe-7 mb-3 mb-lg-0 ms-lg-3 pe-lg-3 flex-fill"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {getConfigValue(appState.config?.GenerateDocumentPage?.NewButton, appState)?.Value}
                                    </button>
                                    <ul
                                        className="dropdown-menu dropdown-menu-end"
                                        style={{
                                            display: isDropdownVisible ? "block" : "none",
                                            position: "absolute",
                                            bottom: "48px",
                                        }}
                                    >
                                        <li className="mb-2">
                                            <span className="dropdown-item-text type-bold">
                                                {getConfigValue(appState.config?.GenerateDocumentPage?.NewButton?.Question, appState)?.Value}
                                            </span>
                                        </li>
                                        <li>
                                            <hr className="dropdown-divider" />
                                        </li>
                                        <li>
                                            <button type="button" to="#" className="dropdown-item" onClick={() => startNewSalesAid(false)}>
                                                {getConfigValue(appState.config?.GenerateDocumentPage?.NewButton?.KeepTerritory, appState)?.Value}
                                            </button>
                                        </li>
                                        <li>
                                            <button type="button" to="#" className="dropdown-item" onClick={() => startNewSalesAid()}>
                                                {getConfigValue(appState.config?.GenerateDocumentPage?.NewButton?.BrandNew, appState)?.Value}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-9 col-md-7 col-lg-6 col-xl-5 order-lg-1">
                                <Link to="/">
                                    <button className="btn btn-secondary btn-lg pe-7 pe-lg-3 flex-fill w-100">
                                        {getConfigValue(appState.config?.GenerateDocumentPage?.DashboardButton, appState)?.Value}
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SuccessfullySubmitPrintingRequest;